import React, { useState } from "react";
import {
  TextField,
  Button,
  Box,
  CircularProgress,
  Typography,
  Grid,
  Snackbar,
  Alert,
  Avatar,
} from "@mui/material";
import { addDoc, collection, doc, updateDoc } from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { db, storage } from "../../../firebase"; // Asegúrate de que el storage esté correctamente configurado en firebase.js

const NuevoEmpleado = ({ handleClose, onEmpleadoAdded }) => {
  const [nombre, setNombre] = useState("");
  const [telefono, setTelefono] = useState("");
  const [sueldoBase, setSueldoBase] = useState("");
  const [direccion, setDireccion] = useState("");
  const [correo, setCorreo] = useState("");
  const [foto, setFoto] = useState(null);
  const [fotoPreview, setFotoPreview] = useState(null); // Vista previa de la imagen
  const [loading, setLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [errors, setErrors] = useState({});

  // Función para validar correo
  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  // Control para que solo acepte números
  const handlePhoneNumberChange = (e) => {
    const { value } = e.target;
    if (/^\d*$/.test(value)) {
      setTelefono(value);
    }
  };

  const handleSueldoChange = (e) => {
    const { value } = e.target;
    if (/^\d*\.?\d*$/.test(value)) {
      setSueldoBase(value);
    }
  };

  const handleAddEmpleado = async () => {
    // Resetea errores previos
    setErrors({});
    let validationErrors = {};

    // Validaciones
    if (!nombre) validationErrors.nombre = "El nombre es obligatorio.";
    if (!telefono) {
      validationErrors.telefono = "El teléfono es obligatorio.";
    } else if (!/^\d{10}$/.test(telefono)) {
      validationErrors.telefono = "El teléfono debe tener 10 dígitos.";
    }
    if (!sueldoBase) {
      validationErrors.sueldoBase = "El sueldo base es obligatorio.";
    } else if (!/^\d+(\.\d{1,2})?$/.test(sueldoBase)) {
      validationErrors.sueldoBase = "El sueldo debe ser un número válido.";
    }
    if (!direccion) validationErrors.direccion = "La dirección es obligatoria.";
    if (!correo) {
      validationErrors.correo = "El correo es obligatorio.";
    } else if (!validateEmail(correo)) {
      validationErrors.correo = "El correo no es válido.";
    }

    // Si hay errores, no continua
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    setLoading(true);
    try {
      // Agregar los datos del empleado a Firestore
      const docRef = await addDoc(collection(db, "empleados"), {
        Nombre: nombre,
        Telefono: telefono,
        SueldoBase: sueldoBase,
        Direccion: direccion,
        Correo: correo,
        FotoUrl: "", // Temporalmente vacío hasta que se suba la foto
      });

      // Si hay una foto seleccionada, subirla a Firebase Storage
      if (foto) {
        const storageRef = ref(storage, `empleados/${docRef.id}`);
        await uploadBytes(storageRef, foto);
        const downloadURL = await getDownloadURL(storageRef);

        // Actualizar el documento con la URL de la foto
        await updateDoc(doc(db, "empleados", docRef.id), {
          FotoUrl: downloadURL,
        });
      }

      onEmpleadoAdded({
        id: docRef.id,
        Nombre: nombre,
        Telefono: telefono,
        SueldoBase: sueldoBase,
        Direccion: direccion,
        Correo: correo,
      });

      setSnackbarMessage(`Empleado ${nombre} agregado correctamente.`);
      setSnackbarOpen(true);
      handleClose();
    } catch (error) {
      console.error("Error al agregar el empleado:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFoto(file);
      setFotoPreview(URL.createObjectURL(file)); // Mostrar la vista previa de la imagen
    }
  };

  return (
    <Box
      sx={{
        p: 3,
        borderRadius: 2,
        boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
        backgroundColor: "#ffffff", // Fondo blanco
        color: "#000000", // Texto en negro
        maxWidth: 500, // Limita el ancho máximo en pantallas grandes
        margin: "0 auto", // Centra el formulario
        mt: 5,
      }}
    >
      <Typography
        variant="h5"
        gutterBottom
        sx={{
          textAlign: "center",
          fontWeight: "bold",
          color: "#333", // Texto gris oscuro
          mb: 3,
        }}
      >
        Agregar Nuevo Empleado
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            label="Nombre del Empleado"
            variant="outlined"
            fullWidth
            value={nombre}
            onChange={(e) => setNombre(e.target.value)}
            error={!!errors.nombre}
            helperText={errors.nombre}
            sx={{ mb: 2 }}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            label="Número de Teléfono"
            variant="outlined"
            fullWidth
            value={telefono}
            onChange={handlePhoneNumberChange}
            error={!!errors.telefono}
            helperText={errors.telefono}
            sx={{ mb: 2 }}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            label="Sueldo Base"
            variant="outlined"
            fullWidth
            value={sueldoBase}
            onChange={handleSueldoChange}
            error={!!errors.sueldoBase}
            helperText={errors.sueldoBase}
            sx={{ mb: 2 }}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            label="Dirección"
            variant="outlined"
            fullWidth
            value={direccion}
            onChange={(e) => setDireccion(e.target.value)}
            error={!!errors.direccion}
            helperText={errors.direccion}
            sx={{ mb: 2 }}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            label="Correo Electrónico"
            variant="outlined"
            fullWidth
            value={correo}
            onChange={(e) => setCorreo(e.target.value)}
            error={!!errors.correo}
            helperText={errors.correo}
            sx={{ mb: 2 }}
          />
        </Grid>

        <Grid item xs={12} sx={{ textAlign: "center", mb: 2 }}>
          <Button
            variant="contained"
            component="label"
            sx={{
              backgroundColor: "#1976d2",
              color: "#ffffff",
              "&:hover": {
                backgroundColor: "#1565c0",
              },
            }}
          >
            Subir Foto
            <input type="file" hidden onChange={handleFileChange} />
          </Button>

          {fotoPreview && (
            <Box sx={{ mt: 2, display: "flex", justifyContent: "center" }}>
              <Avatar
                alt="Foto del empleado"
                src={fotoPreview}
                sx={{ width: 120, height: 120 }}
              />
            </Box>
          )}
        </Grid>
      </Grid>

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          mt: 4,
        }}
      >
        <Button
          variant="contained"
          onClick={handleAddEmpleado}
          disabled={loading}
          sx={{
            backgroundColor: "#1976d2",
            color: "#ffffff",
            "&:hover": {
              backgroundColor: "#1565c0",
            },
          }}
        >
          {loading ? <CircularProgress size={24} color="inherit" /> : "Agregar"}
        </Button>
        <Button
          variant="outlined"
          onClick={handleClose}
          sx={{
            borderColor: "#d32f2f",
            color: "#d32f2f",
            "&:hover": {
              borderColor: "#b71c1c",
              color: "#b71c1c",
            },
          }}
        >
          Cancelar
        </Button>
      </Box>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity="success"
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default NuevoEmpleado;
