import React, { useState, useEffect } from "react";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Box,
  Typography,
  CircularProgress,
} from "@mui/material";
import SignaturePad from "react-signature-canvas";
import NuevoOficial from "../../AdminDashboard/Oficiales/NuevoOficial";

const Step4OficialFirma = ({
  oficiales,
  selectedOficial,
  setSelectedOficial,
  firma,
  handleFirmaPad,
  handleBack,
  handleSubmit,
  uploading,
}) => {
  const [showNuevoOficial, setShowNuevoOficial] = useState(false);
  const [oficialesList, setOficialesList] = useState(oficiales);
  const signaturePadRef = React.useRef(null);

  const clearSignature = () => {
    signaturePadRef.current.clear();
    handleFirmaPad(null);
  };

  const handleOpenNuevoOficial = () => {
    setShowNuevoOficial(true);
  };

  const handleCloseNuevoOficial = () => {
    setShowNuevoOficial(false);
  };

  const handleAddOfficial = (newOfficial) => {
    setOficialesList([...oficialesList, newOfficial]);
    setSelectedOficial(newOfficial.nombre);
    handleCloseNuevoOficial();
  };

  useEffect(() => {
    setOficialesList(oficiales); // Actualiza la lista local de oficiales si cambia externamente
  }, [oficiales]);

  const handleFinalSubmit = async () => {
    const endTime = new Date().toLocaleString();
    await handleSubmit(endTime);
  };

  return (
    <>
      <FormControl fullWidth variant="outlined" sx={{ mb: 4 }}>
        <InputLabel sx={{ color: "#00ffff" }}>Oficial</InputLabel>
        <Select
          value={selectedOficial}
          onChange={(e) => setSelectedOficial(e.target.value)}
          label="Oficial"
          sx={{
            color: "#ffffff",
            ".MuiOutlinedInput-notchedOutline": {
              borderColor: "#00ffff",
            },
            "&:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: "#00ffff",
            },
            ".MuiSvgIcon-root ": {
              fill: "#00ffff !important",
            },
          }}
        >
          {oficialesList.map((oficial) => (
            <MenuItem key={oficial.id} value={oficial.nombre}>
              {oficial.nombre}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <Typography
        variant="h6"
        sx={{ color: "#00ffff", mb: 2, textAlign: "center" }}
      >
        Firma del Oficial
      </Typography>

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          mb: 4,
        }}
      >
        <Box
          sx={{
            border: "2px solid #00ffff",
            borderRadius: "8px",
            padding: "16px",
            backgroundColor: "white",
          }}
        >
          <SignaturePad
            ref={signaturePadRef}
            penColor="black"
            backgroundColor="white"
            canvasProps={{
              width: 500,
              height: 200,
              style: { backgroundColor: "white", borderRadius: "8px" },
            }}
            onEnd={() => handleFirmaPad(signaturePadRef.current)}
          />
        </Box>
      </Box>

      <Box sx={{ display: "flex", justifyContent: "center", mb: 4 }}>
        <Button
          variant="outlined"
          color="secondary"
          onClick={clearSignature}
          sx={{ mr: 2, color: "#00ffff", borderColor: "#00ffff" }}
        >
          Limpiar Firma
        </Button>
      </Box>

      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Button
          variant="contained"
          color="secondary"
          onClick={handleBack}
          sx={{
            backgroundColor: "#009688",
            color: "#ffffff",
            "&:hover": { backgroundColor: "#00796b" },
          }}
        >
          Anterior
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleFinalSubmit}
          disabled={uploading}
          sx={{
            backgroundColor: "#00C49F",
            color: "#000000",
            "&:hover": { backgroundColor: "#009688" },
          }}
        >
          {uploading ? (
            <CircularProgress size={24} sx={{ ml: 2 }} />
          ) : (
            "Crear Inspección"
          )}
        </Button>
      </Box>

      <Button
        variant="contained"
        color="secondary"
        onClick={handleOpenNuevoOficial}
        sx={{
          mt: 2,
          backgroundColor: "#009688",
          color: "#ffffff",
          "&:hover": { backgroundColor: "#00796b" },
        }}
      >
        Nuevo Oficial
      </Button>

      <NuevoOficial
        open={showNuevoOficial}
        handleClose={handleCloseNuevoOficial}
        onOfficialAdded={handleAddOfficial}
      />
    </>
  );
};

export default Step4OficialFirma;
