import React, { useState, useEffect } from "react";
import {
  TextField,
  Button,
  Box,
  CircularProgress,
  Typography,
  Grid,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  Snackbar,
  Alert,
} from "@mui/material";
import { addDoc, collection, getDocs } from "firebase/firestore";
import { db } from "../../../firebase";

const NuevaUnidad = ({ handleClose, onUnidadAdded }) => {
  const [unidad, setUnidad] = useState("");
  const [zona, setZona] = useState("");
  const [zonas, setZonas] = useState([]);
  const [loading, setLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  useEffect(() => {
    const fetchZonas = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "unidades"));
        const zonasSet = new Set();
        querySnapshot.forEach((doc) => {
          zonasSet.add(doc.data().Zona);
        });
        setZonas([...zonasSet]);
      } catch (error) {
        console.error("Error al obtener las zonas:", error);
      }
    };

    fetchZonas();
  }, []);

  const handleAddUnidad = async () => {
    setLoading(true);
    try {
      const docRef = await addDoc(collection(db, "unidades"), {
        Unidad: unidad,
        Zona: zona,
      });
      onUnidadAdded({
        id: docRef.id,
        Unidad: unidad,
        Zona: zona,
      });
      setSnackbarMessage(
        `Unidad: ${unidad} agregada correctamente a la Zona ${zona}`
      );
      setSnackbarOpen(true);
      handleClose();
    } catch (error) {
      console.error("Error al agregar la unidad:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <Box
      sx={{
        p: 3,
        borderRadius: 2,
        boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
        backgroundColor: "#1c1c1c",
        color: "#ffffff",
      }}
    >
      <Typography
        variant="h5"
        gutterBottom
        sx={{
          textAlign: "center",
          fontWeight: "bold",
          color: "#00ffff",
          mb: 3,
        }}
      >
        Agregar Nueva Unidad
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            label="Unidad"
            variant="outlined"
            fullWidth
            value={unidad}
            onChange={(e) => setUnidad(e.target.value)}
            sx={{
              "& .MuiOutlinedInput-root": {
                color: "#ffffff",
                borderColor: "#00ffff",
              },
              "& .MuiInputLabel-root": {
                color: "#00ffff",
              },
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "#00ffff",
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: "#00ffff",
              },
              mb: 2,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth variant="outlined" sx={{ mb: 2 }}>
            <InputLabel sx={{ color: "#00ffff" }}>Zona</InputLabel>
            <Select
              value={zona}
              onChange={(e) => setZona(e.target.value)}
              label="Zona"
              sx={{
                color: "#ffffff",
                ".MuiOutlinedInput-notchedOutline": {
                  borderColor: "#00ffff",
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#00ffff",
                },
                ".MuiSvgIcon-root ": {
                  fill: "#00ffff !important",
                },
              }}
            >
              {zonas.map((z) => (
                <MenuItem key={z} value={z}>
                  {z}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          mt: 4,
        }}
      >
        <Button
          variant="contained"
          color="primary"
          onClick={handleAddUnidad}
          disabled={loading}
          sx={{
            backgroundColor: "#00C49F",
            color: "#000000",
            "&:hover": {
              backgroundColor: "#009688",
            },
          }}
        >
          {loading ? <CircularProgress size={24} color="inherit" /> : "Agregar"}
        </Button>
        <Button
          variant="outlined"
          color="secondary"
          onClick={handleClose}
          sx={{
            borderColor: "#ff1744",
            color: "#ff1744",
            "&:hover": {
              borderColor: "#ff4569",
              color: "#ff4569",
            },
          }}
        >
          Cancelar
        </Button>
      </Box>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity="success"
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default NuevaUnidad;
