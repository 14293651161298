import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  FormControl,
  InputLabel,
  Input,
  Button,
  Alert,
  Grid,
  Box,
  Typography,
} from "@mui/material";
import GoogleIcon from "../assets/google-icon.png";
import { useUserAuth } from "../context/userAuthContext"; // Asegúrate de importar correctamente

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const { logIn, googleSignIn } = useUserAuth(); // Usa el hook del contexto
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    try {
      await logIn(email, password);
      navigate("/home");
    } catch (err) {
      setError(err.message);
    }
  };

  const handleGoogleSignIn = async (e) => {
    e.preventDefault();
    try {
      await googleSignIn();
      navigate("/home");
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      className="login-container"
      style={{ minHeight: "100vh", padding: "0 20px" }}
    >
      <Grid item xs={12} sm={8} md={6} lg={4}>
        <Box className="login-box" p={3} boxShadow={3} borderRadius={2}>
          {error && <Alert severity="error">{error}</Alert>}
          <form onSubmit={handleSubmit}>
            <FormControl fullWidth margin="normal">
              <InputLabel htmlFor="email">Correo Electrónico</InputLabel>
              <Input
                id="email"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </FormControl>

            <FormControl fullWidth margin="normal">
              <InputLabel htmlFor="password">Contraseña</InputLabel>
              <Input
                id="password"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </FormControl>

            <Button
              variant="contained"
              color="primary"
              fullWidth
              type="submit"
              sx={{ mt: 2, py: 2 }}
            >
              Iniciar Sesión
            </Button>
          </form>

          <Box className="google-btn-container" mt={2}>
            <Button
              variant="outlined"
              onClick={handleGoogleSignIn}
              startIcon={<img src={GoogleIcon} alt="Google" width="20" />}
              fullWidth
              sx={{ py: 2 }}
            >
              Iniciar Sesión con Google
            </Button>
          </Box>

          <Box mt={2}>
            <Link to="/phonesignup" style={{ textDecoration: "none" }}>
              <Button
                variant="contained"
                color="success"
                fullWidth
                sx={{ py: 2, backgroundColor: "black", color: "white" }}
              >
                Celular
              </Button>
            </Link>
          </Box>

          <Typography variant="body2" align="center" mt={3}>
            ¿No tienes una cuenta? <Link to="/registro">¡Crea una!</Link>
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
};

export default Login;
