import React, { useState } from "react";
import { Card, CardContent, Typography } from "@mui/material";
import FormProducto from "./NuevoIventario/FormProducto";
import GaleriaImagenes from "./NuevoIventario/GaleriaImagenes";
import EscanerQR from "./NuevoIventario/EscanerQR";
import NuevaCategoria from "./NuevaCategoria";
import NuevaMarca from "./NuevaMarca";

const Inventario = () => {
  const [imagenes, setImagenes] = useState([]);
  const [previewUrls, setPreviewUrls] = useState([]);
  const [message, setMessage] = useState("");
  const [showQrScanner, setShowQrScanner] = useState(false);

  return (
    <div style={{ padding: 20 }}>
      <Card
        sx={{
          p: 4,
          borderRadius: 4,
          boxShadow: "0 8px 24px rgba(0,0,0,0.12)",
          mb: 4,
        }}
      >
        <CardContent>
          <Typography variant="h5" component="div" gutterBottom>
            Agregar Producto al Inventario
          </Typography>
          <FormProducto
            imagenes={imagenes}
            setImagenes={setImagenes}
            previewUrls={previewUrls}
            setPreviewUrls={setPreviewUrls}
            setMessage={setMessage}
          />
          <GaleriaImagenes
            imagenes={imagenes}
            previewUrls={previewUrls}
            setImagenes={setImagenes}
            setPreviewUrls={setPreviewUrls}
          />
          <EscanerQR
            showQrScanner={showQrScanner}
            setShowQrScanner={setShowQrScanner}
            setMessage={setMessage}
          />
          {message && (
            <Typography variant="body1" color="text.secondary" sx={{ mt: 2 }}>
              {message}
            </Typography>
          )}
        </CardContent>
      </Card>
      <NuevaCategoria />
      <NuevaMarca />
    </div>
  );
};

export default Inventario;
