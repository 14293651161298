import React from "react";
import {
  Box,
  Typography,
  Grid,
  Card,
  CardContent,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import SyncIcon from "@mui/icons-material/Sync";

const DiagnosticoInspeccion = ({ estadoGeneral, fusiblesReemplazados }) => {
  const renderEstadoGeneral = () => {
    if (!estadoGeneral) return null;

    return (
      <Box
        sx={{
          mt: 2,
          p: 1,
          backgroundColor: "#111",
          borderRadius: "8px",
          boxShadow: "0 2px 8px rgba(0, 255, 255, 0.2)",
          maxWidth: "100%",
          margin: "0 auto",
        }}
      >
        <Typography
          variant="h6"
          gutterBottom
          sx={{
            color: "#00ffff",
            fontWeight: "bold",
            mb: 1,
            textAlign: "center",
            borderBottom: "1px solid #00ffff",
            paddingBottom: "4px",
          }}
        >
          Estado General
        </Typography>
        <Grid container spacing={1}>
          {Object.entries(estadoGeneral).map(([key, value]) => (
            <Grid item xs={12} sm={6} key={key}>
              <Card
                sx={{
                  backgroundColor: "#222",
                  borderRadius: "6px",
                  boxShadow: "0 2px 8px rgba(0, 255, 255, 0.2)",
                  border: `2px solid ${
                    value === "Correcto" ? "#39FF14" : "#FF3131"
                  }`,
                }}
              >
                <CardContent
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "8px",
                    textAlign: "center",
                    flexDirection: "column",
                  }}
                >
                  <Typography
                    variant="body1"
                    sx={{
                      color: value === "Correcto" ? "#39FF14" : "#FF3131",
                      fontWeight: "bold",
                      fontSize: "1rem",
                    }}
                  >
                    {key}
                  </Typography>
                  {value === "Correcto" ? (
                    <CheckCircleIcon
                      sx={{ color: "#39FF14", fontSize: "1.5rem", mt: 1 }}
                    />
                  ) : (
                    <CancelIcon
                      sx={{ color: "#FF3131", fontSize: "1.5rem", mt: 1 }}
                    />
                  )}
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>
    );
  };

  const renderFusiblesReemplazados = () => {
    if (!fusiblesReemplazados || fusiblesReemplazados.length === 0) return null;

    return (
      <Box
        sx={{
          mt: 2,
          p: 1,
          backgroundColor: "#111",
          borderRadius: "8px",
          boxShadow: "0 2px 8px rgba(0, 255, 255, 0.2)",
          maxWidth: "100%",
          margin: "0 auto",
        }}
      >
        <Typography
          variant="h6"
          gutterBottom
          sx={{
            color: "#00ffff",
            fontWeight: "bold",
            mb: 1,
            textAlign: "center",
            borderBottom: "1px solid #00ffff",
            paddingBottom: "4px",
          }}
        >
          Componentes Reemplazados
        </Typography>
        <List sx={{ padding: 0 }}>
          {fusiblesReemplazados.map((fusible, index) => (
            <ListItem
              key={index}
              sx={{
                backgroundColor: "#222",
                borderRadius: "6px",
                boxShadow: "0 2px 8px rgba(0, 255, 255, 0.2)",
                mb: 0.5,
                padding: "4px 8px",
                textAlign: "center",
              }}
            >
              <SyncIcon sx={{ color: "#00ffff", fontSize: "1.5rem", mr: 1 }} />
              <ListItemText
                primary={fusible}
                primaryTypographyProps={{
                  variant: "body1",
                  sx: {
                    color: "#ffffff",
                    fontWeight: "bold",
                    fontSize: "1rem",
                  },
                }}
              />
            </ListItem>
          ))}
        </List>
      </Box>
    );
  };

  return (
    <Box
      sx={{
        p: 1,
        bgcolor: "#0d0d0d",
        borderRadius: "8px",
        boxShadow: "0 2px 8px rgba(0, 255, 255, 0.2)",
        maxWidth: "100%",
        margin: "0 auto",
      }}
    >
      {renderEstadoGeneral()}
      {renderFusiblesReemplazados()}
    </Box>
  );
};

export default DiagnosticoInspeccion;
