import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import { db } from "./firebase";
import { doc, getDoc } from "firebase/firestore";
import { IconButton } from "@mui/material";
import LocalShippingIcon from "@mui/icons-material/LocalShipping"; // Material UI Icon

const ProductDetail = () => {
  const { documentName } = useParams();
  const [product, setProduct] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const productRef = doc(db, "products", documentName);
        const productSnapshot = await getDoc(productRef);

        if (productSnapshot.exists()) {
          setProduct(productSnapshot.data());
        } else {
          setError("Producto no encontrado.");
        }
      } catch (err) {
        console.error("Error al buscar el producto: ", err);
        setError("Hubo un problema al buscar el producto.");
      } finally {
        setLoading(false);
      }
    };

    fetchProduct();
  }, [documentName]);

  if (loading) {
    return <div style={styles.loading}>Cargando...</div>;
  }

  if (error) {
    return <div style={styles.error}>{error}</div>;
  }

  const renderStockInfo = () => {
    const locations = [
      "Cd. Juárez",
      "Chihuahua",
      "Chihuahua CEDIS",
      "Guadalajara",
      "Hermosillo",
      "León",
      "Los Mochis",
      "Monterrey",
      "Mérida",
      "México Norte",
      "México Sur",
      "Puebla",
      "Querétaro",
      "San Luis Potosí",
      "Stock Cancun",
      "Stock Culiacan",
      "Stock Querétaro CEDIS",
      "Stock Tepotzotlan",
      "Stock Veracruz",
      "Tijuana",
      "Toluca",
      "Torreón",
      "Villahermosa",
    ];

    return locations.map((location) => {
      if (product[location]) {
        return (
          <li key={location} style={styles.detailItem}>
            <strong>{location}:</strong> {product[location]}
          </li>
        );
      }
      return null;
    });
  };

  return (
    <div style={styles.container}>
      <Link to="/search" style={styles.goBackButton}>
        &larr; Volver a los resultados
      </Link>
      {product ? (
        <div style={styles.productContainer}>
          <div style={styles.imageContainer}>
            <img
              src={product["Imagen Principal"]}
              alt={product.Título}
              style={styles.image}
            />
          </div>
          <div style={styles.productInfo}>
            <h1 style={styles.title}>{product.Título}</h1>
            <p style={styles.model}>
              <strong>Modelo:</strong> {product.Modelo}
            </p>
            <p style={styles.brand}>
              <strong>Marca:</strong> {product.Marca}
            </p>
            <div style={styles.priceContainer}>
              {product["Precio Especial"] &&
              product["Precio Especial"] !== "-" ? (
                <>
                  <span style={styles.originalPrice}>
                    ${product["Precio Lista"]}
                  </span>
                  <span style={styles.specialPrice}>
                    ${product["Precio Especial"]}
                  </span>
                  <span style={styles.discount}>
                    {Math.round(
                      ((product["Precio Lista"] - product["Precio Especial"]) /
                        product["Precio Lista"]) *
                        100
                    )}
                    % de descuento
                  </span>
                </>
              ) : (
                <span style={styles.price}>
                  {product["Precio Lista"] && product["Precio Lista"] !== "-"
                    ? `$${product["Precio Lista"]}`
                    : "Precio no disponible"}
                </span>
              )}
            </div>
            <div style={styles.deliveryInfo}>
              <IconButton color="primary" size="large">
                <LocalShippingIcon fontSize="large" />
              </IconButton>
              <span style={styles.deliveryText}>Envío GRATIS</span>
            </div>
            <button style={styles.addToCartButton}>Agregar al carrito</button>
            <button style={styles.buyNowButton}>Comprar ahora</button>
            <div style={styles.detailsContainer}>
              <h2 style={styles.subTitle}>Inventario en Sucursales</h2>
              <ul style={styles.detailsList}>{renderStockInfo()}</ul>
            </div>
            <div style={styles.detailsContainer}>
              <h2 style={styles.subTitle}>Acerca de este artículo</h2>
              <ul style={styles.detailsList}>
                {Object.entries(product).map(([key, value]) => {
                  if (
                    key !== "Descripción" &&
                    key !== "Imagen Principal" &&
                    key !== "Descripción Larga" &&
                    key !== "Precio Lista" &&
                    key !== "Precio Especial" &&
                    key !== "Modelo" &&
                    key !== "Marca" &&
                    key !== "Título"
                  ) {
                    return (
                      <li key={key} style={styles.detailItem}>
                        <strong>{key.replace(/_/g, " ")}:</strong> {value}
                      </li>
                    );
                  }
                  return null;
                })}
              </ul>
            </div>
          </div>
        </div>
      ) : (
        <div style={styles.error}>No se encontró el producto.</div>
      )}
      {product && (
        <div style={styles.longDescriptionContainer}>
          <h2 style={styles.longDescriptionTitle}>Descripción del producto</h2>
          <div
            dangerouslySetInnerHTML={{
              __html: product["Descripcion"],
            }}
            style={styles.longDescriptionContent}
          ></div>
        </div>
      )}
    </div>
  );
};

const styles = {
  container: {
    padding: "20px",
    fontFamily: "'Arial', sans-serif",
    backgroundColor: "#f8f8f8",
    minHeight: "100vh",
    color: "#111",
  },
  goBackButton: {
    display: "inline-block",
    padding: "8px 12px",
    marginBottom: "20px",
    backgroundColor: "#f0f0f0",
    color: "#111",
    borderRadius: "4px",
    textDecoration: "none",
    fontSize: "14px",
    transition: "background-color 0.2s ease",
  },
  productContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "20px",
    maxWidth: "1200px",
    margin: "0 auto",
    backgroundColor: "#ffffff",
    borderRadius: "8px",
    boxShadow: "0 1px 4px rgba(0, 0, 0, 0.1)",
    padding: "20px",
  },
  imageContainer: {
    flex: "1",
    textAlign: "center",
  },
  image: {
    maxWidth: "100%",
    height: "auto",
    maxHeight: "500px",
    objectFit: "contain",
  },
  productInfo: {
    flex: "1",
  },
  title: {
    fontSize: "24px",
    fontWeight: "400",
    marginBottom: "10px",
    color: "#0F1111",
  },
  model: {
    fontSize: "18px",
    fontWeight: "400",
    marginBottom: "10px",
    color: "#0F1111",
  },
  brand: {
    fontSize: "18px",
    fontWeight: "400",
    marginBottom: "10px",
    color: "#0F1111",
  },
  priceContainer: {
    marginBottom: "10px",
  },
  originalPrice: {
    textDecoration: "line-through",
    color: "#565959",
    fontSize: "14px",
    marginRight: "8px",
  },
  specialPrice: {
    fontSize: "28px",
    color: "#B12704",
    fontWeight: "400",
  },
  price: {
    fontSize: "28px",
    color: "#B12704",
    fontWeight: "400",
  },
  discount: {
    color: "#B12704",
    fontSize: "14px",
    marginLeft: "8px",
  },
  deliveryInfo: {
    display: "flex",
    alignItems: "center",
    fontSize: "14px",
    color: "#007600",
    marginBottom: "15px",
  },
  deliveryIcon: {
    marginRight: "5px",
  },
  deliveryText: {
    fontSize: "16px",
    fontWeight: "500",
  },
  addToCartButton: {
    width: "100%",
    padding: "10px",
    fontSize: "14px",
    backgroundColor: "#FFD814",
    border: "1px solid #FCD200",
    borderRadius: "20px",
    cursor: "pointer",
    marginBottom: "10px",
    fontWeight: "bold",
    color: "#111",
    textTransform: "uppercase",
  },
  buyNowButton: {
    width: "100%",
    padding: "10px",
    fontSize: "14px",
    backgroundColor: "#FFA41C",
    border: "1px solid #FF8F00",
    borderRadius: "20px",
    cursor: "pointer",
    marginBottom: "20px",
    fontWeight: "bold",
    color: "#111",
    textTransform: "uppercase",
  },
  detailsContainer: {
    marginTop: "20px",
  },
  subTitle: {
    fontSize: "16px",
    fontWeight: "700",
    marginBottom: "10px",
  },
  detailsList: {
    listStyleType: "disc",
    paddingLeft: "20px",
  },
  detailItem: {
    fontSize: "14px",
    marginBottom: "5px",
  },
  longDescriptionContainer: {
    maxWidth: "1200px",
    margin: "20px auto 0",
    backgroundColor: "#ffffff",
    borderRadius: "8px",
    boxShadow: "0 1px 4px rgba(0, 0, 0, 0.1)",
    padding: "20px",
    overflowX: "auto", // Handle content overflow horizontally
  },
  longDescriptionTitle: {
    fontSize: "24px",
    fontWeight: "400",
    marginBottom: "15px",
    color: "#0F1111",
  },
  longDescriptionContent: {
    fontSize: "14px",
    lineHeight: "20px",
    color: "#333",
    whiteSpace: "normal", // Ensure content adjusts to screen width
  },
  loading: {
    textAlign: "center",
    fontSize: "18px",
    color: "#0066c0",
    padding: "20px",
  },
  error: {
    textAlign: "center",
    fontSize: "18px",
    color: "#B12704",
    padding: "20px",
  },
  "@media (min-width: 768px)": {
    productContainer: {
      flexDirection: "row",
    },
    imageContainer: {
      flex: "0 0 40%",
    },
    productInfo: {
      flex: "0 0 60%",
      paddingLeft: "20px",
    },
  },
};

export default ProductDetail;
