import React, { useState, useEffect } from "react";
import {
  Container,
  Typography,
  Paper,
  TextField,
  Button,
  Grid,
  InputLabel,
  IconButton,
  MenuItem,
  Select,
  FormControl,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { useUserAuth } from "../../context/userAuthContext";
import { storage } from "../../firebase";
import { ref, uploadBytesResumable, getDownloadURL } from "@firebase/storage";
import {
  getFirestore,
  collection,
  doc,
  getDoc,
  setDoc,
} from "firebase/firestore";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import DeleteIcon from "@mui/icons-material/Delete";

const mapContainerStyle = {
  width: "100%",
  height: "300px",
};

const projectTypes = [
  "Topografía",
  "Inspección",
  "Modelado 3D",
  "Cartografía",
  "Web Mapping",
  "Análisis Espacial",
  "Localización de Terrenos",
];

const AddProject = () => {
  const { user } = useUserAuth();
  const navigate = useNavigate();
  const { id } = useParams(); // Get the project ID from the URL parameters
  const [userUid, setUserUid] = useState("");

  useEffect(() => {
    if (user) {
      setUserUid(user.uid);
    }
  }, [user]);

  const [projectData, setProjectData] = useState({
    ownerName: userUid || "",
    storeName: "",
    address: "",
    phoneNumber: "",
    latitude: "",
    longitude: "",
    photo: null,
    description: "",
    projectType: "",
    clients: [], // Agregar clientes
  });

  useEffect(() => {
    const fetchProjectData = async () => {
      if (id) {
        try {
          const firestore = getFirestore();
          const projectRef = doc(firestore, "Projects", id);
          const projectDoc = await getDoc(projectRef);
          if (projectDoc.exists()) {
            setProjectData({
              ...projectDoc.data(),
              photo: null,
              clients: projectDoc.data().clients || [],
            });
          }
        } catch (error) {
          console.error("Error fetching project data:", error.message);
        }
      }
    };

    fetchProjectData();
  }, [id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProjectData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleMapClick = (e) => {
    const { latLng } = e;
    setProjectData((prevData) => ({
      ...prevData,
      latitude: latLng.lat().toString(),
      longitude: latLng.lng().toString(),
    }));
  };

  const handlePhotoChange = (e) => {
    const file = e.target.files[0];
    setProjectData((prevData) => ({ ...prevData, photo: file }));
  };

  const handleAddClient = () => {
    setProjectData((prevData) => ({
      ...prevData,
      clients: [...prevData.clients, ""],
    }));
  };

  const handleClientChange = (index, value) => {
    const updatedClients = [...projectData.clients];
    updatedClients[index] = value;
    setProjectData((prevData) => ({
      ...prevData,
      clients: updatedClients,
    }));
  };

  const handleRemoveClient = (index) => {
    const updatedClients = [...projectData.clients];
    updatedClients.splice(index, 1);
    setProjectData((prevData) => ({
      ...prevData,
      clients: updatedClients,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const firestore = getFirestore();
      let photoUrl = projectData.photoUrl;

      if (projectData.photo) {
        const storageRef = ref(
          storage,
          `project_images/${userUid}/${projectData.photo.name}`
        );
        await uploadBytesResumable(storageRef, projectData.photo);
        photoUrl = await getDownloadURL(storageRef);
      }

      const projectRef = id
        ? doc(firestore, "Projects", id)
        : doc(collection(firestore, "Projects"));
      await setDoc(projectRef, {
        ownerName: projectData.ownerName,
        storeName: projectData.storeName,
        address: projectData.address,
        phoneNumber: projectData.phoneNumber,
        latitude: projectData.latitude,
        longitude: projectData.longitude,
        photo: photoUrl,
        description: projectData.description,
        projectType: projectData.projectType,
        clients: projectData.clients,
      });

      alert(`Project ${id ? "updated" : "added"} successfully!`);
      navigate("/");
    } catch (error) {
      console.error(
        `Error ${id ? "updating" : "adding"} project:`,
        error.message
      );
    }
  };

  return (
    <Container maxWidth="sm">
      <Paper elevation={3} sx={{ padding: 3, marginTop: 2 }}>
        <Typography variant="h4" gutterBottom>
          {id ? "Edit Project" : "Add Project"}
        </Typography>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Store Name"
                type="text"
                name="storeName"
                value={projectData.storeName}
                onChange={handleChange}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Address"
                type="text"
                name="address"
                value={projectData.address}
                onChange={handleChange}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Phone Number"
                type="text"
                name="phoneNumber"
                value={projectData.phoneNumber}
                onChange={handleChange}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth required>
                <InputLabel id="project-type-label">Project Type</InputLabel>
                <Select
                  labelId="project-type-label"
                  name="projectType"
                  value={projectData.projectType}
                  onChange={handleChange}
                >
                  {projectTypes.map((type, index) => (
                    <MenuItem key={index} value={type}>
                      {type}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <InputLabel htmlFor="photo">Project Photo:</InputLabel>
              <input
                type="file"
                accept="image/*"
                id="photo"
                style={{ display: "none" }}
                onChange={handlePhotoChange}
              />
              <label htmlFor="photo">
                <IconButton
                  color="primary"
                  aria-label="upload picture"
                  component="span"
                >
                  <PhotoCameraIcon />
                </IconButton>
              </label>
              {projectData.photo ? (
                <img
                  src={URL.createObjectURL(projectData.photo)}
                  alt="Project Preview"
                  style={{ marginTop: "10px", maxWidth: "100%" }}
                />
              ) : (
                projectData.photoUrl && (
                  <img
                    src={projectData.photoUrl}
                    alt="Project Preview"
                    style={{ marginTop: "10px", maxWidth: "100%" }}
                  />
                )
              )}
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Latitude"
                type="text"
                name="latitude"
                value={projectData.latitude}
                onChange={handleChange}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Longitude"
                type="text"
                name="longitude"
                value={projectData.longitude}
                onChange={handleChange}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Description"
                multiline
                rows={6}
                name="description"
                value={projectData.description}
                onChange={handleChange}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6">Clients</Typography>
              {projectData.clients.map((client, index) => (
                <Grid container spacing={1} alignItems="center" key={index}>
                  <Grid item xs={10}>
                    <TextField
                      fullWidth
                      label={`Client ${index + 1} Email`}
                      type="email"
                      value={client}
                      onChange={(e) =>
                        handleClientChange(index, e.target.value)
                      }
                      required
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <IconButton
                      color="secondary"
                      onClick={() => handleRemoveClient(index)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              ))}
              <Button
                variant="contained"
                color="primary"
                onClick={handleAddClient}
                startIcon={<AddCircleIcon />}
                sx={{ mt: 2 }}
              >
                Add Client
              </Button>
            </Grid>
            <Grid item xs={12}>
              <LoadScript googleMapsApiKey="AIzaSyBSXE8zZ-SqMWdDz16hr00Djvkm3mMit28">
                <GoogleMap
                  mapContainerStyle={mapContainerStyle}
                  center={{ lat: 29.110262, lng: -110.940463 }}
                  zoom={15}
                  onClick={(e) => handleMapClick(e)}
                >
                  {projectData.latitude && projectData.longitude && (
                    <Marker
                      position={{
                        lat: parseFloat(projectData.latitude),
                        lng: parseFloat(projectData.longitude),
                      }}
                    />
                  )}
                </GoogleMap>
              </LoadScript>
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="contained"
                fullWidth
                color="primary"
                type="submit"
              >
                {id ? "Update Project" : "Add Project"}
              </Button>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </Container>
  );
};

export default AddProject;
