import React, { useState, useEffect } from "react";
import { collection, onSnapshot, doc } from "firebase/firestore";
import { db } from "../../firebase";
import {
  Box,
  Typography,
  Card,
  CardContent,
  CircularProgress,
  LinearProgress,
} from "@mui/material";
import { blueGrey } from "@mui/material/colors";

const BatteryPrediction = ({ uid }) => {
  const [stats, setStats] = useState([]);
  const [remainingTime, setRemainingTime] = useState(null);
  const [loading, setLoading] = useState(true); // Para mostrar spinner mientras se calculan los datos
  const [error, setError] = useState(false); // Para mostrar errores si los hay
  const voltageThreshold = 11; // Umbral para predicción de batería

  useEffect(() => {
    const enlaceRef = doc(db, "Enlaces", uid);
    const statsRef = collection(enlaceRef, "stats");

    const unsubscribe = onSnapshot(statsRef, (snapshot) => {
      const statsList = snapshot.docs.map((doc) => {
        const data = doc.data();
        const timestamp = new Date(data.timestamp.seconds * 1000);
        return {
          timestamp,
          voltage: data.voltage,
        };
      });

      // Usar solo los últimos 20 datos si existen
      const recentStats = statsList.slice(-20);

      if (recentStats.length >= 2) {
        setError(false); // Resetea el error si hay suficientes datos
        calculateRemainingTime(recentStats);
      } else {
        setError(true); // Si no hay suficientes datos, mostrar error
      }

      setStats(recentStats);
      setLoading(false); // Ocultar el spinner una vez que se realiza el cálculo
    });

    return () => unsubscribe();
  }, [uid]);

  // Calcular la predicción del tiempo restante de batería hasta llegar a 11V
  const calculateRemainingTime = (recentStats) => {
    let totalTimeDifference = 0; // Tiempo total en horas entre mediciones
    let totalVoltageDrop = 0; // Caída total de voltaje entre mediciones

    for (let i = 1; i < recentStats.length; i++) {
      const previousStat = recentStats[i - 1];
      const currentStat = recentStats[i];

      // Calcular la diferencia de tiempo entre cada par de mediciones en horas
      const timeDifference =
        (currentStat.timestamp - previousStat.timestamp) / 3600000; // Convertir ms a horas

      // Calcular la diferencia de voltaje entre cada par de mediciones
      const voltageDrop = previousStat.voltage - currentStat.voltage;

      // Acumular la diferencia de tiempo y la caída de voltaje
      totalTimeDifference += timeDifference;
      totalVoltageDrop += voltageDrop;
    }

    // Calcular la tasa promedio de descarga por hora
    const averageVoltageDropPerHour = totalVoltageDrop / totalTimeDifference;

    // Si la tasa de descarga es válida, calcular las horas restantes
    if (averageVoltageDropPerHour > 0) {
      const latestVoltage = recentStats[recentStats.length - 1].voltage;
      const hoursRemaining =
        (latestVoltage - voltageThreshold) / averageVoltageDropPerHour;

      // Convertimos las horas restantes en un formato de horas y minutos
      const roundedHours = Math.floor(hoursRemaining); // Horas completas
      const minutesRemaining = Math.floor((hoursRemaining - roundedHours) * 60); // Minutos restantes
      setRemainingTime({ hours: roundedHours, minutes: minutesRemaining });
    } else {
      setRemainingTime("N/A"); // No se puede predecir si no hay suficientes datos o el voltaje está aumentando
    }
  };

  // UI mejorada
  return (
    <Card sx={{ mt: 4, borderRadius: 3, boxShadow: 3, bgcolor: blueGrey[50] }}>
      <CardContent>
        <Typography variant="h5" gutterBottom color="primary">
          Predicción del tiempo de batería restante
        </Typography>
        {loading ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            minHeight="150px"
          >
            <CircularProgress />
          </Box>
        ) : error ? (
          <Typography variant="body1" color="error">
            No se pudo predecir el tiempo restante. Se necesitan más datos.
          </Typography>
        ) : remainingTime !== null && remainingTime !== "N/A" ? (
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
          >
            <Typography variant="h4" color="textSecondary">
              {remainingTime.hours} horas {remainingTime.minutes} minutos
            </Typography>
            <Typography variant="body1" color="textSecondary">
              hasta que la batería llegue a 11V.
            </Typography>
            <LinearProgress
              variant="determinate"
              value={
                (100 * (remainingTime.hours * 60 + remainingTime.minutes)) /
                (24 * 60)
              } // Simulación para mostrar una barra de progreso en base a 24 horas
              sx={{ width: "100%", mt: 2, height: 10, borderRadius: 5 }}
            />
          </Box>
        ) : (
          <Typography variant="body1" color="textSecondary">
            No se puede predecir el tiempo restante en este momento.
          </Typography>
        )}
      </CardContent>
    </Card>
  );
};

export default BatteryPrediction;
