import React from "react";
import algoliasearch from "algoliasearch/lite";
import {
  InstantSearch,
  SearchBox,
  Hits,
  Highlight,
  Pagination,
  Configure,
} from "react-instantsearch-dom";
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Typography,
  CardActionArea,
  Grid,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Link } from "react-router-dom";

const searchClient = algoliasearch(
  "CE2DHOJRGG",
  "b8c31052f535789944e9689e8f549bb4"
);

const Hit = ({ hit }) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Grid item xs={12} sm={6} md={4} lg={3}>
      <Link to={`/producto/${hit.objectID}`} style={{ textDecoration: "none" }}>
        <Card
          sx={{
            display: "flex",
            flexDirection: matches ? "column" : "row",
            marginBottom: 2,
          }}
        >
          <CardActionArea>
            <CardMedia
              component="img"
              sx={{
                width: matches ? "100%" : 151,
                height: matches ? 200 : "auto",
              }}
              image={hit["Imagen Principal"]}
              alt={`Imagen de ${hit.Título}`}
            />
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <CardContent sx={{ flex: "1 0 auto" }}>
                <Typography component="div" variant="h5">
                  <Highlight attribute="Título" hit={hit} tagName="mark" />
                </Typography>
                <Typography
                  variant="subtitle1"
                  color="text.secondary"
                  component="div"
                >
                  Modelo:{" "}
                  <Highlight attribute="Modelo" hit={hit} tagName="mark" />
                </Typography>
              </CardContent>
            </Box>
          </CardActionArea>
        </Card>
      </Link>
    </Grid>
  );
};

const ProductList = () => {
  return (
    <InstantSearch searchClient={searchClient} indexName="radeo">
      <Configure
        attributesToRetrieve={["Título", "Modelo", "Imagen Principal"]}
      />
      <Box sx={{ padding: 2 }}>
        <SearchBox />
        <Grid container spacing={2}>
          <Hits hitComponent={Hit} />
        </Grid>
        <Pagination />
      </Box>
    </InstantSearch>
  );
};

export default ProductList;
