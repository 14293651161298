import React, { useState, useEffect } from "react";
import { db } from "../../firebase"; // Importa Firestore desde firebase.js
import {
  collection,
  doc,
  getDocs,
  setDoc,
  updateDoc,
  query,
  orderBy,
  limit,
} from "firebase/firestore";
import {
  TextField,
  Button,
  Card,
  CardContent,
  Typography,
  Grid,
  Paper,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
} from "@mui/material";

const NuevaMarca = () => {
  const [marca, setMarca] = useState("");
  const [modelo, setModelo] = useState("");
  const [modelos, setModelos] = useState([]);
  const [message, setMessage] = useState("");
  const [nextId, setNextId] = useState(1);
  const [marcasExistentes, setMarcasExistentes] = useState([]);
  const [marcaSeleccionada, setMarcaSeleccionada] = useState("");

  useEffect(() => {
    const fetchMarcas = async () => {
      try {
        const q = query(collection(db, "marcas"), orderBy("id", "asc"));
        const querySnapshot = await getDocs(q);

        const marcas = querySnapshot.docs.map((doc) => ({
          id: doc.data().id,
          nombre: doc.data().nombre,
          modelos: doc.data().modelos || [],
        }));

        setMarcasExistentes(marcas);

        if (marcas.length > 0) {
          setNextId(marcas[marcas.length - 1].id + 1);
        }
      } catch (error) {
        console.error("Error al obtener las marcas:", error);
      }
    };

    fetchMarcas();
  }, []);

  const handleAddMarca = async () => {
    if (!marca.trim() && !marcaSeleccionada) {
      setMessage("El nombre de la marca es obligatorio.");
      return;
    }

    try {
      if (marcaSeleccionada) {
        // Actualizar la marca existente
        const marcaDocRef = doc(db, "marcas", marcaSeleccionada);
        const marcaSeleccionadaDoc = marcasExistentes.find(
          (m) => m.id === parseInt(marcaSeleccionada)
        );

        await updateDoc(marcaDocRef, {
          modelos: [...marcaSeleccionadaDoc.modelos, ...modelos],
        });

        setMessage("Modelos agregados a la marca existente con éxito!");
      } else {
        // Crear una nueva marca
        const newDocRef = doc(db, "marcas", nextId.toString());

        await setDoc(newDocRef, {
          id: nextId,
          nombre: marca,
          modelos,
        });

        setMessage("Marca agregada con éxito!");
        setNextId(nextId + 1);
      }

      setMarca("");
      setModelo("");
      setModelos([]);
      setMarcaSeleccionada("");
    } catch (error) {
      console.error("Error al agregar la marca:", error);
      setMessage("Error al agregar la marca. Intenta nuevamente.");
    }
  };

  const handleAddModelo = () => {
    if (modelo.trim()) {
      setModelos((prev) => [...prev, modelo]);
      setModelo("");
    }
  };

  const handleRemoveModelo = (index) => {
    setModelos((prev) => prev.filter((_, i) => i !== index));
  };

  return (
    <div style={{ padding: 20 }}>
      <Card>
        <CardContent>
          <Typography variant="h5" component="div" gutterBottom>
            Crear Nueva Marca o Agregar Modelos
          </Typography>
          <FormControl fullWidth style={{ marginBottom: 20 }}>
            <InputLabel id="marca-select-label">Seleccionar Marca</InputLabel>
            <Select
              labelId="marca-select-label"
              value={marcaSeleccionada}
              onChange={(e) => setMarcaSeleccionada(e.target.value)}
              label="Seleccionar Marca"
            >
              <MenuItem value="">
                <em>Nueva Marca</em>
              </MenuItem>
              {marcasExistentes.map((m) => (
                <MenuItem key={m.id} value={m.id.toString()}>
                  {m.nombre}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {!marcaSeleccionada && (
            <TextField
              fullWidth
              label="Nombre de la Marca"
              variant="outlined"
              value={marca}
              onChange={(e) => setMarca(e.target.value)}
              style={{ marginBottom: 20 }}
              required
            />
          )}
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Nombre del Modelo"
                variant="outlined"
                value={modelo}
                onChange={(e) => setModelo(e.target.value)}
                style={{ marginBottom: 20 }}
              />
              <Button
                variant="contained"
                color="secondary"
                onClick={handleAddModelo}
                style={{ marginBottom: 20 }}
              >
                Agregar Modelo
              </Button>
            </Grid>
          </Grid>
          {modelos.length > 0 && (
            <div style={{ marginBottom: 20 }}>
              <Typography variant="h6">Modelos:</Typography>
              <Grid container spacing={2}>
                {modelos.map((mod, index) => (
                  <Grid item xs={12} sm={6} md={4} key={index}>
                    <Paper
                      elevation={3}
                      style={{ padding: 10, position: "relative" }}
                    >
                      <Typography variant="body1">{mod}</Typography>
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => handleRemoveModelo(index)}
                        style={{
                          position: "absolute",
                          top: 5,
                          right: 5,
                        }}
                      >
                        Eliminar
                      </Button>
                    </Paper>
                  </Grid>
                ))}
              </Grid>
            </div>
          )}
          <Button variant="contained" color="primary" onClick={handleAddMarca}>
            {marcaSeleccionada ? "Agregar Modelos" : "Crear Marca"}
          </Button>
          {message && (
            <Typography
              variant="body1"
              color="text.secondary"
              style={{ marginTop: 20 }}
            >
              {message}
            </Typography>
          )}
        </CardContent>
      </Card>
    </div>
  );
};

export default NuevaMarca;
