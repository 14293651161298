import React from "react";
import {
  Box,
  Typography,
  Grid,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  useTheme,
  styled,
  Container,
} from "@mui/material";
import { Link } from "react-router-dom";
import LockIcon from "@mui/icons-material/Lock";
import RadioIcon from "@mui/icons-material/Radio";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import Marcas from "./Marcas";

const bannerImage =
  "https://ftp3.syscom.mx/usuarios/ftp/marcas_banner/tassta.jpg";
const radioImage =
  "https://ftp3.syscom.mx/usuarios/ftp/marcas_banner/kenwood.jpg";
const pocImage =
  "https://tassta.com/wp-content/uploads/2022/11/banner-m22-1024x629.png";

const Root = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
}));

const Banner = styled(CardMedia)(({ theme }) => ({
  height: 300,
  position: "relative",
  "&::after": {
    content: '""',
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
}));

const BannerContent = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  textAlign: "center",
  zIndex: 1,
  color: theme.palette.common.white,
}));

const StyledCard = styled(Card)(({ theme }) => ({
  height: "100%",
  display: "flex",
  flexDirection: "column",
  transition: "transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out",
  "&:hover": {
    transform: "translateY(-8px)",
    boxShadow: theme.shadows[8],
  },
}));

const CardImageWrapper = styled(Box)({
  position: "relative",
  paddingTop: "56.25%", // 16:9 aspect ratio
  overflow: "hidden",
});

const CardImage = styled(CardMedia)({
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  objectFit: "cover",
});

const CardOverlay = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  backgroundColor: "rgba(0, 0, 0, 0.4)",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  opacity: 0,
  transition: "opacity 0.3s ease-in-out",
  "&:hover": {
    opacity: 1,
  },
}));

const LinkStyled = styled(Link)({
  textDecoration: "none",
  color: "inherit",
  display: "block",
  height: "100%",
});

const CardTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 700,
  marginBottom: theme.spacing(2),
}));

const CardDescription = styled(Typography)(({ theme }) => ({
  flexGrow: 1,
}));

export default function RadioCommunication() {
  const theme = useTheme();

  return (
    <Root>
      <Banner image={bannerImage}>
        <BannerContent>
          <Typography variant="h3" component="h1" gutterBottom>
            Comunicaciones Seguras
          </Typography>
          <Typography variant="h5">
            Soluciones de radiocomunicación avanzadas y confiables
          </Typography>
        </BannerContent>
      </Banner>

      <Container maxWidth="lg" sx={{ py: 8 }}>
        <Typography variant="h4" align="center" gutterBottom>
          Soluciones de Radiocomunicación
        </Typography>
        <Typography variant="body1" align="center" paragraph sx={{ mb: 6 }}>
          Ofrecemos tecnología de punta en radiocomunicación, diseñada para
          garantizar la máxima seguridad y eficiencia en sus operaciones.
        </Typography>

        <Grid container spacing={4}>
          <Grid item xs={12} sm={6}>
            <LinkStyled to="/radiosconvencionales">
              <StyledCard>
                <CardImageWrapper>
                  <CardImage image={radioImage} />
                  <CardOverlay>
                    <Typography variant="h6" color="white">
                      Explorar Soluciones
                    </Typography>
                  </CardOverlay>
                </CardImageWrapper>
                <CardContent>
                  <CardTitle variant="h5" component="h2">
                    Radios Convencionales
                  </CardTitle>
                  <CardDescription variant="body2">
                    Sistemas de radio avanzados con encriptación de última
                    generación para comunicaciones seguras y confiables.
                  </CardDescription>
                </CardContent>
              </StyledCard>
            </LinkStyled>
          </Grid>

          <Grid item xs={12} sm={6}>
            <LinkStyled to="/radiospoc">
              <StyledCard>
                <CardImageWrapper>
                  <CardImage image={pocImage} />
                  <CardOverlay>
                    <Typography variant="h6" color="white">
                      Descubrir Tecnología
                    </Typography>
                  </CardOverlay>
                </CardImageWrapper>
                <CardContent>
                  <CardTitle variant="h5" component="h2">
                    Equipos POC
                  </CardTitle>
                  <CardDescription variant="body2">
                    Soluciones POC de alta seguridad que utilizan redes
                    celulares encriptadas para comunicaciones instantáneas y
                    protegidas.
                  </CardDescription>
                </CardContent>
              </StyledCard>
            </LinkStyled>
          </Grid>
        </Grid>
        <Marcas />
      </Container>
    </Root>
  );
}
