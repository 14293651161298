import React from "react";
import { Box, Typography } from "@mui/material";
import { PieChart, Pie, Cell, Tooltip, ResponsiveContainer } from "recharts";

const COLORS = ["#00C49F", "#FFBB28"]; // Colores con más contraste y modernidad

const InventoryChart = ({ inventoryCount }) => {
  const pieData = [
    { name: "Productos en Inventario", value: inventoryCount },
    { name: "Espacio Libre", value: 1000 - inventoryCount }, // Solo para ilustrar
  ];

  return (
    <Box sx={{ display: "flex", justifyContent: "center", my: 4 }}>
      <ResponsiveContainer width="100%" height={300}>
        <PieChart>
          <Pie
            data={pieData}
            cx="50%"
            cy="50%"
            innerRadius={80}
            outerRadius={120}
            fill="#8884d8"
            paddingAngle={5}
            dataKey="value"
          >
            {pieData.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={COLORS[index % COLORS.length]}
              />
            ))}
          </Pie>
          <Tooltip
            contentStyle={{
              backgroundColor: "#1a1a1a",
              borderColor: "#00ffff",
              color: "#ffffff",
            }}
            itemStyle={{ color: "#00ffff" }}
          />
        </PieChart>
      </ResponsiveContainer>
    </Box>
  );
};

export default InventoryChart;
