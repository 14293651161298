import React, { useEffect, useState } from "react";
import {
  Container,
  Paper,
  Typography,
  Grid,
  CircularProgress,
  Box,
} from "@mui/material";
import { collection, query, where, getDocs } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import { db } from "../../firebase";

const UnidadInspections = ({ unidad }) => {
  const [inspections, setInspections] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchInspections = async () => {
      try {
        const q = query(
          collection(db, "inspecciones"),
          where("Unidad", "==", unidad)
        );
        const querySnapshot = await getDocs(q);
        const inspectionsData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setInspections(inspectionsData);
      } catch (error) {
        console.error("Error al obtener las inspecciones:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchInspections();
  }, [unidad]);

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
        bgcolor="#0d0d0d"
      >
        <CircularProgress color="secondary" />
      </Box>
    );
  }

  const handleInspectionClick = (id) => {
    navigate(`/inspeccion/${id}`);
  };

  return (
    <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
      <Paper
        sx={{
          p: { xs: 2, sm: 4 },
          borderRadius: 4,
          boxShadow: "0 8px 24px rgba(0, 255, 255, 0.2)",
          backgroundColor: "#1a1a1a",
          color: "#00ffff",
        }}
      >
        <Typography
          variant="h5"
          align="center"
          gutterBottom
          sx={{
            color: "#00ffff",
            borderBottom: "3px solid #00ffff",
            paddingBottom: "12px",
            fontWeight: "bold",
            fontSize: { xs: "1.5rem", sm: "2rem" },
          }}
        >
          Inspecciones: {unidad}
        </Typography>

        <Typography
          variant="h6"
          align="center"
          sx={{
            mt: 2,
            color: "#ffffff",
            fontSize: { xs: "1rem", sm: "1.25rem" },
          }}
        >
          Total de Inspecciones: {inspections.length}
        </Typography>

        <Grid container spacing={2} sx={{ mt: 2 }}>
          {inspections.map((inspection) => (
            <Grid
              item
              xs={12}
              key={inspection.id}
              onClick={() => handleInspectionClick(inspection.id)}
              sx={{ cursor: "pointer" }}
            >
              <Paper
                sx={{
                  p: 2,
                  borderRadius: 4,
                  backgroundColor: "#2b2b2b",
                  boxShadow: "0 4px 16px rgba(0, 255, 255, 0.3)",
                  color: "#ffffff",
                  transition: "box-shadow 0.3s",
                  "&:hover": {
                    boxShadow: "0 6px 20px rgba(0, 255, 255, 0.5)",
                  },
                }}
              >
                <Grid container alignItems="center" spacing={2}>
                  <Grid item xs={8} sm={9}>
                    <Typography
                      variant="h6"
                      gutterBottom
                      sx={{
                        color: "#00ffff",
                        fontSize: { xs: "1rem", sm: "1.25rem" },
                      }}
                    >
                      Inspección ID: {inspection.id}
                    </Typography>
                    <Typography
                      variant="body2"
                      gutterBottom
                      sx={{ fontSize: { xs: "0.875rem", sm: "1rem" } }}
                    >
                      <strong>Fecha:</strong> {inspection.Date}
                    </Typography>
                    <Typography
                      variant="body2"
                      gutterBottom
                      sx={{ fontSize: { xs: "0.875rem", sm: "1rem" } }}
                    >
                      <strong>Status:</strong> {inspection.Status}
                    </Typography>
                    <Typography
                      variant="body2"
                      gutterBottom
                      sx={{ fontSize: { xs: "0.875rem", sm: "1rem" } }}
                    >
                      <strong>Comentario:</strong>{" "}
                      {inspection.Comentario || "N/A"}
                    </Typography>
                  </Grid>
                  <Grid item xs={4} sm={3}>
                    {inspection.fotos && inspection.fotos["Foto unidad"] ? (
                      <img
                        src={inspection.fotos["Foto unidad"]}
                        alt="Foto unidad"
                        style={{
                          width: "100%",
                          height: "auto",
                          borderRadius: 4,
                          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.3)",
                        }}
                      />
                    ) : (
                      <Typography
                        variant="body2"
                        sx={{
                          color: "#ff0000",
                          textAlign: "center",
                          fontSize: { xs: "0.75rem", sm: "0.875rem" },
                        }}
                      >
                        No hay foto disponible
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          ))}
        </Grid>
      </Paper>
    </Container>
  );
};

export default UnidadInspections;
