import React, { useState, useEffect } from "react";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import { Box, Typography } from "@mui/material";

const containerStyle = {
  width: "400px",
  height: "400px",
  borderRadius: "15px",
  overflow: "hidden",
};

const center = {
  lat: 29.129467,
  lng: -110.937223,
};

function MyMapComponent() {
  const [markerIcon, setMarkerIcon] = useState();

  useEffect(() => {
    if (window.google) {
      setMarkerIcon({
        url: "https://firebasestorage.googleapis.com/v0/b/radiosistemas-a46cd.appspot.com/o/RMC264636EFFF4_0.png?alt=media&token=8ae42d00-bbd8-43c2-825d-945d8c8f333e", // Reemplaza con la URL de la imagen que deseas mostrar
        scaledSize: new window.google.maps.Size(64, 64),
        anchor: new window.google.maps.Point(32, 32),
      });
    }
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: 2,
      }}
    >
      <Typography variant="h6" gutterBottom>
        Cobertura
      </Typography>
      <LoadScript googleMapsApiKey="AIzaSyBSXE8zZ-SqMWdDz16hr00Djvkm3mMit28">
        <GoogleMap mapContainerStyle={containerStyle} center={center} zoom={10}>
          {markerIcon && <Marker position={center} icon={markerIcon} />}
        </GoogleMap>
      </LoadScript>
    </Box>
  );
}

export default MyMapComponent;
