import React from "react";
import {
  Box,
  Typography,
  Grid,
  Card,
  CardContent,
  CardMedia,
} from "@mui/material";
import { styled } from "@mui/system";
import { Security, Build, AccessTime } from "@mui/icons-material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import RssFeedIcon from "@mui/icons-material/RssFeed";

const theme = createTheme({
  palette: {
    primary: {
      main: "#107ee9",
    },
    secondary: {
      main: "#f50057",
    },
    background: {
      default: "#f5f5f5",
    },
  },
  typography: {
    fontFamily: '"Poppins", "Roboto", "Helvetica", "Arial", sans-serif',
    h2: {
      fontSize: "3.5rem",
      fontWeight: 700,
      marginBottom: "3rem",
      color: "#000000",
    },
    h5: {
      fontSize: "1.5rem",
      fontWeight: 600,
      marginBottom: "1rem",
    },
    body1: {
      fontSize: "1rem",
      lineHeight: 1.8,
      color: "#000000", // Hacemos todo el texto de la descripción del mismo color
    },
  },
});

const FeaturesContainer = styled(Box)(({ theme }) => ({
  padding: "6rem 2rem", // Reducimos el padding superior
  background: "linear-gradient(135deg, #f5f5f5 0%, #e0e0e0 100%)",
}));

const FeatureCard = styled(Card)(({ theme }) => ({
  height: "100%",
  display: "flex",
  flexDirection: "column",
  borderRadius: "16px",
  overflow: "hidden",
  boxShadow: "0 10px 30px rgba(0, 0, 0, 0.1)",
  transition: "all 0.3s ease-in-out",
}));

const IconContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "64px",
  height: "64px",
  borderRadius: "50%",
  backgroundColor: "#ffffff",
  margin: "0 auto",
  marginBottom: theme.spacing(2),
  "& svg": {
    fontSize: "2rem",
    color: "#107ee9",
  },
}));

const features = [
  {
    title: "Puntos de Repetición",
    description:
      "Contamos con puntos de repetición de radio frecuencias estratégicamente ubicados en Hermosillo, Sonora.",
    icon: <RssFeedIcon />,
    media:
      "https://firebasestorage.googleapis.com/v0/b/radiosistemas-a46cd.appspot.com/o/122094597_182670086833241_1973500611783783661_n-1500w.jpg?alt=media&token=9d945a7a-ccb3-4beb-8430-5108ccfd9f2d",
    details:
      "Ofrecemos espacios disponibles para el alquiler de repetidoras, permitiendo una conexión confiable y de calidad para sus comunicaciones.",
  },
  {
    title: "Equipamiento",
    description:
      "Nuestro equipo comprende la importancia de la seguridad pública y está comprometido con brindar soluciones confiables.",
    icon: <Security />,
    media:
      "https://firebasestorage.googleapis.com/v0/b/radiosistemas-a46cd.appspot.com/o/frente%20nice-1500w.png?alt=media&token=c23d47fe-56ee-4d79-acac-3c3788079e82",
    details:
      "Instalamos equipos de seguridad de manera profesional, siguiendo las mejores prácticas de la industria.",
  },
  {
    title: "Tecnologías de Vanguardia",
    description:
      "Nuestros drones están equipados con las últimas características y capacidades para operaciones de seguridad.",
    icon: <Build />,
    media:
      "https://firebasestorage.googleapis.com/v0/b/radiosistemas-a46cd.appspot.com/o/img_20230613_1423511-1500w.jpg?alt=media&token=59d631dd-3db5-42db-abc5-94659d551fb5",
    details:
      "Utilizamos drones para vigilancia, monitoreo aéreo, y tareas de búsqueda y rescate, ofreciendo herramientas efectivas y avanzadas.",
  },
  {
    title: "Experiencia",
    description:
      "Más de 30 años de experiencia en el campo de la seguridad, ofreciendo soluciones tanto para clientes públicos como privados.",
    icon: <AccessTime />,
    media:
      "https://firebasestorage.googleapis.com/v0/b/radiosistemas-a46cd.appspot.com/o/aereas-_entrega_de_patrullas_03-1500w.jpg?alt=media&token=a4b133aa-48f3-4e57-9d71-24b1510a0927",
    details:
      "Con tres décadas de experiencia, garantizamos la implementación y mantenimiento de soluciones de seguridad confiables.",
  },
];

const FeatureItem = ({ feature, index }) => {
  return (
    <Grid item xs={12} sm={6} md={3}>
      <FeatureCard>
        <CardMedia
          component={
            feature.media.match(/\.(mp4|webm|ogg)$/i) ? "video" : "img"
          }
          image={
            !feature.media.match(/\.(mp4|webm|ogg)$/i)
              ? feature.media
              : undefined
          }
          src={
            feature.media.match(/\.(mp4|webm|ogg)$/i)
              ? feature.media
              : undefined
          }
          alt={feature.title}
          sx={{ height: 200, objectFit: "cover" }} // Ajustamos la imagen a su tamaño completo
          {...(feature.media.match(/\.(mp4|webm|ogg)$/i) && {
            autoPlay: true,
            loop: true,
            muted: true,
            playsInline: true,
          })}
        />
        <CardContent>
          <IconContainer>{feature.icon}</IconContainer>
          <Typography variant="h5" component="h3" gutterBottom align="center">
            {feature.title}
          </Typography>
          <Typography variant="body1" paragraph>
            {feature.description}
          </Typography>
          <Typography variant="body1">{feature.details}</Typography>
        </CardContent>
      </FeatureCard>
    </Grid>
  );
};

const Features = () => {
  return (
    <ThemeProvider theme={theme}>
      <FeaturesContainer id="features">
        <Grid container spacing={4}>
          {features.map((feature, index) => (
            <FeatureItem key={index} feature={feature} index={index} />
          ))}
        </Grid>
      </FeaturesContainer>
    </ThemeProvider>
  );
};

export default Features;
