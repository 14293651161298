import React, { useEffect, useState } from "react";
import {
  Container,
  Grid,
  Box,
  Typography,
  Paper,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../firebase";
import { useNavigate } from "react-router-dom";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import PlaceIcon from "@mui/icons-material/Place";
import GppMaybeIcon from "@mui/icons-material/GppMaybe";
import ElectricCarIcon from "@mui/icons-material/ElectricCar";

const SearchUnidades = () => {
  const [unidades, setUnidades] = useState([]);
  const [filteredUnidades, setFilteredUnidades] = useState([]);
  const [searchUnidad, setSearchUnidad] = useState("");
  const [selectedZona, setSelectedZona] = useState("");
  const [selectedRequerida, setSelectedRequerida] = useState("");
  const navigate = useNavigate();

  const requeridas = ["TRUE", "FALSE"]; // Definición de la variable requeridas

  const sortedZonas = (zonas) => {
    return zonas.sort((a, b) => a.localeCompare(b));
  };

  const zonas = sortedZonas([
    ...new Set(unidades.map((unidad) => unidad.Zona)),
  ]);

  useEffect(() => {
    const fetchUnidades = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "unidades"));
        const unidadesData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        unidadesData.sort((a, b) => a.Unidad.localeCompare(b.Unidad));
        setUnidades(unidadesData);
        setFilteredUnidades(unidadesData);
      } catch (error) {
        console.error("Error al obtener las unidades:", error);
      }
    };

    fetchUnidades();
  }, []);

  useEffect(() => {
    let filtered = unidades;

    if (searchUnidad) {
      filtered = filtered.filter((unidad) =>
        unidad.Unidad.toLowerCase().includes(searchUnidad.toLowerCase())
      );
    }

    if (selectedZona) {
      filtered = filtered.filter((unidad) => unidad.Zona === selectedZona);
    }

    if (selectedRequerida) {
      filtered = filtered.filter(
        (unidad) => unidad.Requerida === selectedRequerida
      );
    }

    setFilteredUnidades(filtered);
  }, [searchUnidad, selectedZona, selectedRequerida, unidades]);

  const handleUnidadClick = (id) => {
    navigate(`/unidad/${id}`);
  };

  return (
    <Box
      sx={{
        bgcolor: "#0d0d0d",
        minHeight: "100vh",
        py: 4,
        px: 2,
      }}
    >
      <Container maxWidth="lg">
        <Paper
          sx={{
            p: 4,
            borderRadius: 4,
            boxShadow: "0 8px 24px rgba(0, 255, 255, 0.2)",
            backgroundColor: "#1a1a1a",
            color: "#00ffff",
          }}
        >
          <Typography
            variant="h4"
            align="center"
            gutterBottom
            sx={{
              color: "#00ffff",
              borderBottom: "3px solid #00ffff",
              paddingBottom: "12px",
              fontWeight: "bold",
            }}
          >
            Buscar Unidades
          </Typography>

          <Grid container spacing={3} sx={{ my: 3 }}>
            <Grid item xs={12} sm={4}>
              <TextField
                fullWidth
                label="Buscar por Unidad"
                variant="outlined"
                value={searchUnidad}
                onChange={(e) => setSearchUnidad(e.target.value)}
                sx={{
                  input: { color: "#00ffff" },
                  label: { color: "#00ffff" },
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "#00ffff",
                    },
                    "&:hover fieldset": {
                      borderColor: "#00ffff",
                    },
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormControl fullWidth variant="outlined">
                <InputLabel sx={{ color: "#00ffff" }}>Zona</InputLabel>
                <Select
                  value={selectedZona}
                  onChange={(e) => setSelectedZona(e.target.value)}
                  label="Zona"
                  sx={{
                    color: "#00ffff",
                    ".MuiOutlinedInput-notchedOutline": {
                      borderColor: "#00ffff",
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#00ffff",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#00ffff",
                    },
                  }}
                >
                  <MenuItem value="">
                    <em>Todos</em>
                  </MenuItem>
                  {zonas.map((zona, index) => (
                    <MenuItem key={index} value={zona}>
                      {zona}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormControl fullWidth variant="outlined">
                <InputLabel sx={{ color: "#00ffff" }}>Requerida</InputLabel>
                <Select
                  value={selectedRequerida}
                  onChange={(e) => setSelectedRequerida(e.target.value)}
                  label="Requerida"
                  sx={{
                    color: "#00ffff",
                    ".MuiOutlinedInput-notchedOutline": {
                      borderColor: "#00ffff",
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#00ffff",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#00ffff",
                    },
                  }}
                >
                  <MenuItem value="">
                    <em>Todos</em>
                  </MenuItem>
                  {requeridas.map((requerida, index) => (
                    <MenuItem key={index} value={requerida}>
                      {requerida}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          <Grid container spacing={3}>
            {filteredUnidades.map((unidad) => (
              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                key={unidad.id}
                onClick={() => handleUnidadClick(unidad.id)}
                sx={{ cursor: "pointer" }}
              >
                <Paper
                  sx={{
                    p: 3,
                    borderRadius: 4,
                    backgroundColor: "#1a1a1a",
                    boxShadow: "0 4px 16px rgba(0, 255, 255, 0.3)",
                    textAlign: "center",
                    color: "#ffffff",
                    borderColor:
                      unidad.Requerida === "TRUE" ? "#ff0000" : "#00ff00",
                    borderWidth: 2,
                    borderStyle: "solid",
                    "&:hover": {
                      boxShadow: "0 6px 20px rgba(0, 255, 255, 0.5)",
                    },
                  }}
                >
                  <ElectricCarIcon
                    sx={{ fontSize: 40, color: "#00ffff", mb: 1 }}
                  />
                  <Typography
                    variant="h6"
                    gutterBottom
                    sx={{ color: "#00ffff", fontWeight: "bold" }}
                  >
                    Unidad: {unidad.Unidad}
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    <PlaceIcon
                      sx={{ verticalAlign: "middle", color: "#00ffff", mr: 1 }}
                    />
                    <strong>Zona:</strong> {unidad.Zona}
                  </Typography>
                  <Typography variant="body1">
                    <GppMaybeIcon
                      sx={{
                        verticalAlign: "middle",
                        color:
                          unidad.Requerida === "TRUE" ? "#ff0000" : "#00ff00",
                        mr: 1,
                      }}
                    />
                    <strong>Requerida:</strong>{" "}
                    {unidad.Requerida === "TRUE" ? "Sí" : "No"}
                  </Typography>
                </Paper>
              </Grid>
            ))}
          </Grid>
        </Paper>
      </Container>
    </Box>
  );
};

export default SearchUnidades;
