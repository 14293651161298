import React, { useState, useEffect } from "react";
import {
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
  IconButton,
  InputAdornment,
} from "@mui/material";
import { db, storage } from "../../../firebase"; // Importar Firestore y Storage
import { collection, doc, setDoc, onSnapshot } from "firebase/firestore";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import FirebaseRealtimeListener from "./FirebaseRealtimeListener";

const FormProducto = ({
  imagenes,
  setImagenes,
  previewUrls,
  setPreviewUrls,
  setMessage,
}) => {
  const [titulo, setTitulo] = useState("");
  const [numInventario, setNumInventario] = useState("");
  const [selectedCategoriaId, setSelectedCategoriaId] = useState("");
  const [selectedSubCategoria, setSelectedSubCategoria] = useState("");
  const [selectedMarcaId, setSelectedMarcaId] = useState("");
  const [selectedModelo, setSelectedModelo] = useState("");
  const [selectedCliente, setSelectedCliente] = useState("");
  const [subCategorias, setSubCategorias] = useState([]);
  const [modelos, setModelos] = useState([]);
  const [stock, setStock] = useState(1); // Inicializamos el stock en 1
  const [uploading, setUploading] = useState(false);

  // Escuchar categorías, marcas y clientes en tiempo real desde Firebase
  const { categorias, marcas, clientes } = FirebaseRealtimeListener();

  const handleCategoriaChange = (e) => {
    const categoriaId = e.target.value;
    setSelectedCategoriaId(categoriaId);

    const categoriaSeleccionada = categorias.find(
      (categoria) => categoria.id === categoriaId
    );

    if (categoriaSeleccionada && categoriaSeleccionada.subCategorias) {
      setSubCategorias(categoriaSeleccionada.subCategorias);
    } else {
      setSubCategorias([]);
    }

    setSelectedSubCategoria("");
  };

  const handleMarcaChange = (e) => {
    const marcaId = e.target.value;
    setSelectedMarcaId(marcaId);

    const marcaSeleccionada = marcas.find((marca) => marca.id === marcaId);

    if (marcaSeleccionada && marcaSeleccionada.modelos) {
      setModelos(marcaSeleccionada.modelos);
    } else {
      setModelos([]);
    }

    setSelectedModelo("");
  };

  // Función para incrementar el stock
  const increaseStock = () => {
    setStock((prevStock) => prevStock + 1);
  };

  // Función para decrementar el stock
  const decreaseStock = () => {
    setStock((prevStock) => (prevStock > 1 ? prevStock - 1 : prevStock));
  };

  // Función para manejar la selección de imágenes
  const handleImageSelect = (e) => {
    const files = Array.from(e.target.files);
    const newPreviewUrls = files.map((file) => URL.createObjectURL(file));
    setPreviewUrls((prev) => [...prev, ...newPreviewUrls]);
    setImagenes((prev) => [...prev, ...files]);
  };

  const handleAddProduct = async (e) => {
    e.preventDefault();

    if (imagenes.length === 0) {
      setMessage("Debes agregar al menos una imagen.");
      return;
    }

    try {
      const newDocRef = doc(collection(db, "inventory"));
      const uploadedUrls = await handleImageUpload(newDocRef.id);

      await setDoc(newDocRef, {
        titulo,
        marcaId: selectedMarcaId,
        modelo: selectedModelo,
        numInventario,
        imagenes: uploadedUrls,
        categoriaId: selectedCategoriaId,
        subCategoria: selectedSubCategoria,
        clienteId: selectedCliente,
        stock, // Guardar el stock en la base de datos
      });

      setMessage("Producto agregado con éxito!");
      // Reiniciar los campos del formulario
      setTitulo("");
      setSelectedMarcaId("");
      setSelectedModelo("");
      setNumInventario("");
      setImagenes([]);
      setPreviewUrls([]);
      setSelectedCategoriaId("");
      setSelectedSubCategoria("");
      setSelectedCliente("");
      setStock(1); // Reiniciamos el stock a 1 después de agregar el producto
    } catch (error) {
      console.error("Error al agregar el producto:", error);
      setMessage("Error al agregar el producto. Intenta nuevamente.");
    }
  };

  const handleImageUpload = async (docId) => {
    if (imagenes.length === 0) return [];

    setUploading(true);
    const uploadedUrls = [];

    for (let i = 0; i < imagenes.length; i++) {
      const file = imagenes[i];
      const storageRef = ref(storage, `inventory/${docId}/${file.name}`);
      const uploadTask = uploadBytesResumable(storageRef, file);

      try {
        await new Promise((resolve, reject) => {
          uploadTask.on(
            "state_changed",
            (snapshot) => {
              const progress =
                (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
              console.log(`Subiendo imagen ${i + 1}: ${progress}% hecho`);
            },
            (error) => {
              console.error("Error al subir la imagen:", error);
              reject(error);
            },
            async () => {
              const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
              uploadedUrls.push(downloadURL);
              resolve();
            }
          );
        });
      } catch (error) {
        setUploading(false);
        setMessage("Error al subir las imágenes. Intenta nuevamente.");
        return [];
      }
    }

    setUploading(false);
    setMessage("Imágenes subidas con éxito!");
    return uploadedUrls;
  };

  return (
    <form onSubmit={handleAddProduct}>
      <TextField
        fullWidth
        label="Título del Producto"
        variant="outlined"
        value={titulo}
        onChange={(e) => setTitulo(e.target.value)}
        sx={{ marginBottom: 2 }}
        required
      />
      <FormControl fullWidth sx={{ marginBottom: 2 }}>
        <InputLabel id="marca-label">Marca</InputLabel>
        <Select
          labelId="marca-label"
          value={selectedMarcaId}
          label="Marca"
          onChange={handleMarcaChange}
          required
        >
          {marcas.map((marca) => (
            <MenuItem key={marca.id} value={marca.id}>
              {marca.nombre}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {modelos.length > 0 && (
        <FormControl fullWidth sx={{ marginBottom: 2 }}>
          <InputLabel id="modelo-label">Modelo</InputLabel>
          <Select
            labelId="modelo-label"
            value={selectedModelo}
            label="Modelo"
            onChange={(e) => setSelectedModelo(e.target.value)}
          >
            {modelos.map((modelo, index) => (
              <MenuItem key={index} value={modelo}>
                {modelo}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
      <TextField
        fullWidth
        label="Número de Inventario"
        variant="outlined"
        value={numInventario}
        onChange={(e) => setNumInventario(e.target.value)}
        sx={{ marginBottom: 2 }}
        required
      />
      <FormControl fullWidth sx={{ marginBottom: 2 }}>
        <InputLabel id="categoria-label">Categoría</InputLabel>
        <Select
          labelId="categoria-label"
          value={selectedCategoriaId}
          label="Categoría"
          onChange={handleCategoriaChange}
          required
        >
          {categorias.map((categoria) => (
            <MenuItem key={categoria.id} value={categoria.id}>
              {categoria.nombre}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {subCategorias.length > 0 && (
        <FormControl fullWidth sx={{ marginBottom: 2 }}>
          <InputLabel id="subcategoria-label">Subcategoría</InputLabel>
          <Select
            labelId="subcategoria-label"
            value={selectedSubCategoria}
            label="Subcategoría"
            onChange={(e) => setSelectedSubCategoria(e.target.value)}
          >
            {subCategorias.map((subCategoria, index) => (
              <MenuItem key={index} value={subCategoria}>
                {subCategoria}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
      <FormControl fullWidth sx={{ marginBottom: 2 }}>
        <InputLabel id="cliente-label">Cliente (Opcional)</InputLabel>
        <Select
          labelId="cliente-label"
          value={selectedCliente}
          label="Cliente (Opcional)"
          onChange={(e) => setSelectedCliente(e.target.value)}
        >
          {clientes.map((cliente) => (
            <MenuItem key={cliente.id} value={cliente.id}>
              {cliente.Nombre}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {/* Campo de stock */}
      <TextField
        fullWidth
        label="Stock"
        variant="outlined"
        value={stock}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <IconButton onClick={decreaseStock}>
                <RemoveIcon />
              </IconButton>
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={increaseStock}>
                <AddIcon />
              </IconButton>
            </InputAdornment>
          ),
          readOnly: true,
        }}
        sx={{ marginBottom: 2 }}
        inputProps={{ min: 1, type: "number" }} // Forzamos a que sea un número mínimo de 1
      />
      {/* Botón para seleccionar imágenes */}
      <input
        accept="image/*"
        style={{ display: "none" }}
        id="upload-button"
        type="file"
        multiple
        onChange={handleImageSelect}
      />
      <label htmlFor="upload-button">
        <Button
          variant="contained"
          color="primary"
          component="span"
          startIcon={<PhotoCamera />}
          sx={{ marginBottom: 2 }}
        >
          Seleccionar Imágenes
        </Button>
      </label>
      <Button
        type="submit"
        variant="contained"
        color="primary"
        disabled={uploading}
      >
        {uploading ? (
          <>
            Subiendo... <CircularProgress size={24} sx={{ ml: 2 }} />
          </>
        ) : (
          "Agregar Producto"
        )}
      </Button>
    </form>
  );
};

export default FormProducto;
