import React from "react";
import { Box, Typography, Container, Paper } from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PhoneIcon from "@mui/icons-material/Phone";

const Location = () => {
  return (
    <Container maxWidth="lg" sx={{ py: 8, backgroundColor: "#f5f5f5" }}>
      <Typography variant="h2" align="center" gutterBottom>
        Ubicación
      </Typography>

      <Box
        sx={{
          mb: 4,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography
          variant="h6"
          color="textSecondary"
          sx={{ display: "flex", alignItems: "center", mb: 1 }}
        >
          <LocationOnIcon sx={{ mr: 1 }} />
          Yañez #27 esq. Nuevo Leon, Col. San Benito, CP 83190
        </Typography>
        <Typography
          variant="h6"
          color="textSecondary"
          sx={{ display: "flex", alignItems: "center", mb: 1 }}
        >
          <PhoneIcon sx={{ mr: 1 }} />
          (662) 217 2654
        </Typography>
      </Box>

      <Paper
        sx={{
          height: 400,
          width: "100%",
          overflow: "hidden",
          borderRadius: 1,
          boxShadow: 3,
        }}
      >
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3486.1095877457483!2d-110.96694108490721!3d29.10611228223674!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x86ce85ecedbb2f95%3A0xafcce07456750d6c!2sRadiosistemas!5e0!3m2!1sen!2sus!4v1628703995965!5m2!1sen!2sus"
          width="100%"
          height="100%"
          style={{ border: 0 }}
          allowFullScreen=""
          loading="lazy"
          title="Ubicación de Radiosistemas"
        />
      </Paper>
    </Container>
  );
};

export default Location;
