import React from "react";
import { Box, Typography } from "@mui/material";
import { styled } from "@mui/system";
import { createTheme, ThemeProvider } from "@mui/material/styles";

// Crear un tema con una tipografía moderna
const theme = createTheme({
  typography: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    h3: {
      fontSize: "2rem",
      fontWeight: 700,
      "@media (min-width:600px)": {
        fontSize: "2.5rem", // Aumenta el tamaño del texto en pantallas más grandes
      },
    },
    body1: {
      fontSize: "1rem",
      lineHeight: 1.6,
      "@media (min-width:600px)": {
        fontSize: "1.2rem", // Aumenta el tamaño del cuerpo en pantallas más grandes
      },
    },
  },
});

const AboutContainer = styled(Box)`
  padding: 2rem 1rem;
  background-color: white;
  text-align: center;
  max-width: 1200px;
  margin: 0 auto;
  box-sizing: border-box;
`;

const RoundedImage = styled("img")`
  width: 100%;
  max-width: 600px;
  min-width: 300px;
  height: auto;
  border-radius: 16px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 2rem;
`;

const About = () => {
  return (
    <ThemeProvider theme={theme}>
      <AboutContainer id="about">
        <RoundedImage
          src="https://firebasestorage.googleapis.com/v0/b/radiosistemas-a46cd.appspot.com/o/patrullas-electricas-de-la-policia-de-hermosillo-de405736-focus-0-0-1300-866-900w.jpg?alt=media&token=fe2570ac-9c43-44fb-9b33-e437b351b149"
          alt="Patrullas eléctricas de la policía de Hermosillo"
        />
        <Typography variant="h3" gutterBottom>
          Tecnología de punta
        </Typography>
        <Typography variant="body1" paragraph>
          Contamos con acceso a las mejores tecnologías disponibles en el
          mercado actual. Gracias a ello, podemos equipar nuestras unidades con
          equipos de vanguardia para garantizar un rendimiento óptimo. Nuestro
          compromiso con la excelencia nos impulsa a utilizar lo último en
          tecnología para brindar un servicio de calidad superior a nuestros
          clientes.
        </Typography>
      </AboutContainer>
    </ThemeProvider>
  );
};

export default About;
