import { useEffect, useState } from "react";
import { collection, onSnapshot } from "firebase/firestore";
import { db } from "../../../firebase";

const FirebaseRealtimeListener = () => {
  const [categorias, setCategorias] = useState([]);
  const [marcas, setMarcas] = useState([]);
  const [clientes, setClientes] = useState([]);

  useEffect(() => {
    const unsubscribeCategorias = onSnapshot(
      collection(db, "categorias"),
      (snapshot) => {
        setCategorias(
          snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
        );
      }
    );

    const unsubscribeMarcas = onSnapshot(
      collection(db, "marcas"),
      (snapshot) => {
        setMarcas(snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
      }
    );

    const unsubscribeClientes = onSnapshot(
      collection(db, "clientes"),
      (snapshot) => {
        setClientes(
          snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
        );
      }
    );

    return () => {
      unsubscribeCategorias();
      unsubscribeMarcas();
      unsubscribeClientes();
    };
  }, []);

  return { categorias, marcas, clientes };
};

export default FirebaseRealtimeListener;
