import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  CircularProgress,
  Box,
  Typography,
  Avatar,
} from "@mui/material";
import { collection, getDocs, addDoc } from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { db, storage } from "../../../firebase";

const NuevoOficial = ({ open, handleClose, onOfficialAdded }) => {
  const [nombre, setNombre] = useState("");
  const [zona, setZona] = useState("");
  const [tipo, setTipo] = useState("preventiva");
  const [celular, setCelular] = useState("");
  const [foto, setFoto] = useState(null);
  const [fotoPreview, setFotoPreview] = useState(null);
  const [zonas, setZonas] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchZonas = async () => {
      const querySnapshot = await getDocs(collection(db, "unidades"));
      const zonasSet = new Set(
        querySnapshot.docs.map((doc) => doc.data().Zona)
      );
      setZonas(Array.from(zonasSet));
    };

    fetchZonas();
  }, []);

  const handleUploadPhoto = async () => {
    if (!foto) return null;
    setLoading(true);
    const storageRef = ref(storage, `oficiales/${foto.name}`);
    const uploadTask = await uploadBytes(storageRef, foto);
    const downloadURL = await getDownloadURL(uploadTask.ref);
    return downloadURL;
  };

  const handleSubmit = async () => {
    const fotoURL = await handleUploadPhoto();
    const docRef = await addDoc(collection(db, "oficiales"), {
      nombre,
      zona,
      tipo,
      celular,
      fotoURL,
    });
    onOfficialAdded({ id: docRef.id, nombre, zona, tipo, celular, fotoURL });
    setLoading(false);
    handleClose();
  };

  const handleFotoChange = (e) => {
    const file = e.target.files[0];
    setFoto(file);
    setFotoPreview(URL.createObjectURL(file));
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle sx={{ color: "#00ffff", textAlign: "center" }}>
        Añadir Nuevo Oficial
      </DialogTitle>
      <DialogContent sx={{ backgroundColor: "#1a1a1a", color: "#00ffff" }}>
        <TextField
          label="Nombre"
          fullWidth
          margin="dense"
          value={nombre}
          onChange={(e) => setNombre(e.target.value)}
          sx={{
            input: { color: "#ffffff" },
            label: { color: "#00ffff" },
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "#00ffff",
              },
              "&:hover fieldset": {
                borderColor: "#00ffff",
              },
            },
            mb: 2,
          }}
        />
        <FormControl fullWidth margin="dense" sx={{ mb: 2 }}>
          <InputLabel sx={{ color: "#00ffff" }}>Zona</InputLabel>
          <Select
            value={zona}
            onChange={(e) => setZona(e.target.value)}
            sx={{
              color: "#ffffff",
              ".MuiOutlinedInput-notchedOutline": {
                borderColor: "#00ffff",
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: "#00ffff",
              },
              ".MuiSvgIcon-root ": {
                fill: "#00ffff !important",
              },
            }}
          >
            {zonas.map((zona) => (
              <MenuItem key={zona} value={zona}>
                {zona}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl fullWidth margin="dense" sx={{ mb: 2 }}>
          <InputLabel sx={{ color: "#00ffff" }}>Tipo</InputLabel>
          <Select
            value={tipo}
            onChange={(e) => setTipo(e.target.value)}
            sx={{
              color: "#ffffff",
              ".MuiOutlinedInput-notchedOutline": {
                borderColor: "#00ffff",
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: "#00ffff",
              },
              ".MuiSvgIcon-root ": {
                fill: "#00ffff !important",
              },
            }}
          >
            <MenuItem value="preventiva">Preventiva</MenuItem>
            <MenuItem value="transito">Tránsito</MenuItem>
          </Select>
        </FormControl>
        <TextField
          label="Celular"
          fullWidth
          margin="dense"
          value={celular}
          onChange={(e) => setCelular(e.target.value)}
          sx={{
            input: { color: "#ffffff" },
            label: { color: "#00ffff" },
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "#00ffff",
              },
              "&:hover fieldset": {
                borderColor: "#00ffff",
              },
            },
            mb: 2,
          }}
        />
        <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
          <Button
            variant="contained"
            component="label"
            sx={{
              backgroundColor: "#00C49F",
              color: "#000000",
              "&:hover": {
                backgroundColor: "#009688",
              },
              mr: 2,
            }}
          >
            Subir Foto
            <input type="file" hidden onChange={handleFotoChange} />
          </Button>
          {fotoPreview && (
            <Avatar
              src={fotoPreview}
              alt="Foto del Oficial"
              sx={{ width: 56, height: 56, border: "2px solid #00ffff" }}
            />
          )}
        </Box>
        <Button
          fullWidth
          onClick={handleSubmit}
          color="primary"
          disabled={loading}
          sx={{
            backgroundColor: "#00C49F",
            color: "#000000",
            "&:hover": {
              backgroundColor: "#009688",
            },
          }}
        >
          {loading ? <CircularProgress size={24} /> : "Crear Oficial"}
        </Button>
      </DialogContent>
    </Dialog>
  );
};

export default NuevoOficial;
