import React from "react";
import ReactPlayer from "react-player";

const HikvisionPlayer = () => {
  const rtspUrl =
    "rtsp://admin:Radio101098@192.168.100.151:554/Streaming/Channels/101";

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <ReactPlayer
        url={rtspUrl}
        playing={true}
        controls={true}
        width="100%"
        height="100%"
        config={{
          file: {
            forceHLS: true,
            attributes: {
              crossOrigin: "anonymous",
            },
          },
        }}
      />
    </div>
  );
};

export default HikvisionPlayer;
