import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Container,
  Grid,
  Paper,
  Typography,
  Box,
  CircularProgress,
} from "@mui/material";
import { db } from "../../firebase";
import { doc, getDoc } from "firebase/firestore";
import UnidadInspections from "./UnidadInspections";

const UnidadDetails = () => {
  const { uid } = useParams();
  const [unidadData, setUnidadData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchUnidad = async () => {
      try {
        const docRef = doc(db, "unidades", uid);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          setUnidadData(docSnap.data());
        } else {
          setError("No se encontró la unidad.");
        }
      } catch (err) {
        setError("Error al recuperar los datos de la unidad.");
      } finally {
        setLoading(false);
      }
    };

    fetchUnidad();
  }, [uid]);

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
        bgcolor="#0d0d0d"
      >
        <CircularProgress color="secondary" />
      </Box>
    );
  }

  if (error) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
        bgcolor="#0d0d0d"
      >
        <Typography color="error" align="center" variant="h6">
          {error}
        </Typography>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        p: { xs: 1, sm: 2, md: 4 },
        bgcolor: "#0d0d0d",
        minHeight: "100vh",
      }}
    >
      <Paper
        sx={{
          p: { xs: 2, sm: 4 },
          borderRadius: 2,
          boxShadow: "0 8px 24px rgba(0, 255, 255, 0.3)",
          backgroundColor: "#1a1a1a",
          color: "#ffffff",
          mb: 4,
        }}
      >
        <Typography
          variant="h4"
          align="center"
          gutterBottom
          sx={{
            color: "#00ffff",
            borderBottom: "3px solid #00ffff",
            paddingBottom: "12px",
            fontWeight: "bold",
            fontSize: { xs: "1.5rem", sm: "2rem", md: "2.5rem" },
          }}
        >
          Unidad: {unidadData.Unidad}
        </Typography>
        <Grid container spacing={2} sx={{ mt: 2 }}>
          <Grid item xs={12}>
            <Typography
              variant="h6"
              gutterBottom
              sx={{
                color: "#00ffff",
                marginBottom: 2,
                fontSize: { xs: "1.2rem", sm: "1.5rem" },
              }}
            >
              <strong>Zona:</strong> {unidadData.Zona}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            {[
              { label: "Bocina", value: unidadData.Bocina },
              { label: "Cobrar", value: unidadData.Cobrar },
              { label: "DVR", value: unidadData.DVR },
              { label: "ESN", value: unidadData.ESN },
              { label: "Identificador", value: unidadData.Identificador },
              { label: "Radio", value: unidadData.Radio },
              { label: "Requerida", value: unidadData.Requerida },
              { label: "Rotulación", value: unidadData.Rotulacion },
              { label: "Torreta", value: unidadData.Torreta },
            ].map((item, index) => (
              <Typography
                key={index}
                variant="body1"
                gutterBottom
                sx={{
                  color: "#e0e0e0",
                  backgroundColor: "#2b2b2b",
                  padding: 1,
                  borderRadius: 2,
                  marginBottom: 1,
                  fontSize: { xs: "0.875rem", sm: "1rem" },
                }}
              >
                <strong>{item.label}:</strong> {item.value || "N/A"}
              </Typography>
            ))}
          </Grid>
        </Grid>
        <UnidadInspections unidad={unidadData.Unidad} />
      </Paper>
    </Box>
  );
};

export default UnidadDetails;
