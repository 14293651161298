import React from "react";
import { Grid, Box } from "@mui/material";

import Hero from "./Home/Hero";
import Features from "./Home/Features";
import About from "./Home/About";
import Contact from "./Home/Contact";
import Footer from "./Footer";
import PartnerSlider from "./Home/Partners";
import Chat from "./Chat";
import LocationRS from "./Home/LocationRS";
import MyMapComponent from "./Home/Coverage";

const Home = () => {
  return (
    <Box className="home">
      <Hero />
      <PartnerSlider />
      <Features />
      <About />
      <MyMapComponent />

      {/* Sección para Contact y LocationRS en el mismo renglón */}
      <Grid container spacing={4} sx={{ marginTop: "4rem" }}>
        <Grid item xs={12} md={6}>
          <Contact />
        </Grid>
        <Grid item xs={12} md={6}>
          <LocationRS />
        </Grid>
      </Grid>

      <Chat />
    </Box>
  );
};

export default Home;
