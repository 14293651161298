import React, { useState, useEffect } from "react";
import { collection, onSnapshot, doc } from "firebase/firestore";
import { db } from "../../firebase";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  ResponsiveContainer,
} from "recharts";
import { Box, Typography } from "@mui/material";
import { blueGrey } from "@mui/material/colors";

// Definir los límites de voltaje para la batería
const minVoltage = 10.5;
const maxVoltage = 13.8;

const BatteryChart = ({ uid, onVoltageUpdate }) => {
  const [voltageHistory, setVoltageHistory] = useState([]);
  const [latestVoltage, setLatestVoltage] = useState(null);

  useEffect(() => {
    // Obtener el documento Enlace en Firestore
    const enlaceRef = doc(db, "Enlaces", uid);

    // Escuchar en tiempo real la colección 'stats'
    const statsRef = collection(enlaceRef, "stats");
    const unsubscribe = onSnapshot(statsRef, (snapshot) => {
      const statsList = snapshot.docs.map((doc) => {
        const data = doc.data();
        const timestamp = new Date(data.timestamp.seconds * 1000);

        // Actualizar el voltaje más reciente
        setLatestVoltage(data.voltage);

        // Devolver datos con timestamps organizados
        return {
          id: doc.id,
          timestamp: timestamp,
          voltage: data.voltage,
        };
      });

      // Ordenar los datos por timestamp de más antiguo a más reciente
      statsList.sort((a, b) => a.timestamp - b.timestamp);

      // Actualizar el historial de voltaje
      setVoltageHistory(
        statsList.map((stat) => ({
          time: stat.timestamp.toLocaleString(),
          voltage: stat.voltage,
        }))
      );

      // Enviar el voltaje más reciente al componente padre
      onVoltageUpdate(statsList[statsList.length - 1]?.voltage || 0);
    });

    // Limpiar la suscripción cuando el componente se desmonta
    return () => unsubscribe();
  }, [uid, onVoltageUpdate]);

  return (
    <Box mt={4} height="400px">
      <Typography variant="h5" gutterBottom>
        Voltaje con el paso del tiempo
      </Typography>
      <ResponsiveContainer width="100%" height="100%">
        <LineChart data={voltageHistory}>
          <CartesianGrid stroke={blueGrey[100]} strokeDasharray="3 3" />
          <XAxis dataKey="time" />
          <YAxis domain={[minVoltage, maxVoltage]} />
          <Tooltip />
          <Line type="monotone" dataKey="voltage" stroke="#1976D2" />
        </LineChart>
      </ResponsiveContainer>
    </Box>
  );
};

export default BatteryChart;
