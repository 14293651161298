import React, { useState } from "react";
import { db, storage } from "../firebase"; // Asegúrate de importar Firestore y Storage desde firebase.js
import {
  collection,
  query,
  where,
  getDocs,
  updateDoc,
  doc,
} from "firebase/firestore";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import {
  Button,
  Typography,
  Card,
  CardContent,
  CircularProgress,
  LinearProgress,
  Grid,
} from "@mui/material";
import PhotoCamera from "@mui/icons-material/PhotoCamera";

const UploadInspectionPhotos = () => {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [message, setMessage] = useState("");
  const [uploading, setUploading] = useState(false);
  const [currentFileIndex, setCurrentFileIndex] = useState(0);
  const [totalProgress, setTotalProgress] = useState(0);

  const handleFileChange = (e) => {
    setSelectedFiles(e.target.files);
  };

  const handleUpload = async () => {
    if (selectedFiles.length === 0) {
      setMessage("Por favor, selecciona fotos para subir.");
      return;
    }

    setUploading(true);
    setMessage("");
    setCurrentFileIndex(0);
    setTotalProgress(0);

    for (let i = 0; i < selectedFiles.length; i++) {
      const file = selectedFiles[i];
      const fileName = file.name;
      const [inspectionID, position, uniqueID] = fileName.split(".");

      try {
        // Buscar el documento que contiene el `Inspection ID`
        const inspectionsRef = collection(db, "inspecciones");
        const q = query(
          inspectionsRef,
          where("Inspection ID", "==", inspectionID)
        );
        const querySnapshot = await getDocs(q);

        if (querySnapshot.empty) {
          setMessage(
            `No se encontró la inspección con el ID especificado: ${inspectionID}`
          );
          continue; // Pasar a la siguiente foto
        }

        // Asumimos que solo hay un documento que coincide con el `Inspection ID`
        const inspectionDoc = querySnapshot.docs[0];
        const docRef = doc(db, "inspecciones", inspectionDoc.id);

        // Subir la imagen a Firebase Storage
        const storageRef = ref(
          storage,
          `inspecciones/${inspectionID}/${fileName}`
        );
        const uploadTask = uploadBytesResumable(storageRef, file);

        uploadTask.on(
          "state_changed",
          (snapshot) => {
            const progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log(
              `Subiendo imagen ${i + 1} de ${
                selectedFiles.length
              }: ${progress}% hecho`
            );

            // Actualizar el progreso total
            const overallProgress =
              ((i + progress / 100) / selectedFiles.length) * 100;
            setTotalProgress(overallProgress);
          },
          (error) => {
            console.error("Error al subir la imagen:", error);
            setMessage("Error al subir la imagen. Intenta nuevamente.");
          },
          async () => {
            // Obtener la URL de descarga de la imagen
            const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);

            // Actualizar el documento de inspección con la URL de la imagen
            const updateData = {
              [`fotos.${position}`]: downloadURL,
            };
            await updateDoc(docRef, updateData);

            setMessage(
              `Foto ${
                i + 1
              } subida y guardada en la inspección ${inspectionID} con éxito!`
            );
            setCurrentFileIndex(i + 1);
          }
        );
      } catch (error) {
        console.error("Error al subir la foto:", error);
        setMessage("Error al subir la foto. Intenta nuevamente.");
      }
    }

    setUploading(false);
  };

  return (
    <div style={{ padding: 20 }}>
      <Card
        sx={{
          p: 4,
          borderRadius: 4,
          boxShadow: "0 8px 24px rgba(0,0,0,0.12)",
          mb: 4,
        }}
      >
        <CardContent>
          <Typography variant="h5" component="div" gutterBottom>
            Subir Fotos de Inspecciones
          </Typography>
          <input
            accept="image/*"
            style={{ display: "none" }}
            id="upload-photo"
            type="file"
            multiple
            onChange={handleFileChange}
          />
          <label htmlFor="upload-photo">
            <Button
              variant="contained"
              color="primary"
              component="span"
              startIcon={<PhotoCamera />}
              sx={{ marginBottom: 2 }}
            >
              Seleccionar Fotos
            </Button>
          </label>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleUpload}
            disabled={uploading || selectedFiles.length === 0}
            sx={{ marginBottom: 2 }}
          >
            {uploading ? (
              <>
                Subiendo... <CircularProgress size={24} sx={{ ml: 2 }} />
              </>
            ) : (
              "Subir Fotos"
            )}
          </Button>
          {uploading && (
            <div style={{ marginTop: 20 }}>
              <Typography variant="body1" sx={{ mb: 2 }}>
                Subiendo {currentFileIndex} de {selectedFiles.length} fotos...
              </Typography>
              <LinearProgress variant="determinate" value={totalProgress} />
            </div>
          )}
          {message && (
            <Typography variant="body1" color="text.secondary" sx={{ mt: 2 }}>
              {message}
            </Typography>
          )}
        </CardContent>
      </Card>
    </div>
  );
};

export default UploadInspectionPhotos;
