import React, { useEffect, useState } from "react";
import { useUserAuth } from "../../context/userAuthContext";
import { Link, useNavigate } from "react-router-dom";
import {
  Typography,
  Button,
  Grid,
  Box,
  Avatar,
  Container,
  useTheme,
  useMediaQuery,
  Paper,
} from "@mui/material";
import {
  Assignment,
  AttachMoney,
  Support,
  ExitToApp,
  Dashboard,
  Person,
  Security, // Importa el ícono de escudo
} from "@mui/icons-material";
import { db } from "../../firebase"; // Importa Firestore desde firebase.js
import { doc, getDoc, setDoc } from "firebase/firestore";

const UserDashboard = () => {
  const { user, logOut } = useUserAuth();
  const [displayName, setDisplayName] = useState("");
  const [photoURL, setPhotoURL] = useState("");
  const [isAdmin, setIsAdmin] = useState(false); // Estado para verificar si el usuario es admin
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    const createUserDocument = async (user) => {
      if (user) {
        const userDocRef = doc(db, "Users", user.uid);
        const userDoc = await getDoc(userDocRef);

        if (!userDoc.exists()) {
          await setDoc(userDocRef, {
            nombre: user.displayName || "Usuario",
            email: user.email,
            roles: [], // Asegúrate de inicializar los roles si aún no existen
          });
        } else {
          const userData = userDoc.data();
          setIsAdmin(userData.roles && userData.roles.includes("admin")); // Verificar si el usuario es admin
        }
      }
    };

    if (user) {
      setDisplayName(user.displayName || "Usuario");
      setPhotoURL(user.photoURL || "https://via.placeholder.com/150");
      createUserDocument(user);
    }
  }, [user]);

  const handleLogout = async () => {
    try {
      await logOut();
      navigate("/");
    } catch (error) {
      console.error("Error logging out:", error);
    }
  };

  const menuItems = [
    { icon: <Dashboard />, text: "Dashboard", path: "/dashboard" },
    { icon: <Assignment />, text: "Proyectos", path: "/projectdashboard" },
    { icon: <AttachMoney />, text: "Cotizaciones", path: "/cotizaciones" },
    { icon: <Support />, text: "Soporte", path: "/soporte" },
    { icon: <Person />, text: "Perfil", path: "/perfil" },
  ];

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Paper elevation={3} sx={{ p: 3, borderRadius: 2 }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            mb: 4,
          }}
        >
          <Avatar
            alt={displayName}
            src={photoURL}
            sx={{ width: 120, height: 120, mb: 2 }}
          />
          <Typography variant="h4" gutterBottom>
            Bienvenido, {displayName}
          </Typography>
        </Box>

        <Grid container spacing={3} justifyContent="center">
          {menuItems.map((item, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Button
                component={Link}
                to={item.path}
                variant="contained"
                startIcon={item.icon}
                fullWidth
                sx={{
                  py: 3,
                  px: 2,
                  fontSize: isMobile ? "1rem" : "1.2rem",
                  textTransform: "none",
                  borderRadius: 2,
                  boxShadow: 3,
                  "&:hover": {
                    transform: "translateY(-3px)",
                    boxShadow: 5,
                  },
                  transition: "all 0.3s",
                }}
              >
                {item.text}
              </Button>
            </Grid>
          ))}

          {/* Agregar el botón de Admin Dashboard si el usuario es admin */}
          {isAdmin && (
            <Grid item xs={12} sm={6} md={4}>
              <Button
                component={Link}
                to="/admin"
                variant="contained"
                startIcon={<Security />}
                fullWidth
                sx={{
                  py: 3,
                  px: 2,
                  fontSize: isMobile ? "1rem" : "1.2rem",
                  textTransform: "none",
                  borderRadius: 2,
                  boxShadow: 3,
                  "&:hover": {
                    transform: "translateY(-3px)",
                    boxShadow: 5,
                  },
                  transition: "all 0.3s",
                  backgroundColor: "darkred",
                  color: "white",
                }}
              >
                Admin Dashboard
              </Button>
            </Grid>
          )}

          <Grid item xs={12} sm={6} md={4}>
            <Button
              variant="outlined"
              startIcon={<ExitToApp />}
              fullWidth
              onClick={handleLogout}
              sx={{
                py: 3,
                px: 2,
                fontSize: isMobile ? "1rem" : "1.2rem",
                textTransform: "none",
                borderRadius: 2,
                boxShadow: 3,
                "&:hover": {
                  transform: "translateY(-3px)",
                  boxShadow: 5,
                },
                transition: "all 0.3s",
              }}
            >
              Cerrar Sesión
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
};

export default UserDashboard;
