import React from "react";
import { Button, Dialog, DialogContent } from "@mui/material";
import QrScanner from "react-qr-scanner";

const EscanerQR = ({ showQrScanner, setShowQrScanner, setMessage }) => {
  const handleScan = (data) => {
    if (data) {
      setMessage(`Código QR escaneado: ${data.text}`);
      setShowQrScanner(false);
    }
  };

  const handleError = (err) => {
    console.error("Error al escanear el código QR:", err);
    setMessage("Error al escanear el código QR. Intenta nuevamente.");
  };

  return (
    <>
      <Button
        variant="outlined"
        color="secondary"
        onClick={() => setShowQrScanner(!showQrScanner)}
        sx={{ marginBottom: 2 }}
      >
        {showQrScanner ? "Cerrar Escáner QR" : "Escanear Código QR"}
      </Button>
      <Dialog
        open={showQrScanner}
        onClose={() => setShowQrScanner(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogContent sx={{ display: "flex", justifyContent: "center" }}>
          {showQrScanner && (
            <QrScanner
              delay={300}
              style={{ width: "100%", height: "100%" }}
              onError={handleError}
              onScan={handleScan}
              constraints={{
                video: { facingMode: { exact: "environment" } },
              }}
            />
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default EscanerQR;
