import React, { useState, useEffect } from "react";
import {
  Container,
  Typography,
  Paper,
  TextField,
  Button,
  Grid,
  InputLabel,
  IconButton,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useUserAuth } from "../context/userAuthContext";
import { storage } from "../firebase";
import { ref, uploadBytesResumable, getDownloadURL } from "@firebase/storage";
import { getFirestore, collection, addDoc } from "firebase/firestore";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";

const mapContainerStyle = {
  width: "100%",
  height: "300px",
};

const AddStore = () => {
  const { user } = useUserAuth();
  const navigate = useNavigate();
  const [userUid, setUserUid] = useState("");
  useEffect(() => {
    if (user) {
      setUserUid(user.uid);
    }
  }, [user]);

  const [storeData, setStoreData] = useState({
    ownerName: userUid || "",
    storeName: "",
    address: "",
    phoneNumber: "",
    hours: "",
    reviews: [],
    latitude: "",
    longitude: "",
    photo: null,
    description: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setStoreData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleMapClick = (e) => {
    const { latLng } = e;
    setStoreData((prevData) => ({
      ...prevData,
      latitude: latLng.lat().toString(),
      longitude: latLng.lng().toString(),
    }));
  };

  const handlePhotoChange = (e) => {
    const file = e.target.files[0];
    setStoreData((prevData) => ({ ...prevData, photo: file }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const firestore = getFirestore();

      const storageRef = ref(
        storage,
        `food_store_images/${userUid}/${storeData.photo.name}`,
      );
      await uploadBytesResumable(storageRef, storeData.photo);
      const photoUrl = await getDownloadURL(storageRef);

      const storesCollectionRef = collection(firestore, "stores");
      await addDoc(storesCollectionRef, {
        ownerName: storeData.ownerName,
        storeName: storeData.storeName,
        address: storeData.address,
        phoneNumber: storeData.phoneNumber,
        hours: storeData.hours,
        reviews: storeData.reviews,
        latitude: storeData.latitude,
        longitude: storeData.longitude,
        photo: photoUrl,
        description: storeData.description,
      });

      alert("Food store added successfully!");
      navigate("/");
    } catch (error) {
      console.error("Error adding food store:", error.message);
    }
  };

  return (
    <Container maxWidth="sm">
      <Paper elevation={3} sx={{ padding: 3, marginTop: 2 }}>
        <Typography variant="h4" gutterBottom>
          Add Food Store
        </Typography>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Store Name"
                type="text"
                name="storeName"
                value={storeData.storeName}
                onChange={handleChange}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Address"
                type="text"
                name="address"
                value={storeData.address}
                onChange={handleChange}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Phone Number"
                type="text"
                name="phoneNumber"
                value={storeData.phoneNumber}
                onChange={handleChange}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Hours"
                type="text"
                name="hours"
                value={storeData.hours}
                onChange={handleChange}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <InputLabel htmlFor="photo">Store Photo:</InputLabel>
              <input
                type="file"
                accept="image/*"
                id="photo"
                style={{ display: "none" }}
                onChange={handlePhotoChange}
              />
              <label htmlFor="photo">
                <IconButton
                  color="primary"
                  aria-label="upload picture"
                  component="span"
                >
                  <PhotoCameraIcon />
                </IconButton>
              </label>
              {storeData.photo && (
                <img
                  src={URL.createObjectURL(storeData.photo)}
                  alt="Store Preview"
                  style={{ marginTop: "10px", maxWidth: "100%" }}
                />
              )}
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Latitude"
                type="text"
                name="latitude"
                value={storeData.latitude}
                onChange={handleChange}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Longitude"
                type="text"
                name="longitude"
                value={storeData.longitude}
                onChange={handleChange}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Description"
                multiline
                rows={6}
                name="description"
                value={storeData.description}
                onChange={handleChange}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <LoadScript googleMapsApiKey="AIzaSyBSXE8zZ-SqMWdDz16hr00Djvkm3mMit28">
                <GoogleMap
                  mapContainerStyle={mapContainerStyle}
                  center={{ lat: 29.110262, lng: -110.940463 }}
                  zoom={15}
                  onClick={(e) => handleMapClick(e)}
                >
                  {storeData.latitude && storeData.longitude && (
                    <Marker
                      position={{
                        lat: parseFloat(storeData.latitude),
                        lng: parseFloat(storeData.longitude),
                      }}
                    />
                  )}
                </GoogleMap>
              </LoadScript>
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="contained"
                fullWidth
                color="primary"
                type="submit"
              >
                Add Store
              </Button>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </Container>
  );
};

export default AddStore;
