import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { db } from "../../firebase";
import { doc, getDoc } from "firebase/firestore";
import {
  Typography,
  Container,
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  Badge,
  CircularProgress,
} from "@mui/material";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import BatteryChargingFullIcon from "@mui/icons-material/BatteryChargingFull";
import { green, red } from "@mui/material/colors";
import BatteryChart from "./BatteryChart"; // Importamos el componente BatteryChart
import BatteryPrediction from "./BatteryPrediction"; // Importamos el componente BatteryPrediction

// Definir los límites de voltaje para la batería
const minVoltage = 10.5;
const maxVoltage = 13.8;

// CircularProgress con porcentaje de batería
const CircularProgressWithLabel = (props) => {
  return (
    <Box position="relative" display="inline-flex">
      <CircularProgress
        variant="determinate"
        {...props}
        style={{
          color: props.value > 20 ? "#00C853" : "#D32F2F",
          width: "140px",
          height: "140px",
        }}
      />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography variant="h5" component="div" color="textSecondary">
          {`${Math.round(props.value)}%`}
        </Typography>
      </Box>
    </Box>
  );
};

const EnlaceInfo = () => {
  const { uid } = useParams(); // Obtenemos el UID de la URL
  const [enlaceData, setEnlaceData] = useState(null);
  const [latestVoltage, setLatestVoltage] = useState(null); // Almacenamos el último voltaje en tiempo real
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false); // Para el diálogo de información de la batería
  const [isOnline, setIsOnline] = useState(false); // Estado online/offline
  let timerId = null; // Temporizador para definir estado offline

  useEffect(() => {
    const fetchEnlaceData = async () => {
      try {
        const enlaceRef = doc(db, "Enlaces", uid);
        const enlaceDoc = await getDoc(enlaceRef);

        if (enlaceDoc.exists()) {
          setEnlaceData(enlaceDoc.data());
        }
        setLoading(false);
      } catch (error) {
        console.error("Error fetching Enlace data:", error);
        setLoading(false);
      }
    };

    fetchEnlaceData();
  }, [uid]);

  // Esta función actualiza el estado de "Online" si recibimos un dato
  const updateOnlineStatus = () => {
    clearTimeout(timerId);
    setIsOnline(true);
    timerId = setTimeout(() => {
      setIsOnline(false); // Cambia a offline si no se recibe ningún dato después de 20 segundos
    }, 20000);
  };

  // Calcular el porcentaje de batería basado en el voltaje
  const calculateBatteryPercentage = (voltage) => {
    return ((voltage - minVoltage) / (maxVoltage - minVoltage)) * 100;
  };

  // Manejar la apertura/cierre del diálogo de información de la batería
  const handleClickOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // Si los datos están cargando, mostramos un spinner
  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Container>
      {/* Mostrar nombre y UniqueID */}
      {enlaceData ? (
        <Box mb={3} textAlign="center">
          <Typography variant="h3" gutterBottom>
            {enlaceData.name || "Enlace"}
          </Typography>
          <Typography variant="subtitle1" color="textSecondary">
            UniqueID: {uid}
          </Typography>
        </Box>
      ) : (
        <Typography variant="body1">
          No se encontraron datos para este enlace.
        </Typography>
      )}

      {/* Estado de la batería */}
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        mb={6}
      >
        {/* Estado Online/Offline */}
        <Badge
          badgeContent={isOnline ? "Online" : "Offline"}
          color={isOnline ? "success" : "error"}
          anchorOrigin={{ vertical: "top", horizontal: "left" }}
          style={{ marginBottom: "10px" }}
        >
          <FiberManualRecordIcon
            style={{ color: isOnline ? green[500] : red[500] }}
          />
        </Badge>

        <Typography variant="h5" gutterBottom>
          Estado de la batería
        </Typography>

        {/* Animación de carga si el voltaje es > 13.7 */}
        {latestVoltage > 13.7 && (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            mb={2}
          >
            <BatteryChargingFullIcon
              fontSize="large"
              style={{ color: "#00C853", marginRight: "10px" }}
            />
            <Typography
              variant="h6"
              style={{ animation: "blinking 1s infinite" }}
            >
              Cargando...
            </Typography>
          </Box>
        )}

        <CircularProgressWithLabel
          value={calculateBatteryPercentage(latestVoltage)}
        />
        <Typography variant="h6" style={{ marginTop: "10px" }}>
          Voltaje actual: {latestVoltage ? latestVoltage.toFixed(2) : "---"}V
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={handleClickOpen}
          style={{ marginTop: "20px" }}
        >
          Información de la batería
        </Button>
      </Box>

      {/* Dialogo de Información de la batería */}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Especificaciones de la batería</DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            <strong>Modelo:</strong> Leoch XP12-400FR
          </Typography>
          <Typography variant="body1">
            <strong>Voltaje Nominal:</strong> 12V
          </Typography>
          <Typography variant="body1">
            <strong>Capacidad Nominal:</strong> 96 Ah @ 1.75V/celda @ 25°C
          </Typography>
          <Typography variant="body1">
            <strong>Voltaje mínimo:</strong> 10.5V (Descargada)
          </Typography>
          <Typography variant="body1">
            <strong>Voltaje máximo:</strong> 13.8V (Cargada completamente)
          </Typography>
          <Typography variant="body1" color="textSecondary">
            Evita descargar la batería por debajo de 10.5V para prevenir daños y
            asegurarte de que se mantenga en buen estado.
          </Typography>
        </DialogContent>
      </Dialog>

      {/* Componente BatteryChart */}
      <BatteryChart
        uid={uid}
        onVoltageUpdate={(voltage) => {
          setLatestVoltage(voltage);
          updateOnlineStatus(); // Actualizamos el estado online cuando recibimos un dato
        }}
      />

      {/* Componente BatteryPrediction */}
      <BatteryPrediction uid={uid} />
    </Container>
  );
};

export default EnlaceInfo;
