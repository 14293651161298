import React, { useState, useEffect } from "react";
import { db } from "../../firebase";
import { collection, getDocs } from "firebase/firestore";
import {
  Typography,
  Grid,
  CircularProgress,
  Box,
  Paper,
  TextField,
  MenuItem,
  Pagination,
  InputAdornment,
} from "@mui/material";
import { Link } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import FilterListIcon from "@mui/icons-material/FilterList";

const SearchInspection = () => {
  const [inspections, setInspections] = useState([]);
  const [filteredInspections, setFilteredInspections] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [search, setSearch] = useState("");
  const [statusFilter, setStatusFilter] = useState("");
  const [statuses, setStatuses] = useState([]);
  const [page, setPage] = useState(1);
  const inspectionsPerPage = 9;

  useEffect(() => {
    const fetchInspections = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "inspecciones"));
        const inspectionsData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
          Date: doc.data().Date ? new Date(doc.data().Date) : new Date(), // Convertir fechas a tipo Date si no lo son
        }));

        // Ordenar de más reciente a más antiguo
        inspectionsData.sort((a, b) => b.Date - a.Date);

        const uniqueStatuses = [
          ...new Set(inspectionsData.map((insp) => insp.Status)),
        ];

        setInspections(inspectionsData);
        setFilteredInspections(inspectionsData);
        setStatuses(uniqueStatuses);
      } catch (err) {
        setError("Error al recuperar las inspecciones.");
      } finally {
        setLoading(false);
      }
    };

    fetchInspections();
  }, []);

  const handleSearch = (e) => {
    const { value } = e.target;
    setSearch(value);
    filterInspections(value, statusFilter);
  };

  const handleStatusFilter = (e) => {
    const { value } = e.target;
    setStatusFilter(value);
    filterInspections(search, value);
  };

  const filterInspections = (searchValue, statusValue) => {
    const filtered = inspections.filter((inspection) => {
      const matchesSearch =
        inspection.Unidad.toLowerCase().includes(searchValue.toLowerCase()) ||
        inspection.Date.toLocaleDateString()
          .toLowerCase()
          .includes(searchValue.toLowerCase()) ||
        inspection.Status.toLowerCase().includes(searchValue.toLowerCase());

      const matchesStatus = statusValue
        ? inspection.Status === statusValue
        : true;

      return matchesSearch && matchesStatus;
    });

    setFilteredInspections(filtered);
    setPage(1); // Reset to first page on filter
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
        bgcolor="#0d0d0d"
      >
        <CircularProgress color="secondary" />
      </Box>
    );
  }

  if (error) {
    return (
      <Typography color="error" align="center" variant="h6">
        {error}
      </Typography>
    );
  }

  const startIndex = (page - 1) * inspectionsPerPage;
  const paginatedInspections = filteredInspections.slice(
    startIndex,
    startIndex + inspectionsPerPage
  );

  return (
    <Box
      sx={{
        p: 2,
        bgcolor: "#0d0d0d",
        minHeight: "100vh",
      }}
    >
      <Typography
        variant="h4"
        component="div"
        gutterBottom
        sx={{
          color: "#00ffff",
          borderBottom: "3px solid #00ffff",
          paddingBottom: "12px",
          fontWeight: "bold",
          textAlign: "center",
        }}
      >
        Todas las Inspecciones
      </Typography>

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 3,
          gap: 2,
          flexDirection: { xs: "column", sm: "row" }, // Responsivo
        }}
      >
        <TextField
          variant="outlined"
          label="Buscar por Unidad, Fecha o Status"
          value={search}
          onChange={handleSearch}
          sx={{
            bgcolor: "#1a1a1a",
            borderRadius: 1,
            width: "100%",
            input: { color: "#ffffff" },
            label: { color: "#00ffff" },
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "#00ffff",
              },
              "&:hover fieldset": {
                borderColor: "#00ffff",
              },
            },
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon sx={{ color: "#00ffff" }} />
              </InputAdornment>
            ),
          }}
        />

        <TextField
          select
          variant="outlined"
          label="Filtrar por Status"
          value={statusFilter}
          onChange={handleStatusFilter}
          sx={{
            bgcolor: "#1a1a1a",
            borderRadius: 1,
            width: { xs: "100%", sm: "30%" }, // Responsivo
            input: { color: "#ffffff" },
            label: { color: "#00ffff" },
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "#00ffff",
              },
              "&:hover fieldset": {
                borderColor: "#00ffff",
              },
            },
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <FilterListIcon sx={{ color: "#00ffff" }} />
              </InputAdornment>
            ),
          }}
        >
          <MenuItem value="">
            <em>Todos</em>
          </MenuItem>
          {statuses.map((status) => (
            <MenuItem key={status} value={status}>
              {status}
            </MenuItem>
          ))}
        </TextField>
      </Box>

      <Grid container spacing={2}>
        {paginatedInspections.map((inspection) => (
          <Grid item xs={12} sm={6} md={4} key={inspection.id}>
            <Link
              to={`/inspeccion/${inspection.id}`}
              style={{ textDecoration: "none" }}
            >
              <Paper
                sx={{
                  p: 2,
                  borderRadius: 2,
                  boxShadow: "0 4px 16px rgba(0, 255, 255, 0.3)",
                  backgroundColor:
                    inspection.Status === "Correcto"
                      ? "#004d00"
                      : inspection.Status === "En Progreso"
                      ? "#ffff00"
                      : "#1a1a1a",
                  color: "#ffffff",
                  "&:hover": {
                    boxShadow: "0 8px 24px rgba(0, 255, 255, 0.5)",
                  },
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Box>
                  <Typography
                    variant="h6"
                    gutterBottom
                    sx={{ color: "#00ffff" }}
                  >
                    Unidad: {inspection.Unidad}
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    <strong>Fecha:</strong>{" "}
                    {inspection.Date instanceof Date
                      ? inspection.Date.toLocaleDateString()
                      : "Fecha no disponible"}
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    <strong>Status:</strong> {inspection.Status}
                  </Typography>
                </Box>
                {inspection.fotos?.["Foto unidad"] && (
                  <img
                    src={inspection.fotos["Foto unidad"]}
                    alt="Foto unidad"
                    style={{
                      width: "80px",
                      height: "80px",
                      borderRadius: "8px",
                      marginLeft: "16px",
                      boxShadow: "0 4px 8px rgba(0, 255, 255, 0.3)",
                    }}
                  />
                )}
              </Paper>
            </Link>
          </Grid>
        ))}
      </Grid>

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          marginTop: 4,
        }}
      >
        <Pagination
          count={Math.ceil(filteredInspections.length / inspectionsPerPage)}
          page={page}
          onChange={handlePageChange}
          sx={{
            "& .MuiPaginationItem-root": {
              color: "#00ffff",
            },
          }}
        />
      </Box>
    </Box>
  );
};

export default SearchInspection;
