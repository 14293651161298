import React, { useEffect, useRef } from "react";
import { Box, Typography, Button } from "@mui/material";
import { styled } from "@mui/system";
import Slider from "react-slick";
import Typed from "typed.js";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const HeroContainer = styled(Box)`
  height: 70vh;
  position: relative;
  overflow: hidden;
`;

const Overlay = styled(Box)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  text-align: center; /* Centrar el texto horizontalmente */
`;

const StyledSlider = styled(Slider)`
  .slick-slide img {
    width: 100%;
    height: 70vh;
    object-fit: cover;
  }

  .slick-dots {
    bottom: 15px;
    li button:before {
      color: white;
    }
  }
`;

const FixedText = styled(Typography)`
  font-family: "Roboto", sans-serif;
  font-size: 3rem;
  font-weight: 500;
  margin-bottom: 1rem;
  color: #fff;
  letter-spacing: 3px;
  @media (max-width: 600px) {
    font-size: 2.5rem;
  }
`;

const TypedText = styled(Box)`
  font-family: "Roboto", sans-serif;
  font-size: 3rem;
  font-weight: 700;
  color: #fff;
  margin-bottom: 2rem;
  letter-spacing: 3px;
  @media (max-width: 600px) {
    font-size: 2.5rem;
  }
`;

const StyledButton = styled(Button)`
  font-family: "Roboto", sans-serif;
  font-size: 1.2rem;
  font-weight: 500;
  padding: 12px 30px;
  border: 2px solid #fff;
  color: #fff;
  background-color: transparent;
  transition: all 0.3s ease;
  &:hover {
    background-color: #fff;
    color: #000;
  }
`;

const Hero = () => {
  const typedElement = useRef(null);

  useEffect(() => {
    const typed = new Typed(typedElement.current, {
      strings: [
        "Radio comunicación",
        "Seguridad",
        "Inspección",
        "Drones",
        "Equipamiento de Vehículos",
        "Puntos de repeticion",
      ],
      typeSpeed: 80,
      backSpeed: 40,
      backDelay: 1000,
      loop: true,
    });

    return () => {
      typed.destroy();
    };
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    fade: true,
  };

  return (
    <HeroContainer id="hero">
      <StyledSlider {...settings}>
        <div>
          <img
            src="https://firebasestorage.googleapis.com/v0/b/radiosistemas-a46cd.appspot.com/o/dji_0558-min-1500h.jpg?alt=media&token=3393d48b-c3fc-4ac0-8984-1ebcd2cdc03b"
            alt="Slide 1"
          />
        </div>
      </StyledSlider>
      <Overlay>
        <FixedText variant="h1">Expertos en</FixedText>
        <TypedText ref={typedElement}></TypedText>
      </Overlay>
    </HeroContainer>
  );
};

export default Hero;
