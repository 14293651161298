import React, { useState } from "react";
import {
  TextField,
  Button,
  Box,
  CircularProgress,
  Typography,
  Grid,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  Snackbar,
  Alert,
} from "@mui/material";
import { addDoc, collection } from "firebase/firestore";
import { db } from "../../../firebase";

const NuevoCliente = ({ handleClose, onClienteAdded }) => {
  const [nombre, setNombre] = useState("");
  const [servicio, setServicio] = useState("");
  const [ubicacion, setUbicacion] = useState("");
  const [loading, setLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const handleAddCliente = async () => {
    setLoading(true);
    try {
      const docRef = await addDoc(collection(db, "clientes"), {
        Nombre: nombre,
        Servicio: servicio,
        Ubicacion: ubicacion,
      });
      onClienteAdded({
        id: docRef.id,
        Nombre: nombre,
        Servicio: servicio,
        Ubicacion: ubicacion,
      });
      setSnackbarMessage(`Cliente: ${nombre} agregado correctamente.`);
      setSnackbarOpen(true);
      handleClose();
    } catch (error) {
      console.error("Error al agregar el cliente:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <Box
      sx={{
        p: 3,
        borderRadius: 2,
        boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
        backgroundColor: "#1c1c1c",
        color: "#ffffff",
      }}
    >
      <Typography
        variant="h5"
        gutterBottom
        sx={{
          textAlign: "center",
          fontWeight: "bold",
          color: "#00ffff",
          mb: 3,
        }}
      >
        Agregar Nuevo Cliente
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            label="Nombre de Cliente"
            variant="outlined"
            fullWidth
            value={nombre}
            onChange={(e) => setNombre(e.target.value)}
            sx={{
              "& .MuiOutlinedInput-root": {
                color: "#ffffff",
                borderColor: "#00ffff",
              },
              "& .MuiInputLabel-root": {
                color: "#00ffff",
              },
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "#00ffff",
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: "#00ffff",
              },
              mb: 2,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth variant="outlined" sx={{ mb: 2 }}>
            <InputLabel sx={{ color: "#00ffff" }}>Servicio</InputLabel>
            <Select
              value={servicio}
              onChange={(e) => setServicio(e.target.value)}
              label="Servicio"
              sx={{
                color: "#ffffff",
                ".MuiOutlinedInput-notchedOutline": {
                  borderColor: "#00ffff",
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#00ffff",
                },
                ".MuiSvgIcon-root ": {
                  fill: "#00ffff !important",
                },
              }}
            >
              <MenuItem value="Renta (Enlaces)">Renta (Enlaces)</MenuItem>
              <MenuItem value="Renta (Repetidora)">Renta (Repetidora)</MenuItem>
              <MenuItem value="Renta (RadioDifusora)">
                Renta (RadioDifusora)
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth variant="outlined" sx={{ mb: 2 }}>
            <InputLabel sx={{ color: "#00ffff" }}>Ubicación</InputLabel>
            <Select
              value={ubicacion}
              onChange={(e) => setUbicacion(e.target.value)}
              label="Ubicación"
              sx={{
                color: "#ffffff",
                ".MuiOutlinedInput-notchedOutline": {
                  borderColor: "#00ffff",
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#00ffff",
                },
                ".MuiSvgIcon-root ": {
                  fill: "#00ffff !important",
                },
              }}
            >
              <MenuItem value="Caseta 1">Caseta 1</MenuItem>
              <MenuItem value="Caseta 2">Caseta 2</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          mt: 4,
        }}
      >
        <Button
          variant="contained"
          color="primary"
          onClick={handleAddCliente}
          disabled={loading}
          sx={{
            backgroundColor: "#00C49F",
            color: "#000000",
            "&:hover": {
              backgroundColor: "#009688",
            },
          }}
        >
          {loading ? <CircularProgress size={24} color="inherit" /> : "Agregar"}
        </Button>
        <Button
          variant="outlined"
          color="secondary"
          onClick={handleClose}
          sx={{
            borderColor: "#ff1744",
            color: "#ff1744",
            "&:hover": {
              borderColor: "#ff4569",
              color: "#ff4569",
            },
          }}
        >
          Cancelar
        </Button>
      </Box>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity="success"
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default NuevoCliente;
