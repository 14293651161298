import React, { useState } from "react";
import {
  Grid,
  Button,
  Box,
  Typography,
  useTheme,
  useMediaQuery,
  Paper,
  TextField,
} from "@mui/material";
import {
  Inventory2,
  Build,
  Security,
  Radio,
  People,
  Assignment,
  Receipt,
  MeetingRoom,
  Business,
  ContactMail,
} from "@mui/icons-material";
import { Link } from "react-router-dom";

const AdminMenu = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [searchTerm, setSearchTerm] = useState("");

  const menuItems = [
    { text: "Enlaces", icon: <Inventory2 />, path: "/enlaces" },
    { text: "Empleados", icon: <People />, path: "/empleados" },
    { text: "Nomina", icon: <Inventory2 />, path: "/nomina" },
    { text: "Nuevo Cliente", icon: <Inventory2 />, path: "/nuevocliente" },
    { text: "Inventario", icon: <Inventory2 />, path: "/inventario" },
    { text: "Mantenimiento", icon: <Build />, path: "/mantenimiento" },
    { text: "Garantías", icon: <Security />, path: "/garantias" },
    { text: "Radios", icon: <Radio />, path: "/radios" },
    { text: "Personal", icon: <People />, path: "/personal" },
    { text: "Proyectos", icon: <Assignment />, path: "/proyectos" },
    { text: "Facturas", icon: <Receipt />, path: "/facturas" },
    { text: "Reuniones", icon: <MeetingRoom />, path: "/reuniones" },
    { text: "Clientes", icon: <Business />, path: "/clientes" },
    { text: "Contactos", icon: <ContactMail />, path: "/contactos" },
  ];

  const filteredItems = menuItems.filter((item) =>
    item.text.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <Box sx={{ p: 2 }}>
      <Typography
        variant="h4"
        gutterBottom
        align="center"
        sx={{
          color: "#00ffff",
          borderBottom: "3px solid #00ffff",
          paddingBottom: "12px",
          fontWeight: "bold",
        }}
      >
        Panel de Administración
      </Typography>

      <Box sx={{ mb: 3 }}>
        <TextField
          fullWidth
          variant="outlined"
          label="Buscar en el menú"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          sx={{
            input: { color: "#ffffff" },
            label: { color: "#00ffff" },
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "#00ffff",
              },
              "&:hover fieldset": {
                borderColor: "#00ffff",
              },
              "&.Mui-focused fieldset": {
                borderColor: "#00ffff",
              },
            },
          }}
          InputLabelProps={{
            style: { color: "#00ffff" },
          }}
        />
      </Box>

      <Grid container spacing={isMobile ? 2 : 3} justifyContent="center">
        {filteredItems.map((item, index) => (
          <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
            <Paper
              elevation={6}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: isMobile ? 100 : 120,
                borderRadius: 2,
                backgroundColor: "#1a1a1a",
                border: "2px solid #00ffff",
                transition: "transform 0.3s, box-shadow 0.3s",
                "&:hover": {
                  transform: "translateY(-3px)",
                  boxShadow: "0 12px 24px rgba(0, 255, 255, 0.2)",
                },
              }}
            >
              <Button
                component={Link}
                to={item.path}
                variant="text"
                startIcon={item.icon}
                sx={{
                  width: "100%",
                  height: "100%",
                  fontSize: isMobile ? "1rem" : "1.2rem",
                  textTransform: "none",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  color: "#00ffff",
                  "& .MuiButton-startIcon": {
                    marginBottom: isMobile ? 0.5 : 1,
                    fontSize: isMobile ? 36 : 48,
                    color: "#00ffff",
                  },
                }}
              >
                <Typography
                  variant="h6"
                  sx={{
                    mt: 1,
                    color: "#ffffff",
                    fontWeight: "bold",
                  }}
                >
                  {item.text}
                </Typography>
              </Button>
            </Paper>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default AdminMenu;
