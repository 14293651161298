import React from "react";
import { Navigate } from "react-router-dom";
import { useUserAuth } from "../context/userAuthContext";
import { CircularProgress, Box } from "@mui/material";

const ProtectedRoute = ({ children, roles }) => {
  const { user } = useUserAuth();
  const [isAuthorized, setIsAuthorized] = React.useState(null);

  React.useEffect(() => {
    if (user) {
      // Verificar si el usuario tiene los roles adecuados
      const userRoles = user.roles || []; // Asumiendo que los roles están en user.roles
      const hasRole = roles.some((role) => userRoles.includes(role));
      setIsAuthorized(hasRole);
    } else {
      setIsAuthorized(false);
    }
  }, [user, roles]);

  if (isAuthorized === null) {
    // Mientras verifica el rol, muestra un indicador de carga
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "100vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return isAuthorized ? children : <Navigate to="/login" />;
};

export default ProtectedRoute;
