import React, { useState } from "react";
import { useTable } from "react-table";
import ReactPaginate from "react-paginate";
import Papa from "papaparse";
import { db } from "./firebase";
import { collection, doc, writeBatch } from "firebase/firestore";

// Constantes
const ITEMS_PER_PAGE = 100;
const BATCH_SIZE = 500; // Tamaño del lote para Firebase

// Componente principal
const ProductImport = () => {
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [searchInput, setSearchInput] = useState("");
  const [uploadStatus, setUploadStatus] = useState(0); // Estado para el progreso de la carga
  const [totalUploaded, setTotalUploaded] = useState(0); // Estado para el total de productos subidos
  const [totalProducts, setTotalProducts] = useState(0); // Total de productos

  // Maneja la carga de archivo CSV
  const handleFileLoad = (file) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      const text = event.target.result;
      Papa.parse(text, {
        encoding: "utf-8",
        header: true,
        skipEmptyLines: true,
        complete: (result) => {
          const headers = Object.keys(result.data[0]).map((header, index) => ({
            Header: header,
            accessor: header,
            id: index,
          }));
          const rows = result.data.map((row, rowIndex) => ({
            ...row,
            id: rowIndex,
          }));
          setColumns(headers);
          setData(rows);
          setTotalProducts(rows.length); // Establece el total de productos
        },
      });
    };
    reader.readAsText(file, "utf-8");
  };

  // Maneja el clic de paginación
  const handlePageClick = (event) => {
    setCurrentPage(event.selected);
  };

  // Filtra los datos basados en el input de búsqueda
  const filteredData = data.filter((product) =>
    product.Descripcion?.toLowerCase().includes(searchInput.toLowerCase())
  );

  const offset = currentPage * ITEMS_PER_PAGE;
  const currentData = filteredData.slice(offset, offset + ITEMS_PER_PAGE);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data: currentData,
    });

  // Sube un lote de productos a Firebase
  const uploadBatchToFirebase = async (batchData) => {
    const batch = writeBatch(db);
    batchData.forEach((product) => {
      const productId = product["Modelo"].replace(/\//g, "-");
      const productRef = doc(collection(db, "products"), productId);
      batch.set(productRef, product, { merge: true }); // Usar merge: true para actualizar los documentos existentes
    });

    try {
      await batch.commit();
      setTotalUploaded((prev) => prev + batchData.length);
    } catch (error) {
      console.error("Error subiendo el lote a Firebase: ", error);
      alert(
        "Hubo un error al subir el lote. Revisa la consola para más detalles."
      );
    }
  };

  // Sube todos los datos a Firebase en lotes
  const uploadDataToFirebase = async () => {
    const totalBatches = Math.ceil(data.length / BATCH_SIZE);
    setUploadStatus(0);
    setTotalUploaded(0);

    for (let i = 0; i < totalBatches; i++) {
      const batchData = data.slice(i * BATCH_SIZE, (i + 1) * BATCH_SIZE);
      await uploadBatchToFirebase(batchData);
      setUploadStatus(((i + 1) / totalBatches) * 100);
    }

    alert("Todos los datos se han subido exitosamente a Firebase!");
  };

  return (
    <div style={{ padding: "20px", fontFamily: "Arial, sans-serif" }}>
      <h1 style={{ textAlign: "center", marginBottom: "20px", color: "#333" }}>
        Importar y Visualizar Productos
      </h1>
      <input
        type="file"
        accept=".csv"
        onChange={(e) => {
          const file = e.target.files[0];
          handleFileLoad(file);
        }}
        style={{ color: "#333", marginBottom: "20px" }}
      />

      {/* Buscador */}
      <input
        type="text"
        placeholder="Buscar por descripción..."
        value={searchInput}
        onChange={(e) => setSearchInput(e.target.value)}
        style={{
          padding: "10px",
          width: "100%",
          marginBottom: "20px",
          borderRadius: "5px",
          border: "1px solid #ddd",
        }}
      />

      <table
        {...getTableProps()}
        style={{
          borderCollapse: "collapse",
          width: "100%",
          marginTop: "20px",
          boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
        }}
      >
        <thead>
          {headerGroups.map((headerGroup, index) => (
            <tr {...headerGroup.getHeaderGroupProps()} key={index}>
              {headerGroup.headers.map((column, colIndex) => (
                <th
                  {...column.getHeaderProps()}
                  key={colIndex}
                  style={{
                    borderBottom: "solid 2px #ddd",
                    backgroundColor: "#f4f4f4",
                    padding: "10px",
                    textAlign: "left",
                    color: "#333",
                    fontWeight: "bold",
                  }}
                >
                  {column.render("Header")}
                </th>
              ))}
              <th
                style={{
                  borderBottom: "solid 2px #ddd",
                  backgroundColor: "#f4f4f4",
                  padding: "10px",
                  textAlign: "left",
                  color: "#333",
                  fontWeight: "bold",
                }}
              >
                Acciones
              </th>
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, rowIndex) => {
            prepareRow(row);
            return (
              <tr
                {...row.getRowProps()}
                key={rowIndex}
                style={{
                  borderBottom: "solid 1px #ddd",
                  backgroundColor: rowIndex % 2 === 0 ? "#f9f9f9" : "white",
                }}
              >
                {row.cells.map((cell, cellIndex) => (
                  <td
                    {...cell.getCellProps()}
                    key={cellIndex}
                    style={{
                      padding: "10px",
                      textAlign: "left",
                      color: "#555",
                      wordBreak: "break-word",
                    }}
                  >
                    {cell.render("Cell")}
                  </td>
                ))}
                <td style={{ padding: "10px", textAlign: "center" }}>
                  <button
                    onClick={() => uploadBatchToFirebase([row.original])}
                    style={{
                      padding: "5px 10px",
                      backgroundColor: "#007bff",
                      color: "white",
                      border: "none",
                      borderRadius: "5px",
                      cursor: "pointer",
                      fontSize: "14px",
                    }}
                  >
                    Subir
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>

      <div
        style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}
      >
        <ReactPaginate
          previousLabel={"«"}
          nextLabel={"»"}
          breakLabel={"..."}
          breakClassName={"break-me"}
          pageCount={Math.ceil(filteredData.length / ITEMS_PER_PAGE)}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={handlePageClick}
          containerClassName={"pagination"}
          subContainerClassName={"pages pagination"}
          activeClassName={"active"}
          pageClassName={"page-item"}
          pageLinkClassName={"page-link"}
          previousClassName={"page-item"}
          previousLinkClassName={"page-link"}
          nextClassName={"page-item"}
          nextLinkClassName={"page-link"}
          breakLinkClassName={"page-link"}
          disabledClassName={"disabled"}
          renderOnZeroPageCount={null}
        />
      </div>

      {/* Botón para subir todos los datos */}
      <div
        style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}
      >
        <button
          onClick={uploadDataToFirebase}
          style={{
            padding: "10px 20px",
            backgroundColor: "#28a745",
            color: "white",
            border: "none",
            borderRadius: "5px",
            cursor: "pointer",
            fontSize: "16px",
          }}
        >
          Subir Todos los Datos a Firebase
        </button>
      </div>

      {/* Progreso de la carga */}
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "20px",
          color: "#333",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        {totalProducts > 0 && (
          <>
            <p>{`Productos subidos: ${totalUploaded}/${totalProducts}`}</p>
            <p>{`Progreso de la carga: ${uploadStatus.toFixed(2)}%`}</p>
            <progress
              value={totalUploaded}
              max={totalProducts}
              style={{ width: "80%" }}
            >
              {totalUploaded}/{totalProducts}
            </progress>
          </>
        )}
      </div>
    </div>
  );
};

export default ProductImport;
