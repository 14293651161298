import React, { useEffect, useState } from "react";
import {
  Container,
  Grid,
  Typography,
  Paper,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Avatar,
  TextField,
} from "@mui/material";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../../firebase";
import { useNavigate } from "react-router-dom";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";

const SearchOficial = () => {
  const [oficiales, setOficiales] = useState([]);
  const [filteredOficiales, setFilteredOficiales] = useState([]);
  const [searchNombre, setSearchNombre] = useState("");
  const [selectedZona, setSelectedZona] = useState("");
  const [selectedTipo, setSelectedTipo] = useState("");
  const navigate = useNavigate();

  const sortedZonas = (zonas) => zonas.sort((a, b) => a.localeCompare(b));
  const sortedTipos = (tipos) => tipos.sort((a, b) => a.localeCompare(b));

  const zonas = sortedZonas([
    ...new Set(oficiales.map((oficial) => oficial.zona)),
  ]);
  const tipos = sortedTipos([
    ...new Set(oficiales.map((oficial) => oficial.tipo)),
  ]);

  useEffect(() => {
    const fetchOficiales = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "oficiales"));
        const oficialesData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        oficialesData.sort((a, b) => a.nombre.localeCompare(b.nombre));
        setOficiales(oficialesData);
        setFilteredOficiales(oficialesData);
      } catch (error) {
        console.error("Error al obtener los oficiales:", error);
      }
    };

    fetchOficiales();
  }, []);

  useEffect(() => {
    let filtered = oficiales;

    if (searchNombre) {
      filtered = filtered.filter((oficial) =>
        oficial.nombre.toLowerCase().includes(searchNombre.toLowerCase())
      );
    }

    if (selectedZona) {
      filtered = filtered.filter((oficial) => oficial.zona === selectedZona);
    }

    if (selectedTipo) {
      filtered = filtered.filter((oficial) => oficial.tipo === selectedTipo);
    }

    setFilteredOficiales(filtered);
  }, [searchNombre, selectedZona, selectedTipo, oficiales]);

  const handleOficialClick = (id) => {
    navigate(`/oficial/${id}`);
  };

  return (
    <div
      style={{
        backgroundColor: "#0d0d0d",
        minHeight: "100vh",
        padding: "20px 0",
      }}
    >
      <Container maxWidth="xl">
        <Paper
          sx={{
            p: 4,
            borderRadius: 4,
            boxShadow: "0 8px 24px rgba(0, 255, 255, 0.2)",
            backgroundColor: "#1a1a1a",
            color: "#00ffff",
          }}
        >
          <Typography
            variant="h4"
            align="center"
            gutterBottom
            sx={{
              color: "#00ffff",
              borderBottom: "3px solid #00ffff",
              paddingBottom: "12px",
              fontWeight: "bold",
            }}
          >
            Buscar Oficiales
          </Typography>

          <Grid container spacing={3} sx={{ my: 3 }}>
            <Grid item xs={12} sm={4}>
              <TextField
                fullWidth
                label="Buscar por Nombre"
                variant="outlined"
                value={searchNombre}
                onChange={(e) => setSearchNombre(e.target.value)}
                sx={{
                  input: { color: "#00ffff" },
                  label: { color: "#00ffff" },
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "#00ffff",
                    },
                    "&:hover fieldset": {
                      borderColor: "#00ffff",
                    },
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormControl fullWidth variant="outlined">
                <InputLabel sx={{ color: "#00ffff" }}>Zona</InputLabel>
                <Select
                  value={selectedZona}
                  onChange={(e) => setSelectedZona(e.target.value)}
                  label="Zona"
                  sx={{
                    color: "#00ffff",
                    ".MuiOutlinedInput-notchedOutline": {
                      borderColor: "#00ffff",
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#00ffff",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#00ffff",
                    },
                  }}
                >
                  <MenuItem value="">
                    <em>Todos</em>
                  </MenuItem>
                  {zonas.map((zona, index) => (
                    <MenuItem key={index} value={zona}>
                      {zona}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormControl fullWidth variant="outlined">
                <InputLabel sx={{ color: "#00ffff" }}>Tipo</InputLabel>
                <Select
                  value={selectedTipo}
                  onChange={(e) => setSelectedTipo(e.target.value)}
                  label="Tipo"
                  sx={{
                    color: "#00ffff",
                    ".MuiOutlinedInput-notchedOutline": {
                      borderColor: "#00ffff",
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#00ffff",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#00ffff",
                    },
                  }}
                >
                  <MenuItem value="">
                    <em>Todos</em>
                  </MenuItem>
                  {tipos.map((tipo, index) => (
                    <MenuItem key={index} value={tipo}>
                      {tipo}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          <Grid container spacing={3}>
            {filteredOficiales.map((oficial) => (
              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                lg={3}
                key={oficial.id}
                onClick={() => handleOficialClick(oficial.id)}
                sx={{ cursor: "pointer" }}
              >
                <Paper
                  sx={{
                    p: 3,
                    borderRadius: 4,
                    backgroundColor: "#1a1a1a",
                    boxShadow: "0 4px 16px rgba(0, 255, 255, 0.3)",
                    textAlign: "center",
                    color: "#ffffff",
                    borderColor: "#00ffff",
                    borderWidth: 2,
                    borderStyle: "solid",
                    "&:hover": {
                      boxShadow: "0 6px 20px rgba(0, 255, 255, 0.5)",
                    },
                  }}
                >
                  <Avatar
                    src={oficial.fotoURL || "https://via.placeholder.com/150"}
                    alt={oficial.nombre}
                    sx={{
                      width: 80,
                      height: 80,
                      mb: 2,
                      mx: "auto",
                      bgcolor: "#00ffff",
                    }}
                  >
                    <AccountCircleIcon sx={{ fontSize: 40 }} />
                  </Avatar>
                  <Typography
                    variant="h6"
                    gutterBottom
                    sx={{ color: "#00ffff", fontWeight: "bold" }}
                  >
                    {oficial.nombre}
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    <strong>Zona:</strong> {oficial.zona}
                  </Typography>
                  <Typography variant="body1">
                    <strong>Tipo:</strong> {oficial.tipo || "No especificado"}
                  </Typography>
                </Paper>
              </Grid>
            ))}
          </Grid>
        </Paper>
      </Container>
    </div>
  );
};

export default SearchOficial;
