import React, { useState, useEffect } from "react";
import { db } from "../../firebase"; // Importa Firestore desde firebase.js
import {
  collection,
  query,
  where,
  getDocs,
  onSnapshot,
} from "firebase/firestore";
import {
  TextField,
  Button,
  Card,
  CardContent,
  Typography,
  Grid,
  Paper,
  Container,
  useMediaQuery,
  useTheme,
  Dialog,
  DialogContent,
} from "@mui/material";
import QrScanner from "react-qr-scanner";
import SearchIcon from "@mui/icons-material/Search";
import ScannerIcon from "@mui/icons-material/QrCodeScanner";

const InventarioCompleto = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [productos, setProductos] = useState([]);
  const [clientes, setClientes] = useState({});
  const [marcas, setMarcas] = useState({});
  const [message, setMessage] = useState("");
  const [showQrScanner, setShowQrScanner] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    const fetchAllProductos = async () => {
      try {
        const productosRef = collection(db, "inventory");
        const querySnapshot = await getDocs(productosRef);
        const productosList = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setProductos(productosList);
      } catch (error) {
        console.error("Error al obtener el inventario:", error);
        setMessage("Error al obtener el inventario. Intenta nuevamente.");
      }
    };

    const fetchClientes = async () => {
      try {
        const clientesRef = collection(db, "clientes");
        const querySnapshot = await getDocs(clientesRef);
        const clientesMap = {};
        querySnapshot.forEach((doc) => {
          clientesMap[doc.id] = doc.data().Nombre;
        });
        setClientes(clientesMap);
      } catch (error) {
        console.error("Error al obtener los clientes:", error);
        setMessage("Error al obtener los clientes. Intenta nuevamente.");
      }
    };

    const fetchMarcas = async () => {
      try {
        const marcasRef = collection(db, "marcas");
        const querySnapshot = await getDocs(marcasRef);
        const marcasMap = {};
        querySnapshot.forEach((doc) => {
          marcasMap[doc.id] = doc.data().nombre;
        });
        setMarcas(marcasMap);
      } catch (error) {
        console.error("Error al obtener las marcas:", error);
        setMessage("Error al obtener las marcas. Intenta nuevamente.");
      }
    };

    fetchAllProductos();
    fetchClientes();
    fetchMarcas();
  }, []);

  const handleSearch = async () => {
    if (!searchTerm.trim()) {
      setMessage("Por favor, ingrese un término de búsqueda.");
      return;
    }

    try {
      const productosRef = collection(db, "inventory");

      const q = query(productosRef, where("numInventario", "==", searchTerm));

      const querySnapshot = await getDocs(q);
      const productosList = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setProductos(productosList);

      if (productosList.length === 0) {
        setMessage(
          "No se encontraron productos que coincidan con la búsqueda."
        );
      } else {
        setMessage("");
      }
    } catch (error) {
      console.error("Error al buscar productos:", error);
      setMessage("Error al buscar productos. Intenta nuevamente.");
    }
  };

  const handleScan = (data) => {
    if (data) {
      setSearchTerm(data.text); // Usamos data.text para obtener el valor del QR
      setShowQrScanner(false);
      handleSearch();
    }
  };

  const handleError = (err) => {
    console.error("Error al escanear el código QR:", err);
    setMessage("Error al escanear el código QR. Intenta nuevamente.");
  };

  const previewStyle = {
    height: 240,
    width: 320,
  };

  const qrScannerProps = {
    delay: 300,
    onError: handleError,
    onScan: handleScan,
    style: previewStyle,
    constraints: {
      video: {
        facingMode: { exact: "environment" }, // Intenta forzar la cámara trasera
      },
    },
  };

  return (
    <Container maxWidth="md" sx={{ py: 4 }}>
      <Card
        sx={{
          p: 3,
          borderRadius: 3,
          boxShadow: "0 8px 24px rgba(0,0,0,0.12)",
          mb: 4,
        }}
      >
        <CardContent>
          <Typography variant="h5" component="div" gutterBottom align="center">
            Inventario Completo
          </Typography>
          <div
            style={{
              display: "flex",
              flexDirection: isMobile ? "column" : "row",
              marginBottom: 20,
            }}
          >
            <TextField
              fullWidth
              label="Buscar por Número de Inventario, Título, Marca, Modelo, etc."
              variant="outlined"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              sx={{
                marginBottom: isMobile ? 2 : 0,
                marginRight: isMobile ? 0 : 2,
              }}
            />
            <Button
              variant="contained"
              color="primary"
              startIcon={<SearchIcon />}
              onClick={handleSearch}
              sx={{
                minWidth: isMobile ? "100%" : "auto",
                px: isMobile ? 2 : 4,
                py: isMobile ? 1.5 : 1,
                fontSize: isMobile ? "1rem" : "1.1rem",
              }}
            >
              Buscar
            </Button>
          </div>
          <Button
            variant="outlined"
            color="secondary"
            startIcon={<ScannerIcon />}
            onClick={() => setShowQrScanner(!showQrScanner)}
            sx={{
              marginBottom: 2,
              display: "block",
              width: isMobile ? "100%" : "auto",
            }}
          >
            {showQrScanner ? "Cerrar Escáner QR" : "Escanear Código QR"}
          </Button>
          <Dialog
            open={showQrScanner}
            onClose={() => setShowQrScanner(false)}
            maxWidth="sm"
            fullWidth
          >
            <DialogContent sx={{ display: "flex", justifyContent: "center" }}>
              {showQrScanner && <QrScanner {...qrScannerProps} />}
            </DialogContent>
          </Dialog>
          {message && (
            <Typography variant="body1" color="text.secondary" sx={{ mt: 2 }}>
              {message}
            </Typography>
          )}
        </CardContent>
      </Card>

      <Grid container spacing={isMobile ? 2 : 3}>
        {productos.map((producto, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Paper
              elevation={3}
              sx={{
                p: 2,
                borderRadius: 3,
                transition: "transform 0.3s, box-shadow 0.3s",
                "&:hover": {
                  transform: "translateY(-5px)",
                  boxShadow: "0 10px 20px rgba(0,0,0,0.2)",
                },
              }}
            >
              <Typography
                variant="h6"
                sx={{ fontSize: isMobile ? "1rem" : "1.2rem" }}
              >
                {producto.titulo}
              </Typography>
              <Typography variant="body2" sx={{ mb: 1 }}>
                Número de Inventario: {producto.numInventario}
              </Typography>
              <Typography variant="body2" sx={{ mb: 1 }}>
                Marca: {marcas[producto.marcaId] || "No asignado"}
              </Typography>
              <Typography variant="body2" sx={{ mb: 1 }}>
                Modelo: {producto.modelo || "N/A"}
              </Typography>
              <Typography variant="body2" sx={{ mb: 1 }}>
                Categoría: {producto.categoriaId}
              </Typography>
              <Typography variant="body2" sx={{ mb: 1 }}>
                Subcategoría: {producto.subCategoria || "N/A"}
              </Typography>
              <Typography variant="body2" sx={{ mb: 1 }}>
                Cliente: {clientes[producto.clienteId] || "No asignado"}
              </Typography>
              <div style={{ marginTop: 10 }}>
                {producto.imagenes && producto.imagenes.length > 0 && (
                  <img
                    src={producto.imagenes[0]}
                    alt={`Producto ${index + 1}`}
                    style={{
                      width: "100%",
                      height: "150px",
                      objectFit: "cover",
                      borderRadius: 3,
                      marginBottom: 10,
                    }}
                  />
                )}
              </div>
            </Paper>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default InventarioCompleto;
