import React from "react";
import { Box, Container, Typography } from "@mui/material";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { styled } from "@mui/material/styles";

// Logos de las marcas
const logos = [
  { src: "https://ftp3.syscom.mx/usuarios/fotos/tassta.png", alt: "Tassta" },
  {
    src: "https://ftp3.syscom.mx/usuarios/fotos/logotipos/airbus.png",
    alt: "Airbus",
  },
  {
    src: "https://ftp3.syscom.mx/usuarios/fotos/logotipos/kenwood.png",
    alt: "Kenwood",
  },
  {
    src: "https://ftp3.syscom.mx/usuarios/fotos/logotipos/nxradio.png",
    alt: "NX Radio",
  },
  {
    src: "https://ftp3.syscom.mx/usuarios/fotos/logotipos/txpro.png",
    alt: "TX Pro",
  },
];

const LogoWrapper = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "140px", // Altura fija para todos los logos, ligeramente más alta
  transition: "transform 0.3s ease",
  "&:hover": {
    transform: "scale(1.1)", // Aumenta el efecto de zoom al pasar el mouse
  },
}));

const Logo = styled("img")({
  maxWidth: "70%", // Limita el ancho máximo
  maxHeight: "70%", // Limita la altura máxima
  objectFit: "contain",
  filter: "grayscale(100%)", // Aplica un filtro de escala de grises
  transition: "filter 0.3s ease, transform 0.3s ease",
  "&:hover": {
    filter: "grayscale(0%)", // Restaura el color al pasar el mouse
  },
});

const StyledSlider = styled(Slider)({
  "& .slick-track": {
    display: "flex",
    alignItems: "center",
  },
  "& .slick-slide": {
    height: "auto",
    display: "flex",
    justifyContent: "center", // Centra las fotos horizontalmente
  },
});

const Marcas = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4, // Cambié a 4 para una mejor disposición en pantallas grandes
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <Box sx={{ py: 8, backgroundColor: "background.paper" }}>
      <Container maxWidth="lg">
        <Typography
          variant="h4"
          align="center"
          gutterBottom
          sx={{ mb: 4, fontWeight: "bold" }}
        >
          Nuestras Marcas
        </Typography>
        <StyledSlider {...settings}>
          {logos.map((logo, index) => (
            <LogoWrapper key={index}>
              <Logo src={logo.src} alt={logo.alt} />
            </LogoWrapper>
          ))}
        </StyledSlider>
      </Container>
    </Box>
  );
};

export default Marcas;
