import React, { useState, useEffect } from "react";

const SuccessPage = () => {
  const [paymentInfo, setPaymentInfo] = useState(null);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlParams.entries());
    setPaymentInfo(params);
  }, []);

  return (
    <div className="success-container">
      <h1>Pago Exitoso</h1>
      {paymentInfo && (
        <div>
          <p>Collection ID: {paymentInfo.collection_id}</p>
          <p>Collection Status: {paymentInfo.collection_status}</p>
          <p>Payment ID: {paymentInfo.payment_id}</p>
          <p>Status: {paymentInfo.status}</p>
          <p>External Reference: {paymentInfo.external_reference}</p>
          {/* Agrega más campos según tus necesidades */}
        </div>
      )}
    </div>
  );
};

export default SuccessPage;
