import React, { useState, useEffect } from "react";
import { db, storage } from "../../firebase";
import { doc, getDoc, deleteDoc } from "firebase/firestore";
import { ref, deleteObject } from "firebase/storage";
import {
  Typography,
  CardContent,
  Grid,
  CircularProgress,
  Box,
  Modal,
  IconButton,
  Avatar,
  Button,
} from "@mui/material";
import { useParams, useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DeleteIcon from "@mui/icons-material/Delete";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import EngineeringIcon from "@mui/icons-material/Engineering";
import CommentIcon from "@mui/icons-material/Comment";
import ElectricCarIcon from "@mui/icons-material/ElectricCar";
import Locker from "../Locker";
import DiagnosticoInspeccion from "./DiagnosticoInspeccion"; // Importa el componente DiagnosticoInspeccion

const InspectionDetails = () => {
  const { uid } = useParams();
  const [inspectionData, setInspectionData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");
  const [lockerOpen, setLockerOpen] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchInspection = async () => {
      try {
        const docRef = doc(db, "inspecciones", uid);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          setInspectionData(docSnap.data());
        } else {
          setError("No se encontró la inspección.");
        }
      } catch (err) {
        setError("Error al recuperar los datos de la inspección.");
      } finally {
        setLoading(false);
      }
    };

    fetchInspection();
  }, [uid]);

  const handleOpenModal = (imageUrl) => {
    setSelectedImage(imageUrl);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedImage("");
  };

  const handleLockerSuccess = async () => {
    setLoading(true);

    try {
      const fotos = inspectionData.fotos || {};
      const deletePromises = Object.values(fotos).map((url) => {
        const imgRef = ref(storage, url);
        return deleteObject(imgRef);
      });

      await Promise.all(deletePromises);

      const docRef = doc(db, "inspecciones", uid);
      await deleteDoc(docRef);

      navigate("/buscarinspeccion");
    } catch (err) {
      setError("Error al eliminar la inspección.");
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteClick = () => {
    setLockerOpen(true);
  };

  const handleLockerClose = () => {
    setLockerOpen(false);
  };

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
        bgcolor="#0d0d0d"
      >
        <CircularProgress color="secondary" />
      </Box>
    );
  }

  if (error) {
    return (
      <Typography color="error" align="center" variant="h6">
        {error}
      </Typography>
    );
  }

  return (
    <Box
      sx={{
        p: 2,
        bgcolor: "#0d0d0d",
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <CardContent>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography
            variant="h6"
            component="div"
            gutterBottom
            sx={{
              color: "#00ffff",
              borderBottom: "3px solid #00ffff",
              paddingBottom: "12px",
              fontWeight: "bold",
            }}
          >
            Folio: {uid}
          </Typography>
          <Button
            variant="contained"
            color="error"
            startIcon={<DeleteIcon />}
            onClick={handleDeleteClick}
            sx={{
              fontSize: "0.75rem",
              padding: "6px 12px",
              minWidth: "auto",
              bgcolor: "#ff1744",
              "&:hover": {
                bgcolor: "#ff4569",
              },
            }}
          >
            Borrar
          </Button>
        </Box>
        <Grid container spacing={4} mt={3}>
          <Grid item xs={12} md={4}>
            <Box
              sx={{
                backgroundColor: "#111",
                borderRadius: "12px",
                p: 2,
                boxShadow: "0 4px 12px rgba(0, 255, 255, 0.2)",
                textAlign: "center",
                cursor: inspectionData.fotos?.["Oficial"] ? "pointer" : "auto",
              }}
              onClick={() => {
                if (inspectionData.fotos?.["Oficial"]) {
                  handleOpenModal(inspectionData.fotos["Oficial"]);
                }
              }}
            >
              <Avatar
                src={
                  inspectionData.fotos?.["Oficial"] ||
                  "https://firebasestorage.googleapis.com/v0/b/radiosistemas-a46cd.appspot.com/o/gray-photo-placeholder-icon-design-ui-vector-35850819.jpg?alt=media&token=de9efd3a-b1d5-4abe-b172-cf8de41832b5"
                }
                alt="Oficial"
                sx={{
                  width: "100%",
                  height: "auto",
                  aspectRatio: 1,
                  border: "3px solid #00ffff",
                  boxShadow: "0 4px 12px rgba(0, 255, 255, 0.4)",
                  borderRadius: "12px",
                  mb: 2,
                }}
              />
              <Typography
                variant="h6"
                align="center"
                gutterBottom
                sx={{ color: "#00ffff", fontWeight: "bold" }}
              >
                {inspectionData.Nombre}
              </Typography>
              {inspectionData.fotos?.["Firma"] && (
                <Box
                  sx={{
                    mt: 2,
                    p: 2,
                    bgcolor: "#ffffff",
                    borderRadius: 2,
                    boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
                  }}
                >
                  <Typography
                    variant="body2"
                    gutterBottom
                    sx={{ color: "#000", textAlign: "center" }}
                  >
                    Firma
                  </Typography>
                  <img
                    src={inspectionData.fotos["Firma"]}
                    alt="Firma"
                    style={{
                      maxWidth: "100%",
                      maxHeight: "150px",
                      height: "auto",
                      width: "auto",
                      borderRadius: "4px",
                      display: "block",
                      margin: "0 auto",
                    }}
                  />
                </Box>
              )}
            </Box>
          </Grid>
          <Grid item xs={12} md={8}>
            <Box
              sx={{
                p: 2,
                backgroundColor: "#111",
                borderRadius: "12px",
                boxShadow: "0 4px 12px rgba(0, 255, 255, 0.2)",
              }}
            >
              <Typography
                variant="h5"
                gutterBottom
                sx={{
                  color: "#00ffff",
                  fontWeight: "bold",
                  mb: 2,
                  textAlign: "center",
                  borderBottom: "2px solid #00ffff",
                  paddingBottom: "8px",
                }}
              >
                Información de la Inspección
              </Typography>
              <Box display="flex" alignItems="center" mb={2}>
                <CalendarTodayIcon sx={{ color: "#00ffff", mr: 1 }} />
                <Typography variant="body1" sx={{ color: "#ffffff" }}>
                  <strong>Fecha:</strong> {inspectionData.Date}
                </Typography>
              </Box>
              <Box display="flex" alignItems="center" mb={2}>
                <ElectricCarIcon sx={{ color: "#00ffff", mr: 1 }} />
                <Typography variant="body1" sx={{ color: "#ffffff" }}>
                  <strong>Unidad:</strong> {inspectionData.Unidad}
                </Typography>
              </Box>
              <Box display="flex" alignItems="center" mb={2}>
                <EngineeringIcon sx={{ color: "#00ffff", mr: 1 }} />
                <Typography variant="body1" sx={{ color: "#ffffff" }}>
                  <strong>Técnico en turno:</strong> {inspectionData.Usuario}
                </Typography>
              </Box>
              <Box display="flex" alignItems="center" mb={2}>
                <CheckCircleIcon
                  sx={{
                    color:
                      inspectionData.Status === "Correcto"
                        ? "#00ff00"
                        : "#ff0000",
                    mr: 1,
                  }}
                />
                <Typography variant="body1" sx={{ color: "#ffffff" }}>
                  <strong>Status:</strong> {inspectionData.Status}
                </Typography>
              </Box>
              <DiagnosticoInspeccion // Renderiza DiagnosticoInspeccion si hay datos de diagnóstico
                estadoGeneral={inspectionData.EstadoGeneral}
                fusiblesReemplazados={inspectionData.FusiblesReemplazados}
              />
              <Box
                sx={{
                  p: 2,
                  mt: 2,
                  backgroundColor: "#222",
                  borderRadius: "8px",
                  boxShadow: "0 4px 12px rgba(0, 255, 255, 0.2)",
                }}
              >
                <CommentIcon sx={{ color: "#00ffff", mr: 1 }} />
                <Typography
                  variant="body1"
                  gutterBottom
                  sx={{ color: "#00ffff", fontWeight: "bold" }}
                >
                  Comentario:
                </Typography>
                <Typography variant="body2" sx={{ color: "#ffffff" }}>
                  {inspectionData.Comentario}
                </Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>

        <Typography
          variant="h5"
          gutterBottom
          sx={{
            color: "#00ffff",
            borderBottom: "2px solid #00ffff",
            paddingBottom: "8px",
            fontWeight: "bold",
            mt: 4,
          }}
        >
          Fotos
        </Typography>
        <Grid container spacing={2}>
          {inspectionData.fotos &&
            Object.entries(inspectionData.fotos)
              .filter(([key]) => key !== "Firma" && key !== "Oficial")
              .map(([key, url]) => (
                <Grid item xs={6} sm={4} key={key}>
                  <Box
                    sx={{
                      bgcolor: "#0d0d0d",
                      padding: "8px",
                      borderRadius: "8px",
                      boxShadow: "0 4px 10px rgba(0, 255, 255, 0.3)",
                      cursor: "pointer",
                    }}
                    onClick={() => handleOpenModal(url)}
                  >
                    <Typography
                      variant="body2"
                      gutterBottom
                      sx={{ color: "#ffffff", textAlign: "center" }}
                    >
                      {key}
                    </Typography>
                    <img
                      src={url}
                      alt={key}
                      style={{
                        width: "100%",
                        height: "auto",
                        borderRadius: "8px",
                        boxShadow: "0 4px 8px rgba(0, 255, 255, 0.2)",
                      }}
                    />
                  </Box>
                </Grid>
              ))}
        </Grid>
      </CardContent>

      <Modal open={openModal} onClose={handleCloseModal}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "80%",
            maxWidth: "800px",
            bgcolor: "#0d0d0d",
            borderRadius: "8px",
            boxShadow: 24,
            p: 4,
            outline: "none",
          }}
        >
          <IconButton
            sx={{
              position: "absolute",
              top: 8,
              right: 8,
              color: "#ffffff",
            }}
            onClick={handleCloseModal}
          >
            <CloseIcon />
          </IconButton>
          <img
            src={selectedImage}
            alt="Selected"
            style={{
              width: "100%",
              height: "auto",
              borderRadius: "8px",
              boxShadow: "0 4px 8px rgba(0, 255, 255, 0.2)",
            }}
          />
        </Box>
      </Modal>

      <Locker
        uid={uid}
        open={lockerOpen}
        onClose={handleLockerClose}
        onSuccess={handleLockerSuccess}
      />
    </Box>
  );
};

export default InspectionDetails;
