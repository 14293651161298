import React, { useState } from "react";
import { db } from "../firebase"; // Asegúrate de importar Firestore desde firebase.js
import { collection, doc, setDoc } from "firebase/firestore";
import {
  Button,
  Typography,
  Card,
  CardContent,
  CircularProgress,
} from "@mui/material";
import Papa from "papaparse"; // Necesitarás instalar papaparse con `npm install papaparse`

const ImportCSV = () => {
  const [csvData, setCsvData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");

  const handleFileUpload = (e) => {
    const file = e.target.files[0];

    if (file) {
      Papa.parse(file, {
        header: true,
        skipEmptyLines: true,
        complete: function (results) {
          setCsvData(results.data);
          setMessage("CSV cargado con éxito! Listo para importar.");
        },
        error: function (error) {
          setMessage(`Error al leer el archivo: ${error.message}`);
        },
      });
    }
  };

  const handleImport = async () => {
    if (csvData.length === 0) {
      setMessage("No hay datos para importar.");
      return;
    }

    setLoading(true);
    setMessage("");

    try {
      const collectionRef = collection(db, "oficiales");

      for (const row of csvData) {
        const newDocRef = doc(collectionRef);
        await setDoc(newDocRef, {
          nombre: row.nombre || "", // Asigna el nombre del CSV al campo 'nombre'
          celular: "", // Campo vacío
          zona: "", // Campo vacío
          tipo: "", // Campo vacío
        });
      }

      setMessage("Datos importados con éxito!");
    } catch (error) {
      console.error("Error al importar los datos:", error);
      setMessage("Error al importar los datos. Intenta nuevamente.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div style={{ padding: 20 }}>
      <Card
        sx={{
          p: 4,
          borderRadius: 4,
          boxShadow: "0 8px 24px rgba(0,0,0,0.12)",
          mb: 4,
        }}
      >
        <CardContent>
          <Typography variant="h5" component="div" gutterBottom>
            Importar Datos desde CSV a Oficiales
          </Typography>
          <input
            accept=".csv"
            style={{ display: "none" }}
            id="upload-csv"
            type="file"
            onChange={handleFileUpload}
          />
          <label htmlFor="upload-csv">
            <Button
              variant="contained"
              color="primary"
              component="span"
              sx={{ marginBottom: 2 }}
            >
              Cargar CSV
            </Button>
          </label>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleImport}
            disabled={loading || csvData.length === 0}
            sx={{ marginBottom: 2 }}
          >
            {loading ? (
              <>
                Importando... <CircularProgress size={24} sx={{ ml: 2 }} />
              </>
            ) : (
              "Importar Datos"
            )}
          </Button>
          {message && (
            <Typography variant="body1" color="text.secondary" sx={{ mt: 2 }}>
              {message}
            </Typography>
          )}
        </CardContent>
      </Card>
    </div>
  );
};

export default ImportCSV;
