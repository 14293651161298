import React, { useState, useEffect } from "react";
import {
  Container,
  Grid,
  Box,
  Typography,
  Paper,
  Button,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Link } from "react-router-dom";
import { db } from "../../firebase";
import { collection, getDocs } from "firebase/firestore";
import {
  Inventory2,
  ContactMail,
  AssignmentTurnedIn,
  Build,
  LocalPolice,
} from "@mui/icons-material";
import AdminMenu from "./AdminMenu";
import InventoryChart from "./InventoryChart";
import InspectionSummary from "./InspectionSummary";

const AdminDashboard = () => {
  const [inventoryCount, setInventoryCount] = useState(0);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    const fetchInventoryData = async () => {
      const querySnapshot = await getDocs(collection(db, "inventory"));
      setInventoryCount(querySnapshot.size);
    };

    fetchInventoryData();
  }, []);

  const menuItems = [
    {
      text: "Unidades",
      icon: <LocalPolice />,
      path: "/buscarunidades",
    },
    {
      text: "Nuevo Inventario",
      icon: <Inventory2 />,
      path: "/nuevoinventario",
    },
    {
      text: "Inspecciones",
      icon: <AssignmentTurnedIn />,
      path: "/buscarinspeccion",
    },
    {
      text: "Nueva Inspección",
      icon: <AssignmentTurnedIn />,
      path: "/nuevainspeccion",
    },
    {
      text: "Buscar Oficial",
      icon: <AssignmentTurnedIn />,
      path: "/buscaroficial",
    },
    { text: "Contactos", icon: <ContactMail />, path: "/contactos" },
    { text: "Garantías", icon: <Build />, path: "/garantias" },
  ];

  return (
    <Box
      sx={{
        bgcolor: "#0d0d0d",
        minHeight: "100vh",
        p: 3,
      }}
    >
      <Container maxWidth="lg">
        <Paper
          sx={{
            p: 4,
            borderRadius: 4,
            boxShadow: "0 8px 24px rgba(0, 255, 255, 0.2)",
            mb: 4,
            backgroundColor: "#1a1a1a",
            color: "#00ffff",
          }}
        >
          <Typography
            variant="h4"
            align="center"
            gutterBottom
            sx={{
              color: "#00ffff",
              borderBottom: "3px solid #00ffff",
              paddingBottom: "12px",
              fontWeight: "bold",
            }}
          >
            Dashboard Admin
          </Typography>
          <Box sx={{ mb: 4 }}>
            <InventoryChart
              inventoryCount={inventoryCount}
              sx={{
                height: isMobile ? "200px" : "300px",
                width: isMobile ? "100%" : "70%",
                mx: "auto",
              }}
            />
          </Box>
          <Box sx={{ mb: 4 }}>
            <InspectionSummary
              sx={{
                height: isMobile ? "200px" : "300px",
                width: isMobile ? "100%" : "70%",
                mx: "auto",
              }}
            />
          </Box>
          <Typography
            variant="h6"
            align="center"
            sx={{ mt: 2, color: "#ffffff" }}
          >
            Productos en Inventario: {inventoryCount}
          </Typography>
        </Paper>

        <Typography
          variant="h5"
          gutterBottom
          align="center"
          sx={{
            my: 4,
            color: "#00ffff",
            borderBottom: "3px solid #00ffff",
            paddingBottom: "12px",
            fontWeight: "bold",
          }}
        >
          Accesos Directos
        </Typography>
        <Grid container spacing={3} justifyContent="center">
          {menuItems.map((item, index) => (
            <Grid item xs={12} sm={6} md={3} key={index}>
              <Button
                component={Link}
                to={item.path}
                variant="contained"
                startIcon={item.icon}
                fullWidth
                sx={{
                  py: 2,
                  px: 2,
                  fontSize: isMobile ? "1rem" : "1.2rem",
                  textTransform: "none",
                  borderRadius: 2,
                  boxShadow: "0 6px 12px rgba(0, 255, 255, 0.3)",
                  "&:hover": {
                    transform: "translateY(-3px)",
                    boxShadow: "0 10px 20px rgba(0, 255, 255, 0.5)",
                  },
                  transition: "all 0.3s ease-in-out",
                  backgroundColor: "#00C49F",
                  color: "#000000",
                  fontWeight: "bold",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  minHeight: "64px",
                }}
              >
                {item.text}
              </Button>
            </Grid>
          ))}
        </Grid>
        <Box sx={{ mt: 6 }}>
          <AdminMenu />
        </Box>
      </Container>
    </Box>
  );
};

export default AdminDashboard;
