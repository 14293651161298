import React, { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  Button,
  Box,
  IconButton,
  CircularProgress,
} from "@mui/material";
import {
  Delete as DeleteIcon,
  AddPhotoAlternate as AddPhotoAlternateIcon,
} from "@mui/icons-material";
import {
  ref,
  uploadBytesResumable,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";
import { db, storage } from "../../../firebase";
import { doc, updateDoc } from "firebase/firestore";

const Step2Fotos = ({
  existingFotos = {},
  handleImageChange,
  handleNext,
  handleBack,
  inspectionId,
}) => {
  const [previews, setPreviews] = useState({});
  const [loadingStates, setLoadingStates] = useState({});
  const [additionalImages, setAdditionalImages] = useState([]);

  useEffect(() => {
    if (existingFotos) {
      setPreviews(existingFotos);
    }
  }, [existingFotos]);

  const handleImageSelect = async (label, e) => {
    const file = e.target.files[0];
    if (file && inspectionId) {
      const storageRef = ref(
        storage,
        `inspecciones/${inspectionId}/${label}.jpg`
      );
      const uploadTask = uploadBytesResumable(storageRef, file);

      setLoadingStates((prev) => ({ ...prev, [label]: true }));

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log(`Subiendo imagen ${label}: ${progress}% hecho`);
        },
        (error) => {
          console.error("Error al subir la imagen:", error);
          setLoadingStates((prev) => ({ ...prev, [label]: false }));
        },
        async () => {
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
          setPreviews((prev) => ({ ...prev, [label]: downloadURL }));
          handleImageChange(label, downloadURL);

          await updateDoc(doc(db, "inspecciones", inspectionId), {
            [`fotos.${label}`]: downloadURL,
          });

          setLoadingStates((prev) => ({ ...prev, [label]: false }));
        }
      );
    }
  };

  const handleRemoveImage = async (label) => {
    setPreviews((prev) => {
      const updatedPreviews = { ...prev };
      delete updatedPreviews[label];
      return updatedPreviews;
    });
    handleImageChange(label, null);

    if (inspectionId) {
      await updateDoc(doc(db, "inspecciones", inspectionId), {
        [`fotos.${label}`]: null,
      });
      const storageRef = ref(
        storage,
        `inspecciones/${inspectionId}/${label}.jpg`
      );
      await deleteObject(storageRef).catch((error) => {
        console.error("Error al eliminar la imagen de Storage:", error);
      });
    }
  };

  const handleAdditionalImagesSelect = (e) => {
    const files = Array.from(e.target.files);
    const newImages = files.map((file, index) => ({
      label: `F${additionalImages.length + index + 1}`,
      preview: URL.createObjectURL(file),
      file,
    }));
    setAdditionalImages((prev) => [...prev, ...newImages]);
    newImages.forEach(({ label, file }) =>
      handleImageSelect(label, { target: { files: [file] } })
    );
  };

  const handleRemoveAdditionalImage = (index) => {
    const imageToRemove = additionalImages[index];
    setAdditionalImages((prev) => prev.filter((_, i) => i !== index));
    handleRemoveImage(imageToRemove.label);
  };

  const renderImageUploadField = (label, placeholder) => (
    <Box
      key={label}
      sx={{
        mt: 2,
        p: 2,
        border: "2px dashed #00ffff",
        borderRadius: 2,
        textAlign: "center",
        position: "relative",
      }}
    >
      <input
        accept="image/*"
        style={{ display: "none" }}
        id={`upload-${label}`}
        type="file"
        onChange={(e) => handleImageSelect(label, e)}
      />
      <label htmlFor={`upload-${label}`}>
        <Box sx={{ display: "flex", justifyContent: "center", mb: 2 }}>
          {loadingStates[label] ? (
            <CircularProgress sx={{ color: "#00ffff" }} />
          ) : (
            <img
              src={previews[label] || placeholder}
              alt={label}
              style={{
                width: "100px",
                height: "100px",
                objectFit: "cover",
                borderRadius: "8px",
                border: previews[label] ? "2px solid #00ffff" : "none",
              }}
            />
          )}
        </Box>
        <Button
          variant="contained"
          color="primary"
          component="span"
          sx={{ mt: 2, backgroundColor: "#00ffff", color: "#000000" }}
        >
          {previews[label] ? "Cambiar Imagen" : `Subir Imagen`}
        </Button>
      </label>
      {previews[label] && !loadingStates[label] && (
        <IconButton
          onClick={() => handleRemoveImage(label)}
          sx={{
            position: "absolute",
            top: 8,
            right: 8,
            color: "#ff0000",
          }}
        >
          <DeleteIcon />
        </IconButton>
      )}
    </Box>
  );

  return (
    <>
      <Typography variant="h6" sx={{ color: "#00ffff", mb: 2 }}>
        Subir Fotos
      </Typography>
      <Grid container spacing={2}>
        {[
          {
            label: "Foto unidad",
            placeholder:
              "https://firebasestorage.googleapis.com/v0/b/radiosistemas-a46cd.appspot.com/o/Fotos%20form%20Unidad%2Ffrontal.png?alt=media&token=5b67d9ed-40da-41e7-9112-b9a7038e711e",
          },
          {
            label: "Derecha",
            placeholder:
              "https://firebasestorage.googleapis.com/v0/b/radiosistemas-a46cd.appspot.com/o/Fotos%20form%20Unidad%2FDerecha.png?alt=media&token=cf40c813-f2ed-4bab-acd2-20a6027f79f2",
          },
          {
            label: "Trasera",
            placeholder:
              "https://firebasestorage.googleapis.com/v0/b/radiosistemas-a46cd.appspot.com/o/Fotos%20form%20Unidad%2FTrasera.png?alt=media&token=fbf8a72d-0672-4310-8505-1838f333f67d",
          },
          {
            label: "Techo",
            placeholder:
              "https://firebasestorage.googleapis.com/v0/b/radiosistemas-a46cd.appspot.com/o/Fotos%20form%20Unidad%2FTecho.png?alt=media&token=ca7824b7-e021-4779-a971-911d460a9a06",
          },
          {
            label: "Izquierda",
            placeholder:
              "https://firebasestorage.googleapis.com/v0/b/radiosistemas-a46cd.appspot.com/o/Fotos%20form%20Unidad%2FIzquierda.png?alt=media&token=fe7ad6c7-4c8f-4cce-a6f1-6ee3f3ebc417",
          },
        ].map(({ label, placeholder }) => (
          <Grid item xs={12} sm={6} key={label}>
            {renderImageUploadField(label, placeholder)}
          </Grid>
        ))}

        {additionalImages.map(({ label, preview }, index) => (
          <Grid item xs={12} sm={6} key={label}>
            <Box
              sx={{
                mt: 2,
                p: 2,
                border: "2px dashed #00ffff",
                borderRadius: 2,
                textAlign: "center",
                position: "relative",
              }}
            >
              <img
                src={preview}
                alt={label}
                style={{
                  width: "100px",
                  height: "100px",
                  objectFit: "cover",
                  borderRadius: "8px",
                  border: "2px solid #00ffff",
                }}
              />
              <Typography variant="caption" sx={{ color: "#ffffff", mt: 1 }}>
                {label}
              </Typography>
              <IconButton
                onClick={() => handleRemoveAdditionalImage(index)}
                sx={{
                  position: "absolute",
                  top: 8,
                  right: 8,
                  color: "#ff0000",
                }}
              >
                <DeleteIcon />
              </IconButton>
            </Box>
          </Grid>
        ))}
      </Grid>
      <Box sx={{ mt: 4, textAlign: "center" }}>
        <Button
          variant="contained"
          component="label"
          color="secondary"
          sx={{
            p: 2,
            backgroundColor: "#00ffff",
            color: "#000000",
            fontSize: "1rem",
            "&:hover": {
              backgroundColor: "#00C49F",
            },
          }}
          startIcon={<AddPhotoAlternateIcon />}
        >
          Más Imágenes
          <input
            accept="image/*"
            type="file"
            multiple
            hidden
            onChange={handleAdditionalImagesSelect}
          />
        </Button>
      </Box>
      <Box sx={{ mt: 4, textAlign: "center" }}>
        <Button
          variant="contained"
          color="secondary"
          onClick={handleBack}
          sx={{ mr: 2 }}
        >
          Anterior
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleNext}
          sx={{
            backgroundColor: "#00C49F",
            color: "#000000",
            "&:hover": {
              backgroundColor: "#009688",
            },
          }}
        >
          Siguiente
        </Button>
      </Box>
    </>
  );
};

export default Step2Fotos;
