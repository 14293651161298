import React from "react";
import { Grid, Paper, IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

const GaleriaImagenes = ({
  imagenes,
  previewUrls,
  setImagenes,
  setPreviewUrls,
}) => {
  const handleImageDelete = (index) => {
    setPreviewUrls((prev) => prev.filter((_, i) => i !== index));
    setImagenes((prev) => prev.filter((_, i) => i !== index));
  };

  return (
    <>
      {previewUrls.length > 0 && (
        <Grid container spacing={2} sx={{ marginBottom: 2 }}>
          {previewUrls.map((url, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Paper
                elevation={3}
                sx={{ position: "relative", borderRadius: 2 }}
              >
                <img
                  src={url}
                  alt={`Preview ${index + 1}`}
                  style={{
                    width: "100%",
                    height: "200px",
                    objectFit: "cover",
                    borderRadius: "4px 4px 0 0",
                  }}
                />
                <IconButton
                  aria-label="delete"
                  sx={{ position: "absolute", top: 10, right: 10 }}
                  onClick={() => handleImageDelete(index)}
                >
                  <DeleteIcon sx={{ color: "red" }} />
                </IconButton>
              </Paper>
            </Grid>
          ))}
        </Grid>
      )}
    </>
  );
};

export default GaleriaImagenes;
