import React, { useState, useEffect } from "react";
import { db, auth } from "../firebase"; // Asegúrate de importar Firestore y Auth desde firebase.js
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { onAuthStateChanged } from "firebase/auth";
import {
  Box,
  Typography,
  Button,
  Grid,
  IconButton,
  Modal,
  TextField,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import BackspaceIcon from "@mui/icons-material/Backspace";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

const Locker = ({ open, onClose, onSuccess }) => {
  const [enteredPass, setEnteredPass] = useState("");
  const [error, setError] = useState("");
  const [attempts, setAttempts] = useState(0);
  const [lockerPass, setLockerPass] = useState("");
  const [uid, setUid] = useState("");
  const [shuffledNumbers, setShuffledNumbers] = useState([]);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUid(user.uid);
      } else {
        setError("No hay usuario autenticado.");
      }
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const fetchLockerPass = async () => {
      try {
        if (uid) {
          const userRef = doc(db, "Users", uid);
          const userSnap = await getDoc(userRef);

          if (userSnap.exists()) {
            setLockerPass(userSnap.data().LockerPass);
          } else {
            setError("Usuario no encontrado.");
          }
        }
      } catch (err) {
        setError("Error al obtener la contraseña.");
      }
    };

    if (uid) {
      fetchLockerPass();
    }
  }, [uid]);

  useEffect(() => {
    const numbers = [1, 2, 3, 4, 5, 6, 7, 8, 9];
    setShuffledNumbers(shuffleArray(numbers));
  }, [open]);

  const shuffleArray = (array) => {
    return array.sort(() => Math.random() - 0.5);
  };

  const handleKeyPress = (number) => {
    setEnteredPass((prev) => prev + number);
  };

  const handleBackspace = () => {
    setEnteredPass((prev) => prev.slice(0, -1));
  };

  const handleSubmit = async () => {
    if (!lockerPass) {
      setError("No se pudo obtener la contraseña.");
      return;
    }

    if (enteredPass === lockerPass) {
      onSuccess();
      onClose();
    } else {
      const newAttempts = attempts + 1;
      setAttempts(newAttempts);
      setError("Contraseña incorrecta");

      if (newAttempts >= 5) {
        try {
          const userRef = doc(db, "Users", uid);
          await updateDoc(userRef, { accountLocked: true });
          setError("Cuenta bloqueada por demasiados intentos fallidos.");
          setTimeout(() => {
            onClose();
          }, 3000);
        } catch (err) {
          setError("Error al bloquear la cuenta.");
        }
      }
    }
  };

  const handleClose = () => {
    setEnteredPass("");
    setError("");
    setAttempts(0);
    onClose();
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "90%",
          maxWidth: "400px",
          bgcolor: "#0d0d0d",
          borderRadius: "16px",
          boxShadow: "0 8px 24px rgba(0, 255, 255, 0.3)",
          p: 4,
          outline: "none",
        }}
      >
        <IconButton
          sx={{
            position: "absolute",
            top: 8,
            right: 8,
            color: "#ffffff",
          }}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
        {uid && (
          <Typography
            variant="body2"
            align="center"
            gutterBottom
            sx={{
              color: "#00ffff",
              fontWeight: "bold",
              mb: 2,
              borderBottom: "2px solid #00ffff",
              paddingBottom: "8px",
              fontSize: "0.875rem",
              wordBreak: "break-all",
            }}
          >
            UID: {uid}
          </Typography>
        )}
        <Typography
          variant="h5"
          align="center"
          gutterBottom
          sx={{
            color: "#00ffff",
            fontWeight: "bold",
            mb: 3,
          }}
        >
          Ingrese su Contraseña
        </Typography>
        <TextField
          value={enteredPass}
          onChange={(e) => setEnteredPass(e.target.value)}
          variant="outlined"
          fullWidth
          type="password"
          inputProps={{
            style: {
              color: "#ffffff",
              textAlign: "center",
              letterSpacing: 4,
            },
          }}
          sx={{
            mb: 2,
            input: { color: "#ffffff", textAlign: "center", letterSpacing: 4 },
            fieldset: { borderColor: "#00ffff", borderRadius: "8px" },
          }}
        />
        {error && (
          <Typography color="error" align="center" gutterBottom>
            {error}
          </Typography>
        )}
        <Grid container spacing={2} justifyContent="center">
          {shuffledNumbers.map((number, index) => (
            <Grid item xs={4} key={index}>
              <Button
                variant="contained"
                onClick={() => handleKeyPress(number.toString())}
                sx={{
                  width: "100%",
                  height: "60px",
                  bgcolor: "#1a1a1a",
                  color: "#00ffff",
                  fontSize: "1.5rem",
                  borderRadius: "8px",
                  "&:hover": {
                    bgcolor: "#333333",
                  },
                }}
              >
                {number}
              </Button>
            </Grid>
          ))}
          <Grid item xs={4}>
            <IconButton
              onClick={handleBackspace}
              sx={{
                width: "100%",
                height: "60px",
                bgcolor: "#333333",
                color: "#ffffff",
                borderRadius: "8px",
                "&:hover": {
                  bgcolor: "#555555",
                },
              }}
            >
              <BackspaceIcon sx={{ fontSize: "1.5rem" }} />
            </IconButton>
          </Grid>
          <Grid item xs={4}>
            <Button
              variant="contained"
              onClick={() => handleKeyPress("0")}
              sx={{
                width: "100%",
                height: "60px",
                bgcolor: "#1a1a1a",
                color: "#00ffff",
                fontSize: "1.5rem",
                borderRadius: "8px",
                "&:hover": {
                  bgcolor: "#333333",
                },
              }}
            >
              0
            </Button>
          </Grid>
          <Grid item xs={4}>
            <IconButton
              onClick={handleSubmit}
              sx={{
                width: "100%",
                height: "60px",
                bgcolor: "#00ffff",
                color: "#0d0d0d",
                borderRadius: "8px",
                "&:hover": {
                  bgcolor: "#33ffff",
                },
              }}
            >
              <ArrowForwardIcon sx={{ fontSize: "1.5rem" }} />
            </IconButton>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default Locker;
