import React, { useState } from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Paper,
  Snackbar,
  CircularProgress,
} from "@mui/material";
import { styled } from "@mui/system";
import emailjs from "emailjs-com";
import { motion, AnimatePresence } from "framer-motion";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

const ContactContainer = styled(Box)(({ theme }) => ({
  padding: "6rem 2rem",
  background: "linear-gradient(135deg, #e0f7fa 0%, #80deea 100%)",
  textAlign: "center",
}));

const FormPaper = styled(Paper)(({ theme }) => ({
  maxWidth: "600px",
  margin: "0 auto",
  padding: "3rem",
  boxShadow: "0 10px 30px rgba(0, 0, 0, 0.1)",
  borderRadius: "20px",
  background: "rgba(255, 255, 255, 0.9)",
  backdropFilter: "blur(10px)",
}));

const StyledButton = styled(Button)(({ theme }) => ({
  marginTop: "2rem",
  padding: "1rem 3rem",
  fontSize: "1rem",
  borderRadius: "50px",
  boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
  transition: "all 0.3s ease",
  "&:hover": {
    transform: "translateY(-3px)",
    boxShadow: "0 6px 8px rgba(0, 0, 0, 0.15)",
  },
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  "& .MuiOutlinedInput-root": {
    borderRadius: "15px",
  },
}));

const SuccessAnimation = () => (
  <motion.div
    initial={{ scale: 0 }}
    animate={{ scale: 1 }}
    exit={{ scale: 0 }}
    transition={{ duration: 0.5, type: "spring" }}
  >
    <CheckCircleOutlineIcon color="success" style={{ fontSize: 60 }} />
  </motion.div>
);

const Contact = () => {
  const [service, setService] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);

  const handleServiceChange = (event) => {
    setService(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);

    try {
      // Enviar el formulario al correo de administración
      await emailjs.sendForm(
        "service_nntdh2l",
        "template_uhmwxcz",
        event.target,
        "YBNR2ke3iLef3YK4o"
      );

      // Enviar un correo de confirmación al usuario
      await emailjs.send(
        "service_nntdh2l",
        "template_4bi7xbp",
        {
          user_name: event.target.user_name.value,
          user_email: event.target.user_email.value,
          service: service,
        },
        "YBNR2ke3iLef3YK4o"
      );

      setShowSuccess(true);
      event.target.reset();
      setService("");
    } catch (error) {
      console.error("Error al enviar el correo:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <ContactContainer id="contact">
      <Typography
        variant="h3"
        gutterBottom
        sx={{ fontWeight: "bold", marginBottom: "2rem" }}
      >
        Contáctanos
      </Typography>
      <FormPaper elevation={3}>
        <form noValidate autoComplete="off" onSubmit={handleSubmit}>
          <StyledTextField
            name="user_name"
            label="Nombre"
            variant="outlined"
            fullWidth
            margin="normal"
            required
          />
          <StyledTextField
            name="user_email"
            label="Correo Electrónico"
            variant="outlined"
            fullWidth
            margin="normal"
            required
          />
          <StyledTextField
            name="user_phone"
            label="Número de Teléfono"
            variant="outlined"
            fullWidth
            margin="normal"
            required
          />
          <FormControl fullWidth margin="normal" required>
            <InputLabel id="service-label">Servicio</InputLabel>
            <Select
              labelId="service-label"
              name="service"
              value={service}
              onChange={handleServiceChange}
              variant="outlined"
              sx={{ borderRadius: "15px" }}
            >
              <MenuItem value="Radio comunicación">Radio comunicación</MenuItem>
              <MenuItem value="Seguridad Privada">Seguridad Privada</MenuItem>
              <MenuItem value="Seguridad Publica">Seguridad Publica</MenuItem>
              <MenuItem value="Busqueda y Rescate">Busqueda y Rescate</MenuItem>
              <MenuItem value="Venta de Drones y Accesorios">
                Venta de Drones y Accesorios
              </MenuItem>
              <MenuItem value="Equipamiento de Vehículos">
                Equipamiento de Vehículos
              </MenuItem>
              <MenuItem value="Puntos de repeticion">
                Puntos de repeticion
              </MenuItem>
              <MenuItem value="Venta de Equipos">Venta de Equipos</MenuItem>
            </Select>
          </FormControl>
          <StyledTextField
            name="message"
            label="Mensaje"
            variant="outlined"
            fullWidth
            margin="normal"
            multiline
            rows={4}
            required
          />
          <StyledButton
            type="submit"
            variant="contained"
            color="primary"
            disabled={isSubmitting}
          >
            {isSubmitting ? <CircularProgress size={24} /> : "Enviar"}
          </StyledButton>
        </form>
      </FormPaper>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={showSuccess}
        autoHideDuration={3000}
        onClose={() => setShowSuccess(false)}
      >
        <Box
          sx={{
            bgcolor: "success.main",
            color: "white",
            p: 2,
            borderRadius: 2,
          }}
        >
          <AnimatePresence>
            {showSuccess && <SuccessAnimation />}
          </AnimatePresence>
          <Typography>¡Mensaje enviado con éxito!</Typography>
        </Box>
      </Snackbar>
    </ContactContainer>
  );
};

export default Contact;
