import React, { useState, useEffect } from "react";
import {
  Container,
  Typography,
  Grid,
  Paper,
  Box,
  Link as MuiLink,
  Divider,
} from "@mui/material";
import { useUserAuth } from "../../context/userAuthContext";
import {
  getFirestore,
  collection,
  query,
  where,
  getDocs,
} from "firebase/firestore";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import { Link } from "react-router-dom";

const mapContainerStyle = {
  width: "100%",
  height: "200px",
};

const MyProjects = () => {
  const { user } = useUserAuth();
  const [projects, setProjects] = useState([]);

  useEffect(() => {
    const fetchProjects = async () => {
      if (user && user.email) {
        try {
          const firestore = getFirestore();
          const projectsRef = collection(firestore, "Projects");
          const q = query(
            projectsRef,
            where("clients", "array-contains", user.email)
          );
          const querySnapshot = await getDocs(q);

          const userProjects = querySnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));

          setProjects(userProjects);
        } catch (error) {
          console.error("Error fetching projects: ", error);
        }
      }
    };

    fetchProjects();
  }, [user]);

  return (
    <Container maxWidth="md" sx={{ padding: 4 }}>
      <Typography variant="h4" gutterBottom>
        Mis Proyectos
      </Typography>
      <Grid container spacing={4}>
        {projects.map((project) => (
          <Grid item xs={12} key={project.id}>
            <Paper elevation={3} sx={{ padding: 3 }}>
              <Link
                to={`/project/${project.id}`}
                style={{ textDecoration: "none" }}
              >
                <Typography
                  variant="h5"
                  sx={{ fontWeight: "bold", color: "#2474ab" }}
                >
                  {project.storeName}
                </Typography>
              </Link>
              <Divider sx={{ my: 2 }} />
              <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                Tipo de Proyecto:
              </Typography>
              <Typography variant="body2" sx={{ mb: 2 }}>
                {project.projectType}
              </Typography>
              <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                Dirección:
              </Typography>
              <Typography variant="body2" sx={{ mb: 2 }}>
                {project.address}
              </Typography>
              <Typography variant="body2" sx={{ mb: 2 }}>
                {project.description}
              </Typography>
              <Box sx={{ marginTop: 2 }}>
                <img
                  src={project.photo}
                  alt={project.storeName}
                  style={{ width: "100%", height: "auto", borderRadius: 8 }}
                />
              </Box>
              <Box sx={{ marginTop: 2 }}>
                <LoadScript googleMapsApiKey="AIzaSyBSXE8zZ-SqMWdDz16hr00Djvkm3mMit28">
                  <GoogleMap
                    mapContainerStyle={mapContainerStyle}
                    center={{
                      lat: parseFloat(project.latitude),
                      lng: parseFloat(project.longitude),
                    }}
                    zoom={15}
                  >
                    <Marker
                      position={{
                        lat: parseFloat(project.latitude),
                        lng: parseFloat(project.longitude),
                      }}
                    />
                  </GoogleMap>
                </LoadScript>
              </Box>
            </Paper>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default MyProjects;
