import React from "react";
import { Container, Typography, Button, Grid, Paper, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import ListIcon from "@mui/icons-material/List";

const ProjectDashboard = () => {
  const navigate = useNavigate();

  const handleNewQuote = () => {
    navigate("/cotizacion");
  };

  const handleMyProjects = () => {
    navigate("/myprojects");
  };

  return (
    <Container
      maxWidth="md"
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "100vh",
        padding: 4,
        backgroundColor: "#f5f5f5",
      }}
    >
      <Typography variant="h3" gutterBottom>
        ¿Qué te gustaría hacer?
      </Typography>
      <Grid container spacing={3} justifyContent="center">
        <Grid item xs={12} sm={6}>
          <Paper elevation={3} sx={{ padding: 2 }}>
            <Button
              startIcon={<AddIcon />}
              onClick={handleNewQuote}
              fullWidth
              variant="contained"
              sx={{
                backgroundColor: "#2474ab",
                color: "#fff",
                fontSize: "1.2rem",
                padding: 2,
                "&:hover": {
                  backgroundColor: "#1e5f91",
                },
              }}
            >
              Cotizar nuevo proyecto
            </Button>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Paper elevation={3} sx={{ padding: 2 }}>
            <Button
              startIcon={<ListIcon />}
              onClick={handleMyProjects}
              fullWidth
              variant="contained"
              sx={{
                backgroundColor: "#2474ab",
                color: "#fff",
                fontSize: "1.2rem",
                padding: 2,
                "&:hover": {
                  backgroundColor: "#1e5f91",
                },
              }}
            >
              Mis Proyectos
            </Button>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};

export default ProjectDashboard;
