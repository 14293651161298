import React, { useEffect, useState } from "react";
import {
  Container,
  Paper,
  Typography,
  CircularProgress,
  Box,
} from "@mui/material";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../firebase";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import dayjs from "dayjs";

const InspectionSummary = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchInspections = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "inspecciones"));
        const inspections = querySnapshot.docs.map((doc) => doc.data());

        const dateGroupedData = inspections.reduce((acc, inspection) => {
          const date = dayjs(inspection.Date).format("YYYY-MM-DD");
          if (!acc[date]) {
            acc[date] = { date, Completas: 0, Incompletas: 0 };
          }
          if (inspection.Status === "Correcto") {
            acc[date].Completas += 1;
          } else if (inspection.Status === "Incompleto") {
            acc[date].Incompletas += 1;
          }
          return acc;
        }, {});

        const formattedData = Object.values(dateGroupedData).sort((a, b) =>
          dayjs(a.date).isBefore(dayjs(b.date)) ? -1 : 1
        );

        setData(formattedData);
      } catch (error) {
        console.error("Error al obtener las inspecciones:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchInspections();
  }, []);

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
        bgcolor="#0d0d0d"
      >
        <CircularProgress color="secondary" />
      </Box>
    );
  }

  return (
    <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
      <Paper
        sx={{
          p: 4,
          borderRadius: 4,
          boxShadow: "0 8px 24px rgba(0, 255, 255, 0.2)",
          backgroundColor: "#121212",
          color: "#E0E0E0",
        }}
      >
        <Typography
          variant="h4"
          align="center"
          gutterBottom
          sx={{
            color: "#00ffff",
            borderBottom: "3px solid #00ffff",
            paddingBottom: "12px",
            fontWeight: "bold",
          }}
        >
          Progreso de Inspecciones
        </Typography>

        <ResponsiveContainer width="100%" height={400}>
          <LineChart data={data}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey="date"
              tickFormatter={(date) => dayjs(date).format("DD MMM")}
            />
            <YAxis />
            <Tooltip />
            <Legend />
            <Line
              type="monotone"
              dataKey="Completas"
              stroke="#00C49F"
              activeDot={{ r: 8 }}
            />
            <Line type="monotone" dataKey="Incompletas" stroke="#FF8042" />
          </LineChart>
        </ResponsiveContainer>
      </Paper>
    </Container>
  );
};

export default InspectionSummary;
