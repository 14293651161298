import React, { useState, useEffect } from "react";
import { db } from "../../firebase"; // Importa Firestore desde firebase.js
import {
  collection,
  doc,
  getDocs,
  setDoc,
  updateDoc,
  query,
  orderBy,
  limit,
} from "firebase/firestore";
import {
  TextField,
  Button,
  Card,
  CardContent,
  Typography,
  Grid,
  Paper,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
} from "@mui/material";

const NuevaCategoria = () => {
  const [categoria, setCategoria] = useState("");
  const [subCategoria, setSubCategoria] = useState("");
  const [subCategorias, setSubCategorias] = useState([]);
  const [message, setMessage] = useState("");
  const [nextId, setNextId] = useState(1);
  const [categoriasExistentes, setCategoriasExistentes] = useState([]);
  const [categoriaSeleccionada, setCategoriaSeleccionada] = useState("");

  useEffect(() => {
    const fetchCategorias = async () => {
      try {
        const q = query(collection(db, "categorias"), orderBy("id", "asc"));
        const querySnapshot = await getDocs(q);

        const categorias = querySnapshot.docs.map((doc) => ({
          id: doc.data().id,
          nombre: doc.data().nombre,
          subCategorias: doc.data().subCategorias || [],
        }));

        setCategoriasExistentes(categorias);

        if (categorias.length > 0) {
          setNextId(categorias[categorias.length - 1].id + 1);
        }
      } catch (error) {
        console.error("Error al obtener las categorías:", error);
      }
    };

    fetchCategorias();
  }, []);

  const handleAddCategoria = async () => {
    if (!categoria.trim() && !categoriaSeleccionada) {
      setMessage("El nombre de la categoría es obligatorio.");
      return;
    }

    try {
      if (categoriaSeleccionada) {
        // Actualizar la categoría existente
        const categoriaDocRef = doc(db, "categorias", categoriaSeleccionada);
        const categoriaSeleccionadaDoc = categoriasExistentes.find(
          (cat) => cat.id === parseInt(categoriaSeleccionada)
        );

        await updateDoc(categoriaDocRef, {
          subCategorias: [
            ...categoriaSeleccionadaDoc.subCategorias,
            ...subCategorias,
          ],
        });

        setMessage(
          "Subcategorías agregadas a la categoría existente con éxito!"
        );
      } else {
        // Crear una nueva categoría
        const newDocRef = doc(db, "categorias", nextId.toString());

        await setDoc(newDocRef, {
          id: nextId,
          nombre: categoria,
          subCategorias,
        });

        setMessage("Categoría agregada con éxito!");
        setNextId(nextId + 1);
      }

      setCategoria("");
      setSubCategoria("");
      setSubCategorias([]);
      setCategoriaSeleccionada("");
    } catch (error) {
      console.error("Error al agregar la categoría:", error);
      setMessage("Error al agregar la categoría. Intenta nuevamente.");
    }
  };

  const handleAddSubCategoria = () => {
    if (subCategoria.trim()) {
      setSubCategorias((prev) => [...prev, subCategoria]);
      setSubCategoria("");
    }
  };

  const handleRemoveSubCategoria = (index) => {
    setSubCategorias((prev) => prev.filter((_, i) => i !== index));
  };

  return (
    <div style={{ padding: 20 }}>
      <Card>
        <CardContent>
          <Typography variant="h5" component="div" gutterBottom>
            Crear Nueva Categoría o Agregar Subcategorías
          </Typography>
          <FormControl fullWidth style={{ marginBottom: 20 }}>
            <InputLabel id="categoria-select-label">
              Seleccionar Categoría
            </InputLabel>
            <Select
              labelId="categoria-select-label"
              value={categoriaSeleccionada}
              onChange={(e) => setCategoriaSeleccionada(e.target.value)}
              label="Seleccionar Categoría"
            >
              <MenuItem value="">
                <em>Nueva Categoría</em>
              </MenuItem>
              {categoriasExistentes.map((cat) => (
                <MenuItem key={cat.id} value={cat.id.toString()}>
                  {cat.nombre}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {!categoriaSeleccionada && (
            <TextField
              fullWidth
              label="Nombre de la Categoría"
              variant="outlined"
              value={categoria}
              onChange={(e) => setCategoria(e.target.value)}
              style={{ marginBottom: 20 }}
              required
            />
          )}
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Nombre de la Subcategoría"
                variant="outlined"
                value={subCategoria}
                onChange={(e) => setSubCategoria(e.target.value)}
                style={{ marginBottom: 20 }}
              />
              <Button
                variant="contained"
                color="secondary"
                onClick={handleAddSubCategoria}
                style={{ marginBottom: 20 }}
              >
                Agregar Subcategoría
              </Button>
            </Grid>
          </Grid>
          {subCategorias.length > 0 && (
            <div style={{ marginBottom: 20 }}>
              <Typography variant="h6">Subcategorías:</Typography>
              <Grid container spacing={2}>
                {subCategorias.map((subCat, index) => (
                  <Grid item xs={12} sm={6} md={4} key={index}>
                    <Paper
                      elevation={3}
                      style={{ padding: 10, position: "relative" }}
                    >
                      <Typography variant="body1">{subCat}</Typography>
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => handleRemoveSubCategoria(index)}
                        style={{
                          position: "absolute",
                          top: 5,
                          right: 5,
                        }}
                      >
                        Eliminar
                      </Button>
                    </Paper>
                  </Grid>
                ))}
              </Grid>
            </div>
          )}
          <Button
            variant="contained"
            color="primary"
            onClick={handleAddCategoria}
          >
            {categoriaSeleccionada
              ? "Agregar Subcategorías"
              : "Crear Categoría"}
          </Button>
          {message && (
            <Typography
              variant="body1"
              color="text.secondary"
              style={{ marginTop: 20 }}
            >
              {message}
            </Typography>
          )}
        </CardContent>
      </Card>
    </div>
  );
};

export default NuevaCategoria;
