import React from "react";
import Slider from "react-slick";
import { Box, Typography, useMediaQuery } from "@mui/material";
import { styled } from "@mui/system";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const theme = createTheme({
  typography: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    h2: {
      fontSize: "2.5rem",
      fontWeight: 700,
      marginBottom: "2rem",
      color: "#333",
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
});

const partnerLogos = [
  "https://logowik.com/content/uploads/images/dji3302.logowik.com.webp",
  "https://firebasestorage.googleapis.com/v0/b/radiosistemas-a46cd.appspot.com/o/Kenwood_Logo.svg.png?alt=media&token=3d201271-f254-40aa-9a34-47b9d39ea926",
  "https://firebasestorage.googleapis.com/v0/b/radiosistemas-a46cd.appspot.com/o/Airbus_Logo_2017.svg.png?alt=media&token=d9f5d0fc-adbf-4f6e-9402-2c9d3f93adf8",
  "https://firebasestorage.googleapis.com/v0/b/radiosistemas-a46cd.appspot.com/o/hikvision-logo.png?alt=media&token=effa282c-314c-4dd5-b243-c142522f6316",
  "https://firebasestorage.googleapis.com/v0/b/radiosistemas-a46cd.appspot.com/o/unnamed.jpg?alt=media&token=263d2bc5-282c-43e3-b636-a7101f6458bb",
  "https://firebasestorage.googleapis.com/v0/b/radiosistemas-a46cd.appspot.com/o/LOG_DE0_41197_61861_165544815126313300_aeid_60__73_atomfeld_60_73-169314511655448164.png.png?alt=media&token=966d17ca-0fd9-49e0-88dc-ece95eaaab5c",
];

const PartnerSliderContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(8, 2),
  background: "linear-gradient(45deg, #f3f4f6 30%, #e5e7eb 90%)",
  borderRadius: "16px",
  boxShadow: "0 8px 32px rgba(0, 0, 0, 0.1)",
}));

const LogoContainer = styled(Box)(({ theme }) => ({
  display: "flex !important",
  justifyContent: "center",
  alignItems: "center",
  padding: theme.spacing(2),
}));

const LogoBox = styled(Box)(({ theme }) => ({
  width: "140px",
  height: "140px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "16px",
  backgroundColor: "white",
  boxShadow: "0 6px 12px rgba(0, 0, 0, 0.08)",
  overflow: "hidden",
  transition: "all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275)",
  "&:hover": {
    transform: "scale(1.08)",
    boxShadow: "0 12px 24px rgba(0, 0, 0, 0.12)",
  },
  img: {
    maxWidth: "90%",
    maxHeight: "90%",
    objectFit: "contain",
    transition: "all 0.4s ease-in-out",
  },
  "&:hover img": {
    transform: "scale(1.1)",
  },
}));

const PartnerSlider = () => {
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const settings = {
    dots: true,
    infinite: true,
    speed: 800,
    slidesToShow: isMobile ? 2 : 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    cssEase: "cubic-bezier(0.645, 0.045, 0.355, 1)",
    arrows: !isMobile,
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          arrows: false,
        },
      },
    ],
  };

  return (
    <ThemeProvider theme={theme}>
      <PartnerSliderContainer>
        <Typography variant="h2" align="center" gutterBottom>
          Alianzas Estratégicas
        </Typography>
        <Slider {...settings}>
          {partnerLogos.map((logo, index) => (
            <LogoContainer key={index}>
              <LogoBox>
                <img src={logo} alt={`Partner logo ${index + 1}`} />
              </LogoBox>
            </LogoContainer>
          ))}
        </Slider>
      </PartnerSliderContainer>
    </ThemeProvider>
  );
};

export default PartnerSlider;
