import React, { useEffect, useState } from "react";
import { db } from "../../firebase";
import { collection, query, where, getDocs } from "firebase/firestore";
import {
  Box,
  Container,
  Typography,
  Grid,
  Card,
  CardContent,
  CardMedia,
  Button,
  Pagination,
  MenuItem,
  FormControl,
  Select,
} from "@mui/material";
import { Link } from "react-router-dom";

const RadiosConvencionales = () => {
  const [productos, setProductos] = useState([]);
  const [paginaActual, setPaginaActual] = useState(1);
  const [filtroNvl1, setFiltroNvl1] = useState("");
  const [filtroNvl2, setFiltroNvl2] = useState("");
  const [filtroNvl3, setFiltroNvl3] = useState("");
  const productosPorPagina = 10;

  useEffect(() => {
    const fetchProductos = async () => {
      try {
        const q = query(
          collection(db, "products"),
          where("Marca", "==", "KENWOOD")
        );
        const querySnapshot = await getDocs(q);
        const productosData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setProductos(productosData);
      } catch (error) {
        console.error("Error fetching products: ", error);
      }
    };

    fetchProductos();
  }, []);

  // Obtener los valores únicos de cada menú
  const obtenerValoresUnicos = (productos, menu) => {
    return [...new Set(productos.map((producto) => producto[menu]))].filter(
      (item) => item !== undefined && item !== ""
    );
  };

  const valoresNvl1 = obtenerValoresUnicos(productos, "Menu Nvl 1");
  const valoresNvl2 = obtenerValoresUnicos(productos, "Menu Nvl 2");
  const valoresNvl3 = obtenerValoresUnicos(productos, "Menu Nvl 3");

  const filtrarProductos = (productos) => {
    return productos.filter((producto) => {
      return (
        (filtroNvl1 === "" || producto["Menu Nvl 1"] === filtroNvl1) &&
        (filtroNvl2 === "" || producto["Menu Nvl 2"] === filtroNvl2) &&
        (filtroNvl3 === "" || producto["Menu Nvl 3"] === filtroNvl3)
      );
    });
  };

  const productosFiltrados = filtrarProductos(productos);
  const indexOfLastProduct = paginaActual * productosPorPagina;
  const indexOfFirstProduct = indexOfLastProduct - productosPorPagina;
  const productosActuales = productosFiltrados.slice(
    indexOfFirstProduct,
    indexOfLastProduct
  );

  const totalPaginas = Math.ceil(
    productosFiltrados.length / productosPorPagina
  );

  const handleChangePagina = (event, value) => {
    setPaginaActual(value);
  };

  return (
    <Box sx={{ py: 8, backgroundColor: "background.paper" }}>
      <Container maxWidth="lg">
        <Typography
          variant="h4"
          align="center"
          gutterBottom
          sx={{ mb: 4, fontWeight: "bold" }}
        >
          Radios Convencionales
        </Typography>

        {/* Filtros */}
        <Box sx={{ display: "flex", justifyContent: "space-between", mb: 4 }}>
          <FormControl sx={{ minWidth: 200 }}>
            <Select
              value={filtroNvl1}
              onChange={(e) => setFiltroNvl1(e.target.value)}
              displayEmpty
            >
              <MenuItem value="">
                <em>Menu Nvl 1</em>
              </MenuItem>
              {valoresNvl1.map((valor, index) => (
                <MenuItem key={index} value={valor}>
                  {valor}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl sx={{ minWidth: 200 }}>
            <Select
              value={filtroNvl2}
              onChange={(e) => setFiltroNvl2(e.target.value)}
              displayEmpty
            >
              <MenuItem value="">
                <em>Menu Nvl 2</em>
              </MenuItem>
              {valoresNvl2.map((valor, index) => (
                <MenuItem key={index} value={valor}>
                  {valor}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl sx={{ minWidth: 300 }}>
            <Select
              value={filtroNvl3}
              onChange={(e) => setFiltroNvl3(e.target.value)}
              displayEmpty
            >
              <MenuItem value="">
                <em>Menu Nvl 3</em>
              </MenuItem>
              {valoresNvl3.map((valor, index) => (
                <MenuItem key={index} value={valor}>
                  {valor}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>

        <Grid container spacing={4}>
          {productosActuales.length > 0 ? (
            productosActuales.map((producto) => (
              <Grid item key={producto.id} xs={12} sm={6} md={4}>
                <Card>
                  <CardMedia
                    component="img"
                    height="200"
                    image={
                      producto["Imagen Principal"] ||
                      "https://via.placeholder.com/200"
                    }
                    alt={producto.Título}
                  />
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                      {producto.Título}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      Modelo: {producto.Modelo}
                    </Typography>
                    <Typography
                      variant="h6"
                      color="text.primary"
                      sx={{ mt: 2 }}
                    >
                      ${producto.precio}
                    </Typography>
                  </CardContent>
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    sx={{ borderRadius: 0 }}
                    component={Link}
                    to={`/producto/${producto.id}`}
                  >
                    Ver Detalles
                  </Button>
                </Card>
              </Grid>
            ))
          ) : (
            <Typography
              variant="h6"
              align="center"
              sx={{ width: "100%", mt: 4 }}
            >
              No se encontraron productos.
            </Typography>
          )}
        </Grid>

        <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
          <Pagination
            count={totalPaginas}
            page={paginaActual}
            onChange={handleChangePagina}
            color="primary"
          />
        </Box>
      </Container>
    </Box>
  );
};

export default RadiosConvencionales;
