import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { db } from "../../firebase"; // Asegúrate de que la importación sea correcta
import { collection, getDocs } from "firebase/firestore";
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Button,
  Container,
  CircularProgress, // Para mostrar un indicador de carga
} from "@mui/material";

const EnlacesList = () => {
  const [enlaces, setEnlaces] = useState([]);
  const [loading, setLoading] = useState(true); // Indicador de carga
  const navigate = useNavigate();

  // Obtener los Enlaces desde Firebase Firestore
  useEffect(() => {
    const fetchEnlaces = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "Enlaces"));

        // Mapea los documentos obtenidos de Firestore
        const enlacesList = querySnapshot.docs.map((doc) => {
          const data = doc.data(); // Obtener los datos del documento
          console.log("Documento encontrado:", doc.id, data); // Debugging
          return {
            id: doc.id, // ID del documento
            name: data.name || "Sin nombre", // Asegúrate de que exista el campo "name"
            ...data, // Datos adicionales del documento
          };
        });

        setEnlaces(enlacesList);
        setLoading(false); // Detenemos la carga una vez que los datos se obtienen
      } catch (error) {
        console.error("Error al obtener los enlaces:", error);
        setLoading(false);
      }
    };

    fetchEnlaces();
  }, []);

  // Navegar a la ruta del enlace seleccionado
  const handleEnlaceClick = (uniqueID) => {
    navigate(`/enlace/${uniqueID}`);
  };

  // Si está cargando, muestra un indicador de carga
  if (loading) {
    return (
      <Container>
        <CircularProgress />
        <Typography variant="h6">Cargando enlaces...</Typography>
      </Container>
    );
  }

  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        Lista de Enlaces
      </Typography>
      <Grid container spacing={2}>
        {enlaces.map((enlace) => (
          <Grid item xs={12} sm={6} md={4} key={enlace.id}>
            <Card>
              <CardContent>
                <Typography variant="h6" component="div">
                  UniqueID: {enlace.id}
                </Typography>
                <Typography variant="body1" component="div">
                  Nombre: {enlace.name} {/* Mostrar el nombre del enlace */}
                </Typography>
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  onClick={() => handleEnlaceClick(enlace.id)}
                >
                  Ver estadísticas
                </Button>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default EnlacesList;
