import React, { useState, useEffect } from "react";
import { Fab, Menu, MenuItem, Typography, Box, Fade } from "@mui/material";
import { styled } from "@mui/system";
import SupportIcon from "@mui/icons-material/Support";

const FloatingButton = styled(Fab)({
  position: "fixed",
  bottom: 16,
  right: 16,
  backgroundColor: "#2474ab",
  color: "white",
  "&:hover": {
    backgroundColor: "#1f6394",
  },
});

const FloatingMessage = styled(Box)({
  position: "fixed",
  bottom: 80,
  right: 16,
  backgroundColor: "#ffffff",
  color: "#000",
  padding: "10px 20px",
  borderRadius: "8px",
  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
  zIndex: 1000,
  cursor: "pointer",
  display: "flex",
  alignItems: "center",
});

const options = [
  "Radio comunicación",
  "Seguridad",
  "Inspección",
  "Drones",
  "Equipamiento de Vehículos",
  "Puntos de repeticion",
];

const Chat = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [showMessage, setShowMessage] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowMessage(false);
    }, 4000);

    return () => clearTimeout(timer);
  }, []);

  const handleButtonClick = (event) => {
    setAnchorEl(event.currentTarget);
    setShowMessage(false);
  };

  const handleMessageClick = () => {
    setShowMessage(false);
    setAnchorEl(document.getElementById("floating-button"));
  };

  const handleClose = (option) => {
    setAnchorEl(null);
    if (option) {
      const message = `Hola! Necesito información sobre ${option}`;
      const phoneNumber = "526621215131";
      const whatsappUrl = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(
        message
      )}`;
      window.open(whatsappUrl, "_blank");
    }
  };

  return (
    <>
      <FloatingButton id="floating-button" onClick={handleButtonClick}>
        <SupportIcon style={{ fontSize: "2rem" }} /> {/* Icono más grande */}
      </FloatingButton>
      <Fade in={showMessage}>
        <FloatingMessage onClick={handleMessageClick}>
          <Typography>¿Necesitas ayuda?</Typography>
        </FloatingMessage>
      </Fade>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => handleClose(null)}
      >
        <MenuItem disabled>
          <Typography>
            ¿Requieres información de algo en específico? Selecciona:
          </Typography>
        </MenuItem>
        {options.map((option, index) => (
          <MenuItem key={index} onClick={() => handleClose(option)}>
            <Typography>{option}</Typography>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default Chat;
